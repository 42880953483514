import { Button, Form, FormGroup } from "reactstrap";
import BtcSpinner from "../components/BtcSpinner";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginScheme } from "./passwordValidation";

export const LoginForm = (props) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(loginScheme),
  });

  return (
    <Form onSubmit={handleSubmit(props.handleLogin)} id="LoginValidation">
      <FormGroup
        className={`has-label ${
          errors.email && getValues("email").length > 0
            ? "has-danger"
            : "has-success"
        }`}
      >
        <label>Email Address *</label>
        <input
          className="form-control"
          autoFocus
          name="email"
          {...register("email")}
        />
        {errors.email && getValues("email").length > 0 ? (
          <label className="error">{errors.email?.message}</label>
        ) : null}
      </FormGroup>
      <FormGroup
        className={`has-label ${
          errors.password ? "has-danger" : "has-success"
        }`}
      >
        <label>Password *</label>
        <input
          className="form-control"
          name="password"
          type="password"
          autoComplete="off"
          {...register("password")}
        />
        {errors.password ? (
          <label role="alert" className="error">
            {errors.password?.message}
          </label>
        ) : null}
      </FormGroup>
      <Button
        block
        color="success"
        disabled={
          (!!errors.email && getValues("email").length > 0) || !!errors.password
        }
        type="submit"
      >
        {props.isLoading ? <BtcSpinner /> : "Login"}
      </Button>

      <label>* Required fields</label>
    </Form>
  );
};
