import React from "react";
import PropTypes from "prop-types";
import { CardBody, CardFooter } from "reactstrap";
import { Pie } from "react-chartjs-2";
import { getPieChartService } from "./constants/guardDuty";
import { getMaxDate, getMinDate } from "./alertDateUtil";
import BtcCard from "../../components/BtcCard";
import BtcCardHeader from "../../components/BtcCardHeader";

EventsByServiceCard.propTypes = {
  alerts: PropTypes.array,
  alertsError: PropTypes.object,
  setTableFilterValue: PropTypes.func,
};

function EventsByServiceCard({
  alerts,
  alertsError,
  setTableFilterValue,
  loading,
}) {
  let pieChartService = getPieChartService(setTableFilterValue, alerts);
  pieChartService.data.datasets[0].data = [
    getCountService(alerts, "EC2"),
    getCountService(alerts, "IAM"),
    getCountService(alerts, "S3"),
  ];

  function getCountService(items, service) {
    return items.filter((item) => item.entityType === service).length;
  }

  return (
    <>
      <BtcCard label="Security Events by Service">
        <BtcCardHeader
          title="Security Events"
          error={alertsError}
          description="grouped by Service"
          loading={loading}
        />
        <CardBody>
          <Pie
            data={pieChartService.data}
            options={pieChartService.options}
            type="pie"
          />
        </CardBody>
        <CardFooter>
          <div className="legend">
            <i className="fa fa-circle text-primary" />
            Total number of Events: {alerts.length}
          </div>
          <hr />
          <div className="stats">
            <i className="fa fa-calendar" />
            {getMinDate(alerts)} - {getMaxDate(alerts)}
          </div>
        </CardFooter>
      </BtcCard>
    </>
  );
}

export default EventsByServiceCard;
