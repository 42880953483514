import BtcCard from "../../components/BtcCard";
import React, { useContext, useEffect, useRef, useState } from "react";
import BtcCardHeader from "../../components/BtcCardHeader";
import { Button, CardFooter } from "reactstrap";
import { UserContext } from "../../utils/userContext";
import { Pie } from "react-chartjs-2";
import {
  getCostsLabels,
  shortenDataPlotLabel,
  sumCosts,
  sumOther,
} from "./costBreakDownPlotUtils";
import { breakdownCostsPieChartData } from "./costBreakdownPlotRecources";
import NotificationAlert from "react-notification-alert";
import { shouldItemBeVisible } from "../../utils/accountUtil";
import BtcStretchedContentCardBody from "../../components/BtcStretchedContentCardBody";

export const CostsBreakdownPlotCard = ({
  switchDetailsHandler,
  isCostBreakdownCardVisible,
  serviceCosts,
  error,
  loading,
  categoryAttribute,
  categoryLabel,
}) => {
  const { activeAccountIds } = useContext(UserContext);
  const notifyRef = useRef(null);
  const [filteredServiceCosts, setFilteredServiceCosts] = useState([]);
  const [breakdownCostsData, setBreakdownCostsData] = useState(null);

  const breakdownCosts = (costsData, categoryAttribute) => {
    const labelsArray = getCostsLabels(costsData, categoryAttribute);
    const dataArray = [];
    labelsArray.forEach((item) => {
      dataArray.push({
        label: shortenDataPlotLabel(item),
        value: sumCosts(costsData, item, categoryAttribute),
      });
    });
    dataArray.sort((a, b) => b.value - a.value);

    const numberOfDisplayedLegendItems = 4;
    const sumOtherTotal = sumOther(dataArray, numberOfDisplayedLegendItems);
    const ourArray = dataArray.slice(0, numberOfDisplayedLegendItems);
    ourArray.push({ label: "Others", value: sumOtherTotal });
    const chartData = breakdownCostsPieChartData(ourArray);
    setBreakdownCostsData(chartData);
  };

  useEffect(() => {
    const filteredServiceCosts = serviceCosts.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );
    breakdownCosts(filteredServiceCosts, categoryAttribute);
    setFilteredServiceCosts(filteredServiceCosts);
  }, [categoryAttribute, activeAccountIds, serviceCosts]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcCard label="Costs Breakdown">
        <BtcCardHeader
          title="Costs Breakdown"
          icon="fas fa-euro-sign"
          error={error}
          url="/costs"
          loading={loading}
          description={`grouped by ${categoryLabel}`}
        />
        <BtcStretchedContentCardBody>
          {breakdownCostsData &&
          breakdownCostsData.datasets[0].data[0] !== 0 ? (
            <Pie
              data={breakdownCostsData}
              options={breakdownCostsData.options}
              type="pie"
            />
          ) : (
            <span>No data to display</span>
          )}
        </BtcStretchedContentCardBody>
        <CardFooter className="card-footer-align-right">
          <hr />
          <Button
            disabled={!breakdownCostsData || filteredServiceCosts.length === 0}
            className="btn-default btn-sm btn-align-right"
            onClick={switchDetailsHandler}
          >
            {" "}
            {isCostBreakdownCardVisible ? "Hide details" : "Show details"}
          </Button>
        </CardFooter>
      </BtcCard>
    </>
  );
};
