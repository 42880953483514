import React from "react";
import PropTypes from "prop-types";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

BtcMonthDropdown.propTypes = {
  numberOfMonth: PropTypes.number,
  setNumberOfMonth: PropTypes.func,
  monthOptions: PropTypes.array,
  className: PropTypes.string,
};

function BtcMonthDropdown({
  numberOfMonth,
  setNumberOfMonth,
  monthOptions = [6, 12],
  className,
}) {
  return (
    <UncontrolledDropdown className={className}>
      <DropdownToggle caret color="primary" size="sm">
        {numberOfMonth + " Months"}
      </DropdownToggle>
      <DropdownMenu>
        {monthOptions.map((option) => (
          <DropdownItem
            key={"month-dropdown" + option}
            onClick={() => setNumberOfMonth(option)}
          >
            {option + " Months"}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default BtcMonthDropdown;
