import React, { useContext, useEffect, useRef, useState } from "react";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import { CardBody } from "reactstrap";
import BtcCardHeader from "../../components/BtcCardHeader";
import ReactTable from "../../components/ReactTable";
import { fetchHostedZones } from "./networkResource";
import { UserContext } from "../../utils/userContext";
import NotificationAlert from "react-notification-alert";
import { shouldItemBeVisible } from "../../utils/accountUtil";
import BtcSpinner from "../../components/BtcSpinner";
import { useAppContext } from "../../utils/contextLib";
import BtcAccountName from "../../components/BtcAccountName";

function HostedZoneTableCard() {
  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const notifyRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [hostedZones, setHostedZones] = useState([]);
  const [filteredHostedZones, setFilteredHostedZones] = useState([]);
  const [hostedZonesError, setHostedZonesError] = useState();

  useEffect(() => {
    let company = activePermission["company"];

    fetchData(
      () => fetchHostedZones(company),
      setHostedZones,
      setHostedZonesError,
      () => {},
      notifyRef
    );
  }, [activePermission, fetchData]);

  useEffect(() => {
    if (!hostedZones) {
      return;
    }
    let filteredHostedZones = hostedZones.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );
    setFilteredHostedZones(filteredHostedZones);
    setLoading(false);
  }, [hostedZones, activeAccountIds]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      {loading ? (
        <BtcSpinner className="ml-auto p-2 m-1" />
      ) : (
        <BtcNoAspectRatioCard label="hosted zones table" className="card-stats">
          <BtcCardHeader title="Hosted Zones" error={hostedZonesError} />
          <CardBody>
            <ReactTable
              data={filteredHostedZones}
              columns={[
                {
                  Header: "Hosted Zone",
                  accessor: "hostedZoneName",
                },
                {
                  Header: "Account-name",
                  accessor: "accountName",
                  Cell: (item) => (
                    <BtcAccountName
                      accountName={item.value}
                      hyperscaler={item.row.original.hyperscaler}
                    />
                  ),
                },
                {
                  Header: "Account-id",
                  accessor: "accountId",
                },
                {
                  Header: "Hosted Zone Type",
                  accessor: "hostedZoneType",
                },
              ]}
            />
          </CardBody>
        </BtcNoAspectRatioCard>
      )}
    </>
  );
}

export default HostedZoneTableCard;
