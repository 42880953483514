import React from "react";
import { Spinner } from "reactstrap";

function BtcSpinner({ className, loading = true }) {
  return loading ? (
    <Spinner size="sm" type="grow" className={className} />
  ) : (
    <></>
  );
}

export default BtcSpinner;
