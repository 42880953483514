import React from "react";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import { Line } from "react-chartjs-2";
import { BTCChart2, chartExample13 } from "../../variables/charts";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";

TbiCostsCard.propTypes = {};

function TbiCostsCard() {
  return (
    <BtcNoAspectRatioCard label="TBI Costs">
      <CardHeader>
        <Row>
          <Col xs="7">
            <div className="numbers pull-left">TBI</div>
          </Col>
          <Col xs="5"></Col>
        </Row>
      </CardHeader>
      <CardBody>
        <h6 className="big-title">total costs</h6>
        <Line
          data={chartExample13.data}
          options={chartExample13.options}
          height={300}
          width={826}
        />
      </CardBody>
      <CardBody>
        <Line
          data={BTCChart2.data}
          options={BTCChart2.options}
          height={250}
          width={826}
        />
      </CardBody>
      <CardFooter></CardFooter>
    </BtcNoAspectRatioCard>
  );
}

export default TbiCostsCard;
