import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Card, CardBody, CardFooter, Form } from "reactstrap";
import BtcCardHeader from "../../../components/BtcCardHeader";
import { fetchCompanyParams, putCompanyParams } from "../adminResource";
import { notifyError, notifySuccess } from "../../../utils/notification";
import BtcFormInput from "../../../components/BtcFormInput";
import { useAppContext } from "../../../utils/contextLib";
import BtcFormCheckbox from "../../../components/BtcFormCheckbox";
import BtcFormCheckboxArray from "../../../components/BtcFormCheckboxList";
import BtcFormValueWithTypeArray from "../../../components/BtcFormValueWithTypeArray";
import BtcFormValueArray, {
  mapFromObjectArray,
} from "../../../components/BtcFormValueArray";

CompanyEditParamsCard.propTypes = {
  notifyRef: PropTypes.object,
};

function CompanyEditParamsCard({ notifyRef, companyName }) {
  const filterTypes = ["prefix", "contains", "equals"];

  const { fetchData } = useAppContext();
  const [backendCall, setBackendCall] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [error, setError] = useState();

  const schema = yup.object().shape({
    contactEmail: yup.string().email("Please provide a valid E-Mail"),
    contactEmail2: yup.string().email("Please provide a valid E-Mail"),
    mailingList: yup.string().email("Please provide a valid E-Mail"),
    ticketEmail: yup.string().email("Please provide a valid E-Mail"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
    control,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      enabledAwsBackendModules: [],
      awsAccountsIncludeFilters: [],
      awsAccountsExcludeFilters: [],
      azureSubscriptionsIncludeFilters: [],
      azureSubscriptionsExcludeFilters: [],
    },
  });

  useEffect(() => {
    fetchData(
      () => fetchCompanyParams(companyName),
      (data) => reset(data),
      setError,
      setOptionsLoading,
      notifyRef
    );
  }, [companyName, notifyRef, reset, fetchData]);

  function onSubmit(data) {
    const transferData = {
      ...data,
      azureTenants: mapFromObjectArray(data.azureTenants),
    };
    setBackendCall(true);
    putCompanyParams(companyName, transferData)
      .then(() => {
        notifySuccess(notifyRef, `Update of parameters was successful.`);
      })
      .catch((e) => notifyError(notifyRef, e))
      .finally(() => {
        setBackendCall(false);
        reset(data); // to remove dirty state
      });
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <BtcCardHeader
          title="Parameter"
          error={error}
          loading={optionsLoading}
        />
        <CardBody className="px-5 pb-4">
          <h4 className="mt-0">Extended & Basic</h4>
          <BtcFormCheckbox
            attributeName={"extended"}
            labelText={"Extended features enabled"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
            type="checkbox"
          />
          <h4>Contact</h4>
          <BtcFormInput
            attributeName={"contactEmail"}
            labelText={"Contact E-Mail"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"contactName"}
            labelText={"Contact Name"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"contactPhone"}
            labelText={"Contact Phone"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"contactEmail2"}
            labelText={"2. Contact E-Mail"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"contactName2"}
            labelText={"2. Contact Name"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"contactPhone2"}
            labelText={"2. Contact Phone"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"mailingList"}
            labelText={"Mailing list"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <h4>AWS</h4>
          <h5>Special Accounts</h5>
          <BtcFormInput
            attributeName={"masterAccountId"}
            labelText={"Master Account Id"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"idpAccountId"}
            labelText={"IDP Account Id"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <h5>Enabled Backend Modules</h5>
          <BtcFormCheckboxArray
            arrayAttributeName={"enabledAwsBackendModules"}
            control={control}
            formErrors={errors}
            disabled={backendCall || optionsLoading}
            arrayDefinition={{
              iam: "IAM",
              security: "Security",
              serviceCosts: "Service Costs",
              budgets: "Budgets",
              infrastructureCosts: "Infrastructure Costs",
              saasCosts: "SAAS Costs",
              network: "Network",
              costAnomaly: "Cost Anomaly",
            }}
          ></BtcFormCheckboxArray>
          <h4>Azure</h4>
          <BtcFormInput
            attributeName={"azureCostCenterTagName"}
            labelText={"Cost Center Tag Name"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <h5>Tenants</h5>
          <BtcFormValueArray
            control={control}
            arrayAttributeName={"azureTenants"}
            register={register}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
          />
          <h4>Batch Load Settings</h4>
          <div className="btc-description">
            <ul>
              <li>AWS accounts and Azure subscriptions are fetched daily.</li>
              <li>
                Accounts/Subscriptions will only be loaded, if an include-filter
                exists which matches the name of the account/subscription.
              </li>
              <li>
                If you want to load all accounts/subscriptions, you have to add
                a prefix include-filter with an empty text only.
              </li>
              <li>
                If you have multiple include-filters, they will be linked with
                an "or". Same is valid for exclude-filters.
              </li>
              <li>
                Exclude filters have a higher priority than include-filters - so
                if there is a matching include and exclude-filter the
                account/subscription is excluded.
              </li>
              <li>Filters are case-sensitive.</li>
            </ul>
          </div>
          <h5>AWS Accounts Include-Filters</h5>
          <BtcFormValueWithTypeArray
            arrayAttributeName={"awsAccountsIncludeFilters"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
            control={control}
            types={filterTypes}
          />
          <h5>AWS Accounts Exclude-Filters</h5>
          <BtcFormValueWithTypeArray
            arrayAttributeName={"awsAccountsExcludeFilters"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
            control={control}
            types={filterTypes}
          />
          <h5>Azure Subscriptions Include-Filters</h5>
          <BtcFormValueWithTypeArray
            arrayAttributeName={"azureSubscriptionsIncludeFilters"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
            control={control}
            types={filterTypes}
          />
          <h5>Azure Subscriptions Exclude-Filters</h5>
          <BtcFormValueWithTypeArray
            arrayAttributeName={"azureSubscriptionsExcludeFilters"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
            control={control}
            types={filterTypes}
          />
          <h4>Jira</h4>
          <BtcFormInput
            attributeName={"ticketEmail"}
            labelText={"Ticket E-Mail"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"jiraSecretTenant"}
            labelText={"Jira Secret Tenant"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"jiraUrl"}
            labelText={"Jira URL"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"jqlIncidents"}
            labelText={"Incidents JQL"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"jqlOpenRequests"}
            labelText={"Open Requests JQL"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"jqlInProgress"}
            labelText={"In Progress JQL"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
          <BtcFormInput
            attributeName={"jqlDone30d"}
            labelText={"Done last 30day JQL"}
            disabled={backendCall || optionsLoading}
            formErrors={errors}
            register={register}
          />
        </CardBody>
        <CardFooter>
          <input
            className="btn btn-success pull-right"
            type="submit"
            value={"Save"}
            disabled={
              backendCall || !isValid || !isDirty || error || optionsLoading
            }
          />
        </CardFooter>
      </Card>
    </Form>
  );
}

export default CompanyEditParamsCard;
