import { API, graphqlOperation } from "aws-amplify";
import { GraphQlError, parseAndThrowErrors } from "../../errors/GraphQlError";
import { buildMapGroupedByKey } from "../../utils/groupByUtil";
import { doDelete, doPost, doPut } from "../../utils/httpUtil";
import config from "../../config";
import { NotFoundError } from "../../errors/NotFoundError";
import { mapFromStringArray } from "../../components/BtcFormValueArray";

async function fetchSysadminConfigFromServer(name) {
  const getConfig = `query {
      getSysadminConfig(name: "${name}") {
        items{
          values
        }
      }
    }
    `;
  let response;
  try {
    response = await API.graphql(graphqlOperation(getConfig));
  } catch (e) {
    parseAndThrowErrors(e, `${name} admin-config`);
  }

  if (response == null) {
    throw new GraphQlError(`Expected response but was null.`);
  }

  let items = response.data.getSysadminConfig.items;
  if (items.length !== 1) {
    throw new GraphQlError(
      `Expected exactly one config entry for ${name} but found ${items.length.length}`
    );
  }

  return items[0];
}

export async function fetchRoles() {
  let promise = await fetchSysadminConfigFromServer("roles");
  return promise.values;
}

export async function fetchCompanies() {
  let promise = await fetchSysadminConfigFromServer("companies");
  return promise.values;
}

export async function fetchModules() {
  let promise = await fetchSysadminConfigFromServer("modules");
  return promise.values;
}

async function fetchUsersFromServer(email) {
  const getConfig = `query {
      listUserCompanyAssignmentForAdmin(email: "${email}") {
        items{
          cognitoUser
          company
          role
          userName
        }
      }
    }
    `;
  try {
    const response = await API.graphql(graphqlOperation(getConfig));
    return mapUser(response.data.listUserCompanyAssignmentForAdmin.items);
  } catch (e) {
    parseAndThrowErrors(e, `${email} admin-config`);
  }
}

function mapUser(users) {
  let userMapByMail = buildMapGroupedByKey(users, (k) => k.cognitoUser);
  return Array.from(userMapByMail.values()).map((userAssignments) =>
    mergeUserCompanyAssignmentsToUserObject(userAssignments)
  );
}

/**
 * result example:
 * [
 *     {
 *       email: "soenke.martens@btc-ag.com",
 *       companyAssignments: [
 *         { company: "BTC", role: "admin" },
 *         { company: "TBW", role: "admin" },
 *       ],
 *     },
 *   ];
 *
 */
function mergeUserCompanyAssignmentsToUserObject(userAssignments) {
  return userAssignments
    .map((userAssignment) => ({
      email: userAssignment.cognitoUser,
      companyAssignments: [
        { company: userAssignment.company, role: userAssignment.role },
      ],
      userName: userAssignment.userName,
    }))
    .reduce((value, newValue) => {
      return {
        email: value.email,
        companyAssignments: [
          ...value.companyAssignments,
          ...newValue.companyAssignments,
        ],
        userName: value.userName,
      };
    });
}

export async function fetchUsers() {
  return fetchUsersFromServer("");
}

export async function fetchUser(email) {
  let users = await fetchUsersFromServer(email);
  if (users.length !== 1) {
    throw new GraphQlError(
      `Expected one user with email ${email}, but got ${users.length}`
    );
  }
  return users[0];
}

export function pushUser(user) {
  return doPost(user, config.sysadminApi.URL + "/user", "creating user");
}

export function putUser(userName, user) {
  return doPut(
    user,
    config.sysadminApi.URL + "/user/" + userName,
    "updating user"
  );
}

export function deleteUser(userName) {
  return doDelete(
    config.sysadminApi.URL + "/user/" + userName,
    "deleting user"
  );
}

export function pushCompany(companyName) {
  return doPost(
    companyName,
    config.sysadminApi.URL + "/company",
    "creating company"
  );
}

export async function fetchCompanyModulesForRole(companyName, role) {
  const getConfig = `query {
      listCompanyModulesForAdmin(input: {
          companyName: "${companyName}",
          role: "${role}"
        }) {
        items{
          module
        }
      }
    }
    `;
  try {
    const response = await API.graphql(graphqlOperation(getConfig));
    if (response.data.listCompanyModulesForAdmin.items.length < 1) {
      return [];
    }
    return response.data.listCompanyModulesForAdmin.items[0].module;
  } catch (e) {
    parseAndThrowErrors(e, `${role} company modules`);
  }
}

export async function putCompanyRoleModules(companyName, role, modules) {
  return doPut(
    modules,
    config.sysadminApi.URL + "/company/" + companyName + "/modules/" + role,
    "updating company modules"
  );
}

export async function putCompanyParams(companyName, data) {
  return doPut(
    data,
    config.sysadminApi.URL + "/company/" + companyName + "/parameters",
    "updating company parameters"
  );
}

export async function fetchCompanyParams(companyName) {
  const getConfig = `query {
      listCompanyConfigParamsForAdmin(input: {
          tenant: "${companyName}",
        }) {
        items {
          extended
          contactName
          contactEmail
          contactPhone
          contactName2
          contactEmail2
          contactPhone2
          mailingList
          masterAccountId
          idpAccountId
          enabledAwsBackendModules
          ticketMailadresse
          jiraConfig {
            secretTenant
            url
            jql {
              incidents
              openRequests
              inProgress
              done30d
            }
          }
          azure {
            costCenterTagName
            tenants
          }
          accountBatchLoad {
            aws {
              includeFilters {
                value
                type
              }
              excludeFilters {
                value
                type
              }
            }
            azure {
              includeFilters {
                value
                type
              }
              excludeFilters {
                value
                type
              }
            }
          }
        }
      }
    }
    `;
  try {
    const response = await API.graphql(graphqlOperation(getConfig));
    const items = response.data.listCompanyConfigParamsForAdmin.items;
    if (items == null || items.length === 0) {
      return {};
    }
    const params = items[0];
    return {
      extended: params.extended,
      contactEmail: params.contactEmail || undefined,
      contactName: params.contactName,
      contactPhone: params.contactPhone,
      contactEmail2: params.contactEmail2 || undefined,
      contactName2: params.contactName2,
      contactPhone2: params.contactPhone2,
      mailingList: params.mailingList || undefined,
      masterAccountId: params.masterAccountId,
      idpAccountId: params.idpAccountId,
      enabledAwsBackendModules: params.enabledAwsBackendModules || [],
      ticketEmail: params.ticketMailadresse || undefined,
      jiraSecretTenant: params.jiraConfig.secretTenant,
      jiraUrl: params.jiraConfig.url,
      jqlIncidents: params.jiraConfig.jql.incidents,
      jqlOpenRequests: params.jiraConfig.jql.openRequests,
      jqlInProgress: params.jiraConfig.jql.inProgress,
      jqlDone30d: params.jiraConfig.jql.done30d,
      azureCostCenterTagName: params.azure?.costCenterTagName,
      azureTenants: mapFromStringArray(params.azure?.tenants || []),
      awsAccountsIncludeFilters:
        params?.accountBatchLoad?.aws?.includeFilters || [],
      awsAccountsExcludeFilters:
        params?.accountBatchLoad?.aws?.excludeFilters || [],
      azureSubscriptionsIncludeFilters:
        params?.accountBatchLoad?.azure?.includeFilters || [],
      azureSubscriptionsExcludeFilters:
        params?.accountBatchLoad?.azure?.excludeFilters || [],
    };
  } catch (e) {
    parseAndThrowErrors(e, `company params`);
  }
}

function mapAwsAccount(account) {
  return {
    accountId: account.accountId,
    accountName: account.accountName,
    hyperscaler: "aws",
    detectorId: account.tags?.detectorId,
    flatrateCosts: account.tags?.costs,
  };
}

/*
{
  "accountId": "1234"
  "accountName": "Bla"
  ...
}
*/
function mapAwsAccounts(accounts) {
  return accounts.map((account) => mapAwsAccount(account));
}

function mapAzureSubscription(account) {
  return {
    accountId: account.accountId,
    accountName: account.accountName,
    hyperscaler: "azure",
  };
}

/*
{
  "accountId": "12345-asbcd-12345-12345-12345"
  "accountName": "Bla"
}
*/
function mapAzureSubscriptions(subscriptions) {
  return subscriptions.map((subscription) =>
    mapAzureSubscription(subscription)
  );
}

export async function fetchCompanyAccounts(companyName) {
  const getConfig = `query {
      listAccountsFromConfigForAdmin(input: {
          companyName: "${companyName}",
        }) {
        items {
        accountId
        accountName
        company
        hyperscaler
        tags {
          detectorId
          costs
          }
        }
      }
    }
    `;
  try {
    const response = await API.graphql(graphqlOperation(getConfig));
    const items = response.data.listAccountsFromConfigForAdmin.items;
    return {
      awsAccounts: mapAwsAccounts(
        items.filter((account) => account.hyperscaler === "aws")
      ),
      azureSubscriptions: mapAzureSubscriptions(
        items.filter((account) => account.hyperscaler === "azure")
      ),
    };
  } catch (e) {
    parseAndThrowErrors(e, `aws accounts and azure subscriptions`);
  }
}

export async function fetchAwsAccount(companyName, accountId) {
  return fetchCompanyAccounts(companyName).then((result) => {
    let accounts = result.awsAccounts.filter(
      (account) => account.accountId === accountId
    );
    if (accounts.length === 0) {
      throw new NotFoundError(
        `AWS Account with account id ${accountId} was not found.`
      );
    }
    return accounts[0];
  });
}

export async function createAWSAccount(companyName, account) {
  return doPost(
    account,
    config.sysadminApi.URL + "/company/" + companyName + "/aws-accounts",
    "creating aws account"
  );
}

export async function putAWSAccount(companyName, accountId, account) {
  return doPut(
    account,
    config.sysadminApi.URL +
      "/company/" +
      companyName +
      "/aws-accounts/" +
      accountId,
    "updating aws account"
  );
}

export async function deleteAWSAccount(companyName, accountId) {
  return doDelete(
    config.sysadminApi.URL +
      "/company/" +
      companyName +
      "/aws-accounts/" +
      accountId,
    "deleting aws account"
  );
}

export async function loadAccountsInBatch(companyName, hyperscaler) {
  return doPost(
    { hyperscaler: hyperscaler },
    config.sysadminApi.URL + "/company/" + companyName + "/batch-load",
    "loading aws accounts in batch"
  );
}

export async function fetchAzureSubscription(companyName, accountId) {
  return fetchCompanyAccounts(companyName).then((result) => {
    let subscriptions = result.azureSubscriptions.filter(
      (subscription) => subscription.accountId === accountId
    );
    if (subscriptions.length === 0) {
      throw new NotFoundError(
        `Azure subscription with subscription id ${accountId} was not found.`
      );
    }
    return subscriptions[0];
  });
}

export async function createAzureSubscription(companyName, subscription) {
  return doPost(
    subscription,
    config.sysadminApi.URL + "/company/" + companyName + "/azure-subscriptions",
    "creating azure subscription"
  );
}

export async function putAzureSubscription(
  companyName,
  accountId,
  subscription
) {
  return doPut(
    subscription,
    config.sysadminApi.URL +
      "/company/" +
      companyName +
      "/azure-subscriptions/" +
      accountId,
    "updating azure subscription"
  );
}

export async function deleteAzureSubscription(companyName, accountId) {
  return doDelete(
    config.sysadminApi.URL +
      "/company/" +
      companyName +
      "/azure-subscriptions/" +
      accountId,
    "deleting azure subscription"
  );
}
