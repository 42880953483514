import React, { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import _uniqueId from "lodash/uniqueId";

function CustomWarningIcon({ message, className }) {
  const [id] = useState(_uniqueId("custom-warning-"));

  return message ? (
    <>
      <i
        id={id}
        className={`fas fa-exclamation-triangle text-warning ${
          className ? className : ""
        }`}
      />
      <UncontrolledTooltip delay={0} target={id}>
        <b>Warning</b> - {message}
      </UncontrolledTooltip>
    </>
  ) : null;
}

export default CustomWarningIcon;
