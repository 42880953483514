import React, { useEffect, useState } from "react";
import ReactTable from "../../../components/ReactTable";
import BtcNoAspectRatioCard from "../../../components/BtcNoAspectRatioCard";
import BtcCardHeader from "../../../components/BtcCardHeader";
import { CardBody } from "reactstrap";
import { fetchUsers } from "../adminResource";
import PropTypes from "prop-types";
import { useAppContext } from "../../../utils/contextLib";
import BtcLink from "../../../components/BtcLink";

UserTableCard.propTypes = {
  notifyRef: PropTypes.object,
};

function UserTableCard({ notifyRef }) {
  const [busy, setBusy] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const { fetchData } = useAppContext();

  useEffect(() => {
    fetchData(
      () => fetchUsers(),
      (users) => {
        let preparedUsers = users.map((user) => ({
          ...user,
          companyAssignmentsAsString: user.companyAssignments.reduce(
            (a, b) => `${a ? a + ", " : ""}${b.company} (${b.role})`,
            ""
          ),
        }));
        setUsers(preparedUsers);
      },
      setError,
      setBusy,
      notifyRef
    );
  }, [notifyRef, fetchData]);

  return (
    <BtcNoAspectRatioCard label="User Table">
      <BtcCardHeader title="Users" error={error} loading={busy} childSize={6}>
        <BtcLink className="btn btn-primary pull-right" to="/admin/user-create">
          <i className="fas fa-baby-carriage" /> Create User
        </BtcLink>
      </BtcCardHeader>
      <CardBody>
        <ReactTable
          data={users}
          columns={[
            {
              Header: "EMail",
              accessor: "email",
            },
            {
              Header: "Company assignments",
              accessor: "companyAssignmentsAsString",
            },
            {
              Header: "Actions",
              className: "rt-th--right-align btc-10p-column",
              disableSortBy: true,
              disableFilters: true,
              Cell: (item) => {
                return (
                  <div className="actions-right">
                    <BtcLink
                      className="btn btn-sm btn-icon btn-link btn-warning edit"
                      to={`/admin/user-edit/${item.row.original.email}`}
                    >
                      <i className="fa fa-edit" />
                    </BtcLink>{" "}
                  </div>
                );
              },
            },
          ]}
        />
      </CardBody>
    </BtcNoAspectRatioCard>
  );
}

export default UserTableCard;
