import React from "react";

export function notifyError(ref, error, autoDismiss = 0) {
  const message = buildMessage(
    error.message,
    error.header || "Error",
    `fas fa-exclamation-triangle`
  );
  notify({ ref, type: 3, place: "tr", message: message, autoDismiss });
}

function buildMessage(message, header, iconClass) {
  return (
    <div>
      <div className="btc-alert">
        {iconClass && <i className={iconClass} />}{" "}
        {header && (
          <>
            <b>{header}</b> -
          </>
        )}{" "}
        {message}
      </div>
    </div>
  );
}

export function notifySuccess(ref, message, autoDismiss = 7) {
  const extendedMessage = buildMessage(message, "Success", `fas fa-check`);
  notify({ ref, type: 2, place: "tr", message: extendedMessage, autoDismiss });
}

export function notify({
  ref,
  type = 4,
  place = "tr",
  message = "No message was defined.",
  autoDismiss = 7,
}) {
  switch (type) {
    case 1:
      type = "primary";
      break;
    case 2:
      type = "success";
      break;
    case 3:
      type = "danger";
      break;
    case 4:
      type = "warning";
      break;
    case 5:
      type = "info";
      break;
    default:
      break;
  }
  let options = {
    place: place,
    message: (
      <div>
        <div>{message}</div>
      </div>
    ),
    type: type,
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: autoDismiss,
  };
  ref.current.notificationAlert(options);
}
