import React from "react";
import { Label } from "reactstrap";
import PropTypes from "prop-types";
import BtcFormGroup from "./BtcFormGroup";

BtcFormCheckbox.propTypes = {
  register: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  attributeName: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

function BtcFormCheckbox({
  register,
  formErrors,
  attributeName,
  labelText,
  disabled = false,
}) {
  return (
    <BtcFormGroup
      formErrors={formErrors}
      attributeName={attributeName}
      check
      className={"mt-3"}
    >
      <Label check>
        <input
          id={attributeName}
          type={"checkbox"}
          className="form-control"
          disabled={disabled}
          {...register(attributeName)}
        />
        {labelText} <span className="form-check-sign" />
      </Label>
    </BtcFormGroup>
  );
}

export default BtcFormCheckbox;
