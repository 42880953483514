import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, CardBody, UncontrolledTooltip } from "reactstrap";
import ReactTable from "components/ReactTable.js";
import { UserContext } from "../../utils/userContext";
import { sendMail } from "../../utils/mailFunction.js";
import { notifyError, notifySuccess } from "../../utils/notification";
import IamRoleChangeModal from "./IamRoleChangeModal";
import ConfirmDialog from "../../components/ConfirmDialog";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import { shouldItemBeVisible } from "../../utils/accountUtil";
import BtcCardHeader from "../../components/BtcCardHeader";
import { getMaxProperty } from "../../utils/arrayUtil";
import { fetchUserRoles } from "./iamResource";
import {
  exportTablePDF,
  exportTableXlsxCsv,
  extractFilteredData,
} from "../../utils/exportTableData";
import { useAppContext } from "../../utils/contextLib";
import BtcAccountName from "../../components/BtcAccountName";
import { prepareForId } from "../../utils/regExpUtil";

const exportConfiguration = {
  tableColumnsNames: ["Username", "Role", "Account", "Account ID"],
  dataToExport: ["username", "role", "accountName", "accountId"],
  columnsWidth: [{ wch: 120 }, { wch: 140 }, { wch: 100 }, { wch: 100 }],
  columnsWidthXls: [{ wch: 20 }, { wch: 40 }, { wch: 30 }, { wch: 20 }],
};

export const UserRolesCard = ({ notifyRef }) => {
  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const [busy, setBusy] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [fetchedUserRoles, setFetchedUserRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userOfModal, setUserOfModal] = useState(null);
  const [iamError, setIamError] = useState(null);
  const [backendCall, setBackendCall] = useState(false);
  const [selectedItemForRemoval, setSelectedItemForRemoval] = useState(null);
  const [selectedItemForAuthChange, setSelectedItemForAuthChange] =
    useState(null);
  const [selectedTargetRoleForAuthChange, setSelectedTargetRoleForAuthChange] =
    useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [filteredTableData, setFilteredTableData] = useState([]);

  const buildAuthorizationActions = useCallback(
    (item) => {
      return (
        <div className="actions-right">
          {item.MailMessageId != null && item.MailMessageId !== "" ? (
            <span>change requested</span>
          ) : (
            <>
              <Button
                onClick={() => {
                  setShowModal(true);
                  setUserOfModal(item);
                }}
                id={"bt-authorization-edit" + prepareForId(item.username)}
                color="warning"
                size="sm"
                className="btn-icon btn-link edit"
                disabled={backendCall}
              >
                <i className="fa fa-edit" />
              </Button>{" "}
              <UncontrolledTooltip
                delay={0}
                target={"bt-authorization-edit" + prepareForId(item.username)}
              >
                Edit Authorization
              </UncontrolledTooltip>
              <Button
                onClick={() => setSelectedItemForRemoval(item)}
                id={"bt-authorization-remove" + prepareForId(item.username)}
                color="danger"
                size="sm"
                className="btn-icon btn-link remove"
                disabled={backendCall}
              >
                <i className="fa fa-times" />
              </Button>{" "}
              <UncontrolledTooltip
                delay={0}
                target={"bt-authorization-remove" + prepareForId(item.username)}
              >
                Remove Authorization
              </UncontrolledTooltip>
            </>
          )}
        </div>
      );
    },
    [backendCall]
  );

  useEffect(() => {
    let company = activePermission["company"];
    fetchData(
      () => fetchUserRoles(company, new Date()),
      setFetchedUserRoles,
      setIamError,
      setBusy,
      notifyRef
    );
  }, [notifyRef, activePermission, refresh, fetchData]);

  useEffect(() => {
    function addAuthorizationActions(userRoleItems) {
      return userRoleItems.map((item) => ({
        ...item,
        actions: buildAuthorizationActions(item),
      }));
    }

    if (!fetchedUserRoles) {
      return;
    }

    let userRoleItems = fetchedUserRoles.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );
    userRoleItems = addAuthorizationActions(userRoleItems);

    setUserRoles(userRoleItems);
    setUpdatedAt(getMaxProperty(userRoleItems, "updatedAt"));
  }, [fetchedUserRoles, buildAuthorizationActions, activeAccountIds]);

  // This depends on userRoles, but also changes it... so adding it to dependencies, would make an infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refreshAuthorizationActionsOfItem, [
    buildAuthorizationActions,
    backendCall,
  ]);

  function refreshAuthorizationActionsOfItem() {
    const changed = userRoles.map((item) => ({
      ...item,
      actions: buildAuthorizationActions(item),
    }));
    setUserRoles(changed);
  }

  function getTodayDate() {
    const today = new Date();
    return (
      today.toISOString().substring(0, 4) +
      today.toISOString().substring(5, 7) +
      today.toISOString().substring(8, 10)
    );
  }

  function openDialogForAuthChange(item, targetRole) {
    setSelectedItemForAuthChange(item);
    setSelectedTargetRoleForAuthChange(targetRole);
  }

  function sendTicketMailForAuthorizationChange(item, targetRole) {
    const mailAddress = "jira-btc-sis@btc-ag.cloud";
    const subject = `Infomail Berechtigung verändern für ${item.username}`;
    const mailData = item;
    mailData["newrole"] = `role/${targetRole}`;
    mailData["subject"] = subject;
    mailData["mailAdress"] = mailAddress;
    mailData["tenant"] = activePermission["company"];
    mailData["todayDate"] = getTodayDate();

    setBackendCall(true);
    sendMail(mailData)
      .then(() => {
        notifySuccess(
          notifyRef,
          "Sending mail requesting authorization change was successful."
        );
        setRefresh(!refresh);
      })
      .catch((e) => notifyError(notifyRef, e))
      .finally(() => setBackendCall(false));
  }

  function sendTicketMailForAuthorizationRemoval(item) {
    const mailAddress = "jira-btc-sis@btc-ag.cloud";
    const subject = `Infomail Berechtigung entziehen für ${item.username}`;
    const mailData = item;
    mailData["subject"] = subject;
    mailData["mailAdress"] = mailAddress;
    mailData["tenant"] = activePermission["company"];
    mailData["todayDate"] = getTodayDate();

    setBackendCall(true);
    sendMail(mailData)
      .then(() => {
        notifySuccess(
          notifyRef,
          "Sending mail requesting authorization removal was successful."
        );
        setRefresh(!refresh);
      })
      .catch((e) => notifyError(notifyRef, e))
      .finally(() => setBackendCall(false));
  }

  const getFilteredData = (data) => {
    setFilteredTableData(extractFilteredData(data));
  };

  return (
    <>
      <BtcNoAspectRatioCard label="User Roles">
        <BtcCardHeader
          title="User Roles"
          updatedAt={updatedAt}
          error={iamError}
          loading={busy}
          exportDataMenu={true}
          isDataToExportAvailable={filteredTableData.length > 0}
          exportExcelFileFn={() =>
            exportTableXlsxCsv(
              "xlsx",
              filteredTableData,
              exportConfiguration,
              "security-events"
            )
          }
          exportCsvFileFn={() =>
            exportTableXlsxCsv(
              "csv",
              filteredTableData,
              exportConfiguration,
              "security-events"
            )
          }
          exportPdfFileFn={() =>
            exportTablePDF(
              filteredTableData,
              exportConfiguration,
              "security-events"
            )
          }
        />
        <CardBody>
          <ReactTable
            data={userRoles}
            hiddenColumns={activePermission.extended ? [] : ["actions"]}
            columns={[
              {
                Header: "Username",
                accessor: "username",
              },
              {
                Header: "Role",
                accessor: "role",
              },
              {
                Header: "Account",
                accessor: "accountName",
                Cell: (item) => (
                  <BtcAccountName
                    accountName={item.value}
                    hyperscaler={item.row.original.hyperscaler}
                  />
                ),
              },
              {
                Header: "Account-ID",
                accessor: "accountId",
              },
              // TODO BUCK-308 Is commented out on prod, since the feature is strange in the moment and confusing for users
              // Also powerusers can do this, too. Which is strange also
              // We have to improve it. Maybe we need a solution how to configure single features and for whom
              // {
              //   Header: "Authorization Actions",
              //   accessor: "actions",
              //   disableSortBy: true,
              //   disableFilters: true,
              //   isVisible: false,
              // },
            ]}
            className="prevent-too-small-columns"
            getFilteredData={getFilteredData}
          />
        </CardBody>
      </BtcNoAspectRatioCard>
      <IamRoleChangeModal
        show={showModal}
        user={userOfModal}
        onClose={() => setShowModal(false)}
        onChangeRole={openDialogForAuthChange}
      />
      {selectedItemForAuthChange && selectedTargetRoleForAuthChange ? (
        <ConfirmDialog
          show={true}
          onConfirm={() => {
            sendTicketMailForAuthorizationChange(
              selectedItemForAuthChange,
              selectedTargetRoleForAuthChange
            );
            setSelectedTargetRoleForAuthChange(null);
            setSelectedItemForAuthChange(null);
          }}
          onCancel={() => {
            setSelectedItemForRemoval(null);
            setSelectedTargetRoleForAuthChange(null);
          }}
        >
          Are you sure to change the authorization from{" "}
          {selectedItemForAuthChange.username} to{" "}
          {selectedTargetRoleForAuthChange}? This process can't be stopped.
        </ConfirmDialog>
      ) : (
        <></>
      )}
      {selectedItemForRemoval ? (
        <ConfirmDialog
          show={true}
          onConfirm={() => {
            sendTicketMailForAuthorizationRemoval(
              selectedItemForRemoval,
              selectedItemForRemoval.role
            );
            setSelectedItemForRemoval(null);
          }}
          onCancel={() => setSelectedItemForRemoval(null)}
        >
          Are you sure to remove the authorization from{" "}
          {selectedItemForRemoval.username}? This process can't be stopped.
        </ConfirmDialog>
      ) : (
        <></>
      )}
    </>
  );
};
