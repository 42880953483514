import { CustomError } from "./CustomError";

export class UnauthorizedError extends CustomError {
  constructor(
    msg = "Seems like you are missing authorization to access necessary information."
  ) {
    super(
      msg,
      "Unauthorized",
      "Please, try to login again or use a different account."
    );
  }
}
