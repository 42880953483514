import React from "react";
import { Col, Row } from "reactstrap";
import HostedZoneTableCard from "./HostedZoneTableCard";

function Network() {
  const customerLogoContainer = document.getElementById("customerLogoImg");
  const isCustomerLogoVisible = customerLogoContainer?.innerHTML !== "";

  return (
    <section
      aria-labelledby="network-module-page-heading"
      className={`content ${
        isCustomerLogoVisible ? "content-with-customer-logo" : ""
      }`}
    >
      <h2 id="network-module-page-heading" className="visually-hidden">
        Network
      </h2>
      <Row>
        <Col>
          <HostedZoneTableCard />
        </Col>
      </Row>
    </section>
  );
}

export default Network;
