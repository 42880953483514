import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, CardBody } from "reactstrap";
import { UserContext } from "../../utils/userContext";
import NotificationAlert from "react-notification-alert";
import BtcCardHeader from "../../components/BtcCardHeader";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import { fetchContactData } from "./contactResource";
import { useAppContext } from "../../utils/contextLib";

export const ContactCard = () => {
  const { fetchData } = useAppContext();
  const { activePermission } = useContext(UserContext);
  const notifyRef = useRef(null);
  const [busy, setBusy] = useState(true);
  const [contact, setContact] = useState(createEmptyContact());
  const [contactError, setContactError] = useState();

  useEffect(() => {
    let company = activePermission["company"];

    fetchData(
      () => fetchContactData(company),
      setContact,
      setContactError,
      setBusy,
      notifyRef,
      (e) => setContact(createEmptyContact())
    );
  }, [activePermission, fetchData]);

  function doMail() {
    let mailto = `mailto:${contact.contactEmail}`;
    // It would be good to put the second mail and mailing list both in cc, however this is not as easy as it seems,
    // some mail clients use , some ; to separate addresses.
    // It is hard to guess, which client the customer uses.
    if (contact.mailingList) {
      mailto += `?cc=${contact.mailingList}`;
    } else if (contact.contactEmail2) {
      mailto += `?cc=${contact.contactEmail2}`;
    }
    window.location.href = mailto;
  }

  function createEmptyContact() {
    return {
      contactName: null,
      contactPhone: null,
      contactEmail: null,
    };
  }

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcNoAspectRatioCard label="Contact" className="card-stats">
        <BtcCardHeader
          title="Contact"
          category="Overview"
          icon="far fa-id-card"
          error={contactError}
          loading={busy}
        />
        <CardBody className="btc-stretch text-center">
          <p>Need to talk, further plans or just an open ear?</p>
          <p>
            {/*<span>&nbsp;</span> is filler content to make loading of contacts smoother*/}
            <b>{contact.contactName || <span>&nbsp;</span>}</b>
            <br />
            {contact.contactPhone || <span>&nbsp;</span>}
            <br />
            {contact.contactEmail || <span>&nbsp;</span>}
          </p>
          {contact.contactName2 && (
            <p>
              <b>{contact.contactName2 || <span>&nbsp;</span>}</b>
              <br />
              {contact.contactPhone2 || <span>&nbsp;</span>}
              <br />
              {contact.contactEmail2 || <span>&nbsp;</span>}
            </p>
          )}
          {contact.mailingList && (
            <p>
              <b>{contact.mailingList}</b>
            </p>
          )}
          <div>
            <Button variant="primary" onClick={() => doMail()}>
              Get in touch
            </Button>{" "}
          </div>
        </CardBody>
      </BtcNoAspectRatioCard>
    </>
  );
};
