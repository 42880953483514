import React, { useContext, useEffect, useState } from "react";
import Select, { components } from "react-select";
import { pushHistory } from "../utils/urlUtil";
import BtcAccountName from "../components/BtcAccountName";
import { useHistory } from "react-router-dom";
import { UserContext } from "../utils/userContext";

AccountSelectionDropdown.propTypes = {};

function AccountSelectionDropdown() {
  let history = useHistory();
  const { activePermission, activeAccountIds } = useContext(UserContext);

  const [selectableAccounts, setSelectableAccounts] = useState(() =>
    createSelectableAccounts(activePermission)
  );
  const [selectedAccounts, setSelectedAccounts] = useState([
    {
      value: "all",
      label: "All Accounts",
      hyperscaler: undefined,
    },
  ]);
  const [searchString, setSearchString] = useState();

  useEffect(() => {
    const selected = selectableAccounts
      .flatMap((entry) => entry.options)
      .filter((entry) => activeAccountIds.indexOf(entry.value) !== -1);
    setSelectedAccounts(selected);
  }, [selectableAccounts, activeAccountIds]);

  useEffect(() => {
    let accounts = createSelectableAccounts(activePermission);
    setSelectableAccounts(accounts);
  }, [activePermission]);

  function createSelectableAccounts(activePermission) {
    function mapItem(item) {
      return {
        value: item.accountId,
        label: item.accountName,
        hyperscaler: item.hyperscaler,
      };
    }

    const generalOptions =
      activePermission?.companyAccListObj
        ?.filter(
          (item) =>
            item.accountId === "all" ||
            item.accountId === "aws" ||
            item.accountId === "azure"
        )
        .map((item) => {
          return mapItem(item);
        }) || [];

    const awsAccounts =
      activePermission?.companyAccListObj
        ?.filter(
          (item) => item.hyperscaler === "aws" && item.accountId !== "aws"
        )
        .sort((a, b) => a.accountName.localeCompare(b.accountName))
        .map((item) => {
          return mapItem(item);
        }) || [];

    const azureSubscriptions =
      activePermission?.companyAccListObj
        ?.filter(
          (item) => item.hyperscaler === "azure" && item.accountId !== "azure"
        )
        .sort((a, b) => a.accountName.localeCompare(b.accountName))
        .map((item) => {
          return mapItem(item);
        }) || [];

    let result = [];

    if (generalOptions.length > 0) {
      result.push({
        options: generalOptions,
      });
    }
    if (awsAccounts.length > 0) {
      result.push({
        label: "AWS Accounts",
        options: awsAccounts,
      });
    }
    if (azureSubscriptions.length > 0) {
      result.push({
        label: "Azure Subscriptions",
        options: azureSubscriptions,
      });
    }
    return result;
  }

  function selectAccountIds(selectedOptions) {
    setSelectedAccounts(selectedOptions);
    const activeIds = selectedOptions.map((item) => item.value);
    pushHistory(history, activePermission, activeIds);
  }

  const changeSelectedAccounts = (items, event) => {
    if (event.action === "select-option") {
      if (
        event.option.value === "all" ||
        event.option.value === "aws" ||
        event.option.value === "azure"
      ) {
        selectAccountIds([event.option]);
      } else {
        const filteredItems = items.filter(
          (item) =>
            item.value !== "all" &&
            item.value !== "aws" &&
            item.value !== "azure"
        );
        selectAccountIds(filteredItems);
      }
    } else if (
      event.action === "deselect-option" ||
      event.action === "remove-value"
    ) {
      if (items.length > 0) {
        selectAccountIds(items);
      } else {
        selectAccountIds([selectableAccounts[0].options[0]]);
      }
    }
  };

  // https://github.com/JedWatson/react-select/issues/3210#issuecomment-566482487
  const handleInputChange = (inputValue, { action }) => {
    if (action !== "set-value") {
      setSearchString(inputValue);
      return inputValue;
    }
    return searchString;
  };

  const MultiValue = (props) => {
    // Only interested in rendering one/first multiValue
    if (props.index > 0) return null;

    const showLabel = !(
      props.selectProps.menuIsOpen && props.selectProps.inputValue
    );

    // don't show label if user is actively searching
    if (!showLabel) return null;

    const { length } = props.getValue();
    return (
      <div className="select-button-inner-container">
        {length > 1 ? (
          <div className="option-label">{`${length} accounts selected`}</div>
        ) : (
          <BtcAccountName
            accountName={props.data.label}
            hyperscaler={props.data.hyperscaler}
          />
        )}
      </div>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <div
        className={`dropdown-toggle ${
          props.selectProps.menuIsOpen ? "dropdown-open" : ""
        }`}
      />
    );
  };

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <BtcAccountName
          accountName={props.data.label}
          hyperscaler={props.data.hyperscaler}
        />
      </components.Option>
    );
  };

  return selectableAccounts.length > 0 ? (
    <Select
      className="btc-multi-select-dropdown"
      classNamePrefix="btc-multi-select-dropdown"
      isSearchable={true}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      defaultValue={selectableAccounts[0]}
      isClearable={false}
      isMulti
      options={selectableAccounts}
      inputId="clickableInput"
      onChange={(event, items) => changeSelectedAccounts(event, items)}
      onInputChange={handleInputChange}
      components={{
        MultiValue,
        DropdownIndicator,
        IndicatorSeparator: () => null,
        Option,
      }}
      value={selectedAccounts}
      inputValue={searchString}
    />
  ) : (
    <></>
  );
}

export default AccountSelectionDropdown;
