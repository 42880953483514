import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import BtcCardHeader from "../../../components/BtcCardHeader";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createAWSAccount,
  deleteAWSAccount,
  fetchAwsAccount,
  putAWSAccount,
} from "../adminResource";
import { notifyError, notifySuccess } from "../../../utils/notification";
import { useHistory, useLocation } from "react-router-dom";
import DeletionModal from "../../../components/DeletionModal";
import BtcFormInput from "../../../components/BtcFormInput";
import { useAppContext } from "../../../utils/contextLib";
import { pushHistory } from "../../../utils/urlUtil";
import { UserContext } from "../../../utils/userContext";
import BtcLink from "../../../components/BtcLink";

AWSAccountEditCard.propTypes = {
  notifyRef: PropTypes.object,
  isCreate: PropTypes.bool,
};

function AWSAccountEditCard({ notifyRef, isCreate }) {
  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();

  const [companyName, setCompanyName] = useState();
  const [modalAccount, setModalAccount] = useState();
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [backendCall, setBackendCall] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const schema = yup.object().shape({
    accountId: yup.string().required("A Account ID is required"),
    accountName: yup
      .string()
      .required("A Account Name is required")
      .matches(/^[^#]+$/, "Account Name mustn't contain a #"),
    hyperscaler: yup
      .string()
      .oneOf(["aws"], "Hyperscaler must be aws")
      .required("A hyperscaler is required"),
    flatrateCosts: yup
      .string()
      .nullable()
      .test(
        "Is positive?",
        "Costs must be a positive number",
        (value) => value == null || Number(value) >= 0
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      hyperscaler: "aws",
    },
  });

  useEffect(() => {
    let pathParams = location.pathname
      .split(isCreate ? "account-create/" : "account-edit/")
      .pop();
    const companyName = pathParams.split("/")[0];
    setCompanyName(companyName);

    if (isCreate) {
      setLoading(false);
      return;
    }

    const accountId = pathParams.split("/").pop();

    fetchData(
      () => fetchAwsAccount(companyName, accountId),
      (account) => {
        setModalAccount(account);
        reset(account);
      },
      setError,
      setLoading,
      notifyRef
    );
  }, [reset, location, isCreate, notifyRef, fetchData]);

  function onSubmit(data) {
    if (isCreate) {
      handleBackendCall(
        createAWSAccount(companyName, data),
        "Creation of aws account was successful.",
        `/admin/company-edit/${companyName}`
      );
    } else {
      handleBackendCall(
        putAWSAccount(companyName, data.accountId, data),
        "Update of aws account was successful.",
        `/admin/account-edit/${companyName}/${data.accountId}`
      );
    }
  }

  function handleBackendCall(backendPromise, successMessage, path) {
    setBackendCall(true);
    backendPromise
      .then(() => {
        setBackendCall(false);
        pushHistory(history, activePermission, activeAccountIds, path);
        notifySuccess(notifyRef, successMessage);
      })
      .catch((e) => {
        setBackendCall(false);
        notifyError(notifyRef, e);
      });
  }

  function doDeleteAWSAccount() {
    setShowDeletionModal(false);
    handleBackendCall(
      deleteAWSAccount(companyName, modalAccount.accountId),
      "Deletion of aws account was successful.",
      `/admin/company-edit/${companyName}`
    );
  }

  return (
    <section aria-labelledby="aws-account-heading" className="content">
      <h2 id="aws-account-heading" className="visually-hidden">
        {isCreate ? "AWS Account Create Page" : "AWS Account Edit Page"}
      </h2>
      <Row>
        <Col
          md={{ offset: 1, size: 10 }}
          lg={{ offset: 2, size: 8 }}
          xl={{ offset: 3, size: 6 }}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <BtcCardHeader
                title={isCreate ? "AWS Account Creation" : "AWS Account Edit"}
                loading={loading}
                error={error}
              />
              <CardBody className="px-5 pb-4">
                <BtcFormInput
                  attributeName={"accountId"}
                  labelText={"Account ID"}
                  disabled={loading || backendCall || !isCreate}
                  formErrors={errors}
                  register={register}
                />
                <BtcFormInput
                  attributeName={"accountName"}
                  labelText={"Account Name"}
                  disabled={loading || backendCall}
                  formErrors={errors}
                  register={register}
                />
                <BtcFormInput
                  attributeName={"hyperscaler"}
                  labelText={"Hyperscaler"}
                  disabled={true}
                  formErrors={errors}
                  register={register}
                />
                <BtcFormInput
                  attributeName={"detectorId"}
                  labelText={"Security Detector ID"}
                  disabled={loading || backendCall}
                  formErrors={errors}
                  register={register}
                />
                <BtcFormInput
                  attributeName={"flatrateCosts"}
                  labelText={"Flatrate Costs"}
                  disabled={loading || backendCall}
                  formErrors={errors}
                  register={register}
                />
              </CardBody>
            </Card>
            {!isCreate && (
              <div className="px-4">
                <Button
                  className="pull-left card-shadow btn--no-margin"
                  color="danger"
                  disabled={loading || backendCall}
                  onClick={() => setShowDeletionModal(true)}
                >
                  <i className="fas fa-trash" /> Delete
                </Button>
              </div>
            )}
            <div className="px-4 pull-right">
              <BtcLink
                className="btn btn-success btn--no-margin card-shadow"
                to={`/admin/company-edit/${companyName}`}
                disabled={loading || backendCall}
              >
                Cancel
              </BtcLink>
              <input
                className="btn btn-success pull-right card-shadow btn--no-margin ml-3"
                type="submit"
                value={isCreate ? "Create AWS Account" : "Save changes"}
                disabled={loading || !isValid || backendCall || !isDirty}
              />
            </div>
          </Form>
        </Col>
      </Row>
      {modalAccount && (
        <DeletionModal
          show={showDeletionModal}
          title={`Delete account ${modalAccount.accountName}`}
          deleteButton={`Delete Account`}
          onDelete={() => doDeleteAWSAccount()}
          onClose={() => setShowDeletionModal(false)}
        />
      )}
    </section>
  );
}

export default AWSAccountEditCard;
