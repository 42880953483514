import React from "react";
import UserEditForm from "./UserEditForm";

function UserCreatePage({ notifyRef }) {
  return (
    <section
      aria-labelledby="user-create-module-page-heading"
      className="content"
    >
      <h2 id="user-create-module-page-heading" className="visually-hidden">
        User Create Page
      </h2>
      <UserEditForm isCreate={true} notifyRef={notifyRef} />
    </section>
  );
}

export default UserCreatePage;
