import { Button, Form, FormGroup } from "reactstrap";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { mfaCodeScheme } from "./authenticationCodeValidation";

export const MfaCodeForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(mfaCodeScheme),
  });

  return (
    <Form onSubmit={handleSubmit(props.handleMfaCode)}>
      <>
        <FormGroup
          className={`form-group ${errors.mfaCode ? "has-danger" : ""}`}
        >
          <label>Enter authentication code</label>
          <input
            autoFocus
            className="form-control"
            type="text"
            {...register("mfaCode")}
          />
          {errors.mfaCode ? (
            <label role="alert" className="error">
              {errors.mfaCode?.message}
            </label>
          ) : null}
        </FormGroup>
        <Button block color="success" disabled={!!errors.mfaCode} type="submit">
          Submit code
        </Button>
      </>
    </Form>
  );
};
