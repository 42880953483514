/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// reactstrap components
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { UserContext } from "../../utils/userContext";
import { useHistory } from "react-router-dom";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import { Auth } from "aws-amplify";
import { MfaSetupForm } from "../../login/MfaSetupForm";
import BtcCardHeader from "../../components/BtcCardHeader";
import NotificationAlert from "react-notification-alert";
import { pushHistory } from "../../utils/urlUtil";

const UserProfile = () => {
  const notifyRef = useRef(null);
  let history = useHistory();
  const { user, activePermission, activeAccountIds } = useContext(UserContext);
  const [startMfaSetup, setStartMfaSetup] = useState(false);
  const [isMfaActive, setIsMfaActive] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  const getCurrentAuthenticatedUser = useCallback(async () => {
    const response = await Auth.currentAuthenticatedUser();
    const mfa = await Auth.getPreferredMFA(response);

    if (mfa === "NOMFA") {
      setIsMfaActive(false);
    } else {
      setIsMfaActive(true);
      setStartMfaSetup(false);
    }

    setCognitoUser(response);
  }, []);

  const customerLogoContainer = document.getElementById("customerLogoImg");
  const isCustomerLogoVisible = customerLogoContainer?.innerHTML !== "";

  const handleChange = (e) => {
    const accessPerms = user.accessPermissions;
    for (let i in accessPerms) {
      if (accessPerms[i].company === e.value) {
        pushHistory(history, accessPerms[i], activeAccountIds);
      }
    }
  };
  const mfaStartSetupHandler = async () => {
    if (isMfaActive) {
      setStartMfaSetup(false);
      setIsMfaActive(false);
      await Auth.setPreferredMFA(cognitoUser, "NOMFA");
    } else {
      setStartMfaSetup(true);
      setIsMfaActive(true);
    }
  };

  useEffect(() => {
    getCurrentAuthenticatedUser().then();
  }, [getCurrentAuthenticatedUser]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <section
        aria-labelledby="user-profile-page-heading"
        className={`content ${
          isCustomerLogoVisible ? "content-with-customer-logo" : ""
        }`}
      >
        <h2 id="user-profile-page-heading" className="visually-hidden">
          User Profile
        </h2>
        <Row className="justify-content-center">
          <Col sm="10" md="8" lg="6" xl="5">
            <BtcNoAspectRatioCard label="Edit Profile">
              <BtcCardHeader title="Edit Profile" icon="far fa-user" />
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-3 pl-3" md="10">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Input
                          disabled
                          placeholder={user.attributes.email}
                          type="email"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-3 pl-3" md="10">
                      <FormGroup>
                        <label>Company</label>

                        <Select
                          name="selectCompany"
                          onChange={handleChange}
                          menuPlacement="auto"
                          defaultValue={{
                            value: activePermission["company"],
                            label: activePermission["company"],
                          }}
                          options={user.company_list.map((i) => {
                            return {
                              value: i,
                              label: i,
                            };
                          })}
                          placeholder="Choose Page"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </BtcNoAspectRatioCard>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="10" md="8" lg="6" xl="5">
            <BtcNoAspectRatioCard>
              <BtcCardHeader title="Multi-Factor authentication" />
              <CardBody>
                <Row>
                  <Col>
                    <span className="mr-4">
                      Current multi-factor authentication status
                    </span>
                    <div className="btc-switch-wrapper">
                      <Switch
                        value={isMfaActive}
                        onColor="default"
                        offColor="default"
                        onChange={() => mfaStartSetupHandler()}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </BtcNoAspectRatioCard>
          </Col>
        </Row>
        {startMfaSetup && (
          <Row className="justify-content-center">
            <Col sm="10" md="8" lg="6" xl="5">
              <BtcNoAspectRatioCard>
                <CardHeader>
                  <h5 className="title">Setup Multi-Factor authentication</h5>
                </CardHeader>
                <CardBody>
                  <span>
                    Configure authentication app like Google Authenticator or
                    Auth to turn on multi-factor authentication
                  </span>
                  <MfaSetupForm
                    updateMfaState={getCurrentAuthenticatedUser}
                    notifyRef={notifyRef}
                  />
                  <Button
                    block
                    color="danger"
                    onClick={() => mfaStartSetupHandler()}
                  >
                    Cancel setup
                  </Button>
                </CardBody>
              </BtcNoAspectRatioCard>
            </Col>
          </Row>
        )}
      </section>
    </>
  );
};

export default UserProfile;
