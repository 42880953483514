/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Collapse, Container, Nav, Navbar } from "reactstrap";
import { CustomerLogoContainer } from "./CustomerLogoContainer";
import AccountSelectionDropdown from "./AccountSelectionDropdown";
import CompanySelectionDropdown from "./CompanySelectionDropdown";
import UserSelectionDropdown from "./UserSelectionDropdown";

const AdminNavbar = () => {
  let history = useHistory();
  const location = useLocation();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    function componentDidMount() {
      if (
        window.outerWidth < 993 &&
        history.location.pathname !== location.pathname &&
        document.documentElement.className.indexOf("nav-open") !== -1
      ) {
        document.documentElement.classList.toggle("nav-open");
      }
    }
    componentDidMount();
  }, [history.location.pathname, location.pathname]);

  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    let navOpen = document.documentElement.classList.toggle("nav-open");
    setSidebarOpen(navOpen);
  };

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  function handleMiniClick() {
    document.body.classList.toggle("sidebar-mini");
  }

  return (
    <>
      <Navbar
        className="btc-sticky-navbar fixed-top"
        expand="md"
        aria-label="navbar"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-icon btn-round"
                color="default"
                id="minimizeSidebar"
                onClick={() => handleMiniClick()}
                aria-label="modules-sidebar toggle"
              >
                <i className="fas fa-chevron-right text-center visible-on-sidebar-mini" />
                <i className="fas fa-chevron-left text-center visible-on-sidebar-regular" />
              </Button>
            </div>
            <div
              className={classnames("navbar-toggle", {
                toggled: sidebarOpen,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <h1 className="btc-navbar-title d-none d-lg-block">
              Utility Cloud Platform
            </h1>
            <h1 className="btc-navbar-title d-block d-lg-none">
              Utility Cloud
            </h1>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            // data-target="#navigation"
            data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={collapseOpen}
          >
            <Nav navbar className={"btc-navbar-group"}>
              <CompanySelectionDropdown />
              <AccountSelectionDropdown />
              <UserSelectionDropdown />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <CustomerLogoContainer />
    </>
  );
};

export default AdminNavbar;
