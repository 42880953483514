// Just for Testing..

import React, { useContext, useEffect, useRef, useState } from "react";
import { CardBody, CardFooter } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { UserContext } from "../../utils/userContext";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import { fetchTickets } from "./issuesResource";
import BtcListRow from "../../components/BtcListRow";
import { useAppContext } from "../../utils/contextLib";

export const IssuesCard = () => {
  const { fetchData } = useAppContext();
  const { activePermission } = useContext(UserContext);
  const [busy, setBusy] = useState(true);
  const [tickets, setTickets] = useState({});

  const notifyRef = useRef(null);
  const [ticketsError, setTicketsError] = useState(null);

  useEffect(() => {
    const company = activePermission["company"];
    const today = new Date();
    fetchData(
      () => fetchTickets(company, today),
      setTickets,
      setTicketsError,
      setBusy,
      notifyRef
    );
  }, [activePermission, fetchData]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcNoAspectRatioCard label="Tickets" className="card-stats">
        <BtcCardHeader
          title="Tickets"
          icon="far fa-clipboard"
          error={ticketsError}
          foreignPageUrl="https://jira.btc-ag.cloud"
          updatedAt={tickets.updatedAt}
          loading={busy}
        />
        <CardBody>
          <BtcListRow
            icon="fas fa-exclamation-triangle"
            text="Incidents"
            loading={busy}
          >
            {tickets.incidents != null ? tickets.incidents : "?"}
          </BtcListRow>
          <BtcListRow
            icon="fas fa-layer-group"
            text="Open Requests"
            loading={busy}
          >
            {tickets.openRequests != null ? tickets.openRequests : "?"}
          </BtcListRow>
          <BtcListRow
            icon="far fa-user"
            text="Requests in Progress"
            loading={busy}
          >
            {tickets.inProgress != null ? tickets.inProgress : "?"}
          </BtcListRow>
          <BtcListRow
            icon="fas fa-check"
            text="Done (within 30d)"
            loading={busy}
          >
            {tickets.done30d != null ? tickets.done30d : "?"}
          </BtcListRow>
        </CardBody>
        <CardFooter>
          <hr />
          <a
            className="btn btn-sm"
            role="button"
            href={tickets.jiraUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open JIRA <i className="fas fa-external-link-alt" />
          </a>
        </CardFooter>
      </BtcNoAspectRatioCard>
    </>
  );
};
