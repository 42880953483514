import React, { useContext } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { pushHistory } from "../utils/urlUtil";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../utils/contextLib";
import { UserContext } from "../utils/userContext";
import { Auth } from "aws-amplify";

UserSelectionDropdown.propTypes = {};

function UserSelectionDropdown() {
  let history = useHistory();
  const { userHasAuthenticated } = useAppContext();
  const { user, activePermission, activeAccountIds } = useContext(UserContext);

  async function handleLogout() {
    await Auth.signOut();
    localStorage.removeItem("mfaSetUp");
    userHasAuthenticated(false);
    history.push("/login");
  }

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        caret
        className="btn-round btn btn-outline-success"
        id="dropdown-basic"
      >
        {user.attributes.email}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          className="primary-text"
          onClick={(_) =>
            pushHistory(
              history,
              activePermission,
              activeAccountIds,
              "/user-profile"
            )
          }
        >
          Profile
        </DropdownItem>
        <DropdownItem className="primary-text" onClick={handleLogout}>
          Logout
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default UserSelectionDropdown;
