import React from "react";
import { Button, Modal } from "reactstrap";
import PropTypes from "prop-types";

const IamRoleChangeModal = ({ show, user, onClose, onChangeRole }) => {
  return (
    <Modal isOpen={show} toggle={onClose}>
      <div className="modal-header justify-content-center">
        <h4 className="title title-up">Change Authorization To...</h4>
      </div>
      <form className="modal-body text-center">
        {["readonly", "poweruser", "admin"].map((role) => (
          <Button
            key={role}
            color="success"
            onClick={() => {
              onChangeRole(user, role);
              onClose();
            }}
          >
            {role}
          </Button>
        ))}
      </form>
      <div className="modal-footer">
        <div className="left-side">
          <Button
            className="btn-link"
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Back
          </Button>
        </div>
      </div>
    </Modal>
  );
};

IamRoleChangeModal.propTypes = {
  show: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
  onChangeRole: PropTypes.func,
};

export default IamRoleChangeModal;
