import React, { useState } from "react";
import { Badge, Button, CardBody, UncontrolledTooltip } from "reactstrap";
import ReactTable from "../../components/ReactTable";
import { notify } from "../../utils/notification";
import PropTypes from "prop-types";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import { prepareForId } from "../../utils/regExpUtil";
import {
  exportTablePDF,
  exportTableXlsxCsv,
  extractFilteredData,
} from "../../utils/exportTableData";
import BtcAccountName from "../../components/BtcAccountName";

EventsTableCard.propTypes = {
  alerts: PropTypes.array,
  alertsError: PropTypes.object,
  notifyRef: PropTypes.any,
  levelFilter: PropTypes.string,
  eventTypeFilter: PropTypes.string,
  serviceFilter: PropTypes.string,
};

const exportConfiguration = {
  tableColumnsNames: [
    "Level",
    "Eventtype",
    "Norm",
    "Norm Items",
    "Source",
    "Service",
    "Account",
    "Count",
    "Solved",
  ],
  dataToExport: [
    "severity",
    "eventType",
    "normName",
    "normItems",
    "actor",
    "entityType",
    "accountName",
    "count",
    "solved",
  ],
  columnsWidth: [
    { wch: 50 },
    { wch: 100 },
    { wch: 60 },
    { wch: 100 },
    { wch: 100 },
    { wch: 50 },
    { wch: 75 },
    { wch: 40 },
    { wch: 40 },
  ],

  columnsWidthXls: [
    { wch: 5 },
    { wch: 20 },
    { wch: 15 },
    { wch: 20 },
    { wch: 20 },
    { wch: 10 },
    { wch: 15 },
    { wch: 7 },
    { wch: 7 },
  ],
};

function EventsTableCard({
  alerts,
  alertsError,
  notifyRef,
  levelFilter,
  eventTypeFilter,
  serviceFilter,
  loading,
  updatedAt,
}) {
  const [filteredTableData, setFilteredTableData] = useState([]);

  function getBadgeVariantByErrorLevel(alertLevel) {
    switch (alertLevel) {
      case "Low":
        return "general-green";
      case "High":
        return "danger";
      case "Medium":
        return "warning";
      default:
        return "success";
    }
  }

  function formatNormItemList(props) {
    const rowId = props.id;

    return (
      <div>
        <ul className="btc-security-ul">
          {props.values.normItems.map((element) => {
            const label = prepareForId(element.split(" - ")[0]);
            const message = element.split(" - ")[1];

            return (
              <li key={`${label}-${rowId}`}>
                <Badge
                  id={`${label}-${rowId}`}
                  className="btc-security-table-normBadge"
                  onClick={(e) =>
                    notify({
                      ref: notifyRef,
                      type: 2,
                      place: "tr",
                      message: element.split(" - ")[1],
                    })
                  }
                  color="success"
                >
                  {element.split(" - ")[0]}
                </Badge>
                <UncontrolledTooltip
                  placement="top"
                  target={`${label}-${rowId}`}
                >
                  {message}
                </UncontrolledTooltip>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  const filterUpdates = [];
  if (levelFilter) {
    filterUpdates.push({ id: "level", value: levelFilter });
  }
  if (eventTypeFilter) {
    filterUpdates.push({ id: "eventType", value: eventTypeFilter });
  }
  if (serviceFilter) {
    filterUpdates.push({ id: "service", value: serviceFilter });
  }

  const divideLongEventsName = (eventName) => {
    return eventName.split("/").reduce((prevValue, currentValue) => {
      return (
        <>
          {prevValue}/<wbr />
          {currentValue}
        </>
      );
    });
  };

  const prepareNormItemsToExport = (dataForExport, exportType) => {
    const dataToEdit = [];
    dataForExport.forEach((item) => {
      let newItem = { ...item };
      let normItems = [...item.normItems];
      newItem.normItems = normItems.join(exportType === "csv" ? ";" : "\n");
      newItem.solved = item.solved ? "Yes" : "No";
      dataToEdit.push(newItem);
    });
    return dataToEdit;
  };

  const getFilteredData = (data) => {
    setFilteredTableData(extractFilteredData(data));
  };

  return (
    <>
      <BtcNoAspectRatioCard label="Events Table">
        <BtcCardHeader
          title="Events"
          error={alertsError}
          loading={loading}
          updatedAt={updatedAt}
          exportDataMenu={true}
          isDataToExportAvailable={filteredTableData.length > 0}
          exportExcelFileFn={() =>
            exportTableXlsxCsv(
              "xlsx",
              prepareNormItemsToExport(filteredTableData, "xlsx"),
              exportConfiguration,
              "security-events"
            )
          }
          exportCsvFileFn={() =>
            exportTableXlsxCsv(
              "csv",
              prepareNormItemsToExport(filteredTableData, "csv"),
              exportConfiguration,
              "security-events"
            )
          }
          exportPdfFileFn={() =>
            exportTablePDF(
              prepareNormItemsToExport(filteredTableData, "pdf"),
              exportConfiguration,
              "landscape",
              "security-events"
            )
          }
        />
        <CardBody>
          <ReactTable
            id="security-table"
            data={alerts}
            filterUpdates={filterUpdates}
            hiddenColumns={["description", "severity"]}
            columns={[
              {
                id: "level",
                Header: "Level",
                accessor: "severityIndex", // index allows sorting, if not low would be higher than medium
                className: "btc-flex-security-level",
                Cell: (props) => (
                  <Badge
                    color={getBadgeVariantByErrorLevel(
                      props.row.values.severity
                    )}
                  >
                    {props.row.values.severity}
                  </Badge>
                ),
              },
              {
                Header: "Severity",
                accessor: "severity",
              },
              {
                id: "eventType",
                Header: "eventType",
                accessor: "eventType",
                className: "btc-flex-security-eventtype",
                Cell: (props) => (
                  <>
                    <Button
                      id={prepareForId(props.value)}
                      className="btn btn-neutral p-0 btc-security-table-btn "
                    >
                      {divideLongEventsName(props.value)}
                    </Button>
                    <UncontrolledTooltip
                      placement="top"
                      target={prepareForId(props.value)}
                    >
                      {props.row.values.description}
                    </UncontrolledTooltip>
                  </>
                ),
              },
              {
                Header: "Description",
                accessor: "description",
              },
              {
                Header: "Norm",
                accessor: "normName",
              },
              {
                Header: "Norm Item",
                accessor: "normItems",
                Cell: (props) => formatNormItemList(props.row),
              },
              {
                Header: "Source",
                accessor: "actor",
              },
              {
                id: "service",
                Header: "Service",
                accessor: "entityType",
              },
              {
                Header: "Account",
                accessor: "accountName",
                Cell: (item) => (
                  <BtcAccountName
                    accountName={item.value}
                    hyperscaler={item.row.original.hyperscaler}
                  />
                ),
              },
              {
                Header: "Count",
                accessor: "count",
                disableFilters: true,
              },
              {
                Header: "Solved",
                accessor: "solvedAsString",
                disableFilters: true,
                Cell: (item) =>
                  item.row.original.solved ? (
                    <i className="fas fa-check fa-lg text-success" />
                  ) : (
                    <i className="fas fa-times fa-lg text-danger" />
                  ),
              },
            ]}
            className="prevent-too-small-columns"
            getFilteredData={getFilteredData}
          />
        </CardBody>
      </BtcNoAspectRatioCard>
    </>
  );
}

export default EventsTableCard;
