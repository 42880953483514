import React from "react";
import { Badge } from "reactstrap";
import PropTypes from "prop-types";

BtcInfoBadge.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};

function BtcInfoBadge({ label, children, className = "" }) {
  return (
    <Badge className={"has-text-btc-blue " + className} color="info" pill>
      {label ? label + ": " : ""}
      {children}
    </Badge>
  );
}

export default BtcInfoBadge;
