import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";

export const PasswordResetPasswordChanged = (props) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Reset Password</CardTitle>
      </CardHeader>
      <CardBody>
        <span>
          The password has been changed, please return to the login screen
        </span>
        <Button block color="success" onClick={props.redirectToLogin}>
          Return to the login screen
        </Button>
      </CardBody>
    </Card>
  );
};
