import { Card, CardBody, CardFooter } from "reactstrap";
import BtcCardHeader from "../../components/BtcCardHeader";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import { UserContext } from "../../utils/userContext";
import NotificationAlert from "react-notification-alert";
import {
  createCostBreakDownPlotData,
  dateRanges,
} from "./costBreakdownPlotRecources";
import {
  createDatasets,
  getFiveFourMostExpensive,
  getLabelsFromData,
  shortenDataPlotLabel,
} from "./costBreakDownPlotUtils";
import { shouldItemBeVisible } from "../../utils/accountUtil";

export const CostsBreakdownCard = ({
  switchDetailsHandler,
  serviceCosts,
  error,
  loading,
  categoryAttribute,
  categoryLabel,
}) => {
  const [filteredDataToPlot, setFilteredDataToPlot] = useState(
    createCostBreakDownPlotData(categoryAttribute)
  );
  const [currentSelectedRange, setCurrentSelectedRange] = useState(
    dateRanges[2]
  );
  const { activeAccountIds } = useContext(UserContext);
  const [busy, setBusy] = useState(true);
  const notifyRef = useRef(null);
  const filterDataByRange = useCallback(
    (range) => {
      setBusy(true);
      let chartData = JSON.parse(
        sessionStorage.getItem(categoryAttribute + "plotDatasets")
      );
      let chartLabels = JSON.parse(
        sessionStorage.getItem(categoryAttribute + "plotLabels")
      );
      const monthNumber = new Date().getMonth();

      chartData.reverse();
      chartLabels.reverse();

      if (range && range.value !== "year_to_date") {
        chartLabels = chartLabels?.slice(-range.value);
      } else {
        chartLabels = chartLabels?.slice(chartLabels.length - monthNumber - 1);
      }

      chartData?.forEach((dataset) => {
        if (range && range.value !== "year_to_date") {
          dataset.data = dataset.data.slice(-range.value);
        } else {
          dataset.data = dataset.data.slice(
            dataset.data.length - monthNumber - 1
          );
        }
      });

      const costBreakDownPlotData =
        createCostBreakDownPlotData(categoryAttribute);
      costBreakDownPlotData.labels = chartLabels;
      costBreakDownPlotData.datasets = chartData;
      setFilteredDataToPlot(costBreakDownPlotData);
      setBusy(false);
    },
    [categoryAttribute]
  );

  const prepareServicesCostsForPlot = useCallback(
    (costsData) => {
      const dataMappedByDate = {};
      costsData.forEach((item) => {
        if (dataMappedByDate[item.date]) {
          dataMappedByDate[item.date].push({
            category: shortenDataPlotLabel(item[categoryAttribute]),
            costs: item.costs,
          });
        } else {
          dataMappedByDate[item.date] = [
            { category: item[categoryAttribute], costs: item.costs },
          ];
        }
      });

      let labels = [];
      let categories = [];
      let datasets = [];

      if (Object.keys(dataMappedByDate).length > 0) {
        labels = getLabelsFromData(dataMappedByDate);
        categories = getFiveFourMostExpensive(
          dataMappedByDate,
          categoryAttribute
        );
        datasets = createDatasets(labels, categories, dataMappedByDate);
      }

      const costBreakDownPlotData =
        createCostBreakDownPlotData(categoryAttribute);
      costBreakDownPlotData.labels = labels;
      costBreakDownPlotData.datasets = datasets;
      sessionStorage.setItem(
        categoryAttribute + "plotLabels",
        JSON.stringify(labels)
      );
      sessionStorage.setItem(
        categoryAttribute + "plotDatasets",
        JSON.stringify(datasets)
      );
      setFilteredDataToPlot(costBreakDownPlotData);
      filterDataByRange({
        label: "Last 6 Months",
        value: 6,
      });
    },
    [categoryAttribute, filterDataByRange]
  );

  useEffect(() => {
    const filteredServiceCosts = serviceCosts.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );

    if (filteredServiceCosts.length === 0) {
      switchDetailsHandler();
    }

    prepareServicesCostsForPlot(filteredServiceCosts);
    return () => {
      setFilteredDataToPlot(null);
    };
  }, [
    activeAccountIds,
    prepareServicesCostsForPlot,
    serviceCosts,
    switchDetailsHandler,
  ]);

  const dateRangeSelectorHandler = (range) => {
    setCurrentSelectedRange(range);
    filterDataByRange(range);
  };

  const dateRangeSelector = (
    <Select
      id="dateRange"
      placeholder="Select range"
      value={currentSelectedRange}
      options={dateRanges}
      onChange={(selectedRange) => dateRangeSelectorHandler(selectedRange)}
    />
  );

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <Card className="card-stats btc-card-vh-40">
        <BtcCardHeader
          title="Costs Breakdown"
          children={dateRangeSelector}
          childSize={3}
          error={error}
          description={`grouped by ${categoryLabel}`}
        />
        <CardBody className="btc-stretch__stretched">
          {!(busy || loading) && (
            <Bar
              redraw={true}
              data={filteredDataToPlot}
              options={filteredDataToPlot.options}
              type="bar"
            />
          )}
        </CardBody>
        <CardFooter />
      </Card>
    </>
  );
};
