import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BtcCard from "../../components/BtcCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import { CardFooter, Col, Input, Label, Row } from "reactstrap";
import { Line } from "react-chartjs-2";
import {
  generateCostPreset,
  generateOptionsForBudget,
} from "./constants/costsData";
import BtcSpinner from "../../components/BtcSpinner";
import { getMaxProperty } from "../../utils/arrayUtil";
import BtcInfoBadge from "../../components/BtcInfoBadge";
import BtcStretchedContentCardBody from "../../components/BtcStretchedContentCardBody";

BudgetPlotCard.propTypes = {
  budgets: PropTypes.array,
  budgetError: PropTypes.object,
  loading: PropTypes.bool,
  selectedBudgetName: PropTypes.string,
};

function BudgetPlotCard({ budgets, budgetError, loading, selectedBudgetName }) {
  const [updatedAt, setUpdatedAt] = useState(null);
  const [graphData, setGraphData] = useState(generateCostPreset());
  const [graphOptions, setGraphOptions] = useState(
    generateOptionsForBudget(null)
  );
  const [showLimit, setShowLimit] = useState(false);

  useEffect(() => {
    const filteredBudget = budgets.filter(
      (b) => b.budgetName === selectedBudgetName
    );
    filteredBudget.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });

    setUpdatedAt(getMaxProperty(filteredBudget, "updatedAt"));
    setGraphData(buildGraphData(filteredBudget));
    if (showLimit && filteredBudget.length > 0) {
      setGraphOptions(
        generateOptionsForBudget(
          filteredBudget[filteredBudget.length - 1].limit
        )
      );
    } else {
      setGraphOptions(generateOptionsForBudget(null));
    }
  }, [budgets, selectedBudgetName, showLimit]);

  function buildGraphData(budgetArray) {
    let costPreset = generateCostPreset();
    costPreset.datasets[0].data = budgetArray.map((b) => b.used);
    costPreset.labels = budgetArray.map((b) =>
      new Date(b.date).toLocaleString(undefined, {
        month: "numeric",
        day: "numeric",
      })
    );
    return costPreset;
  }

  return (
    <>
      <BtcCard label="Budget Plot">
        <BtcCardHeader
          title="Budget"
          updatedAt={updatedAt}
          icon="fas fa-euro-sign"
          error={budgetError}
          loading={loading}
        />
        <BtcStretchedContentCardBody>
          <Line data={graphData} options={graphOptions} />
        </BtcStretchedContentCardBody>
        <CardFooter>
          <hr />
          <Row>
            <Col xs="9">
              {loading ? (
                <BtcSpinner />
              ) : (
                <BtcInfoBadge className="btc-shorten-overflow-text">
                  {selectedBudgetName}
                </BtcInfoBadge>
              )}
            </Col>
            <Col xs="3">
              <div className="small-checkbox pull-right">
                <Input
                  type="checkbox"
                  id="show-budget"
                  className="danger-text"
                  onChange={(e) => setShowLimit(e.target.checked)}
                />
                <Label for="show-budget">Limit</Label>
              </div>
            </Col>
          </Row>
        </CardFooter>
      </BtcCard>
    </>
  );
}

export default BudgetPlotCard;
