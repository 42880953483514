import React, { useEffect, useState } from "react";
import ReactTable from "../../../components/ReactTable";
import BtcNoAspectRatioCard from "../../../components/BtcNoAspectRatioCard";
import BtcCardHeader from "../../../components/BtcCardHeader";
import { CardBody } from "reactstrap";
import { fetchCompanies } from "../adminResource";
import PropTypes from "prop-types";
import { useAppContext } from "../../../utils/contextLib";
import BtcLink from "../../../components/BtcLink";

CompanyTableCard.propTypes = {
  notifyRef: PropTypes.object,
};

function CompanyTableCard({ notifyRef }) {
  const [busy, setBusy] = useState(true);
  const [error, setError] = useState(null);
  const [companies, setCompanies] = useState([]);
  const { fetchData } = useAppContext();

  useEffect(() => {
    fetchData(
      () => fetchCompanies(),
      (companies) =>
        setCompanies(companies.map((company) => ({ name: company }))),
      setError,
      setBusy,
      notifyRef
    );
  }, [notifyRef, fetchData]);

  return (
    <BtcNoAspectRatioCard label="Company Table">
      <BtcCardHeader title="Company" error={error} loading={busy} childSize={6}>
        <BtcLink
          className="btn btn-primary pull-right"
          to="/admin/company-create"
        >
          <i className="fas fa-building" /> Create Company
        </BtcLink>
      </BtcCardHeader>
      <CardBody>
        <ReactTable
          data={companies}
          columns={[
            {
              Header: "Company",
              accessor: "name",
            },
            {
              Header: "Actions",
              className: "rt-th--right-align btc-10p-column",
              disableSortBy: true,
              disableFilters: true,
              Cell: (item) => {
                return (
                  <div className="actions-right">
                    <BtcLink
                      className="btn btn-sm btn-icon btn-link btn-warning edit"
                      to={`/admin/company-edit/${item.row.original.name}`}
                    >
                      <i className="fa fa-edit" />
                    </BtcLink>{" "}
                  </div>
                );
              },
            },
          ]}
        />
      </CardBody>
    </BtcNoAspectRatioCard>
  );
}

export default CompanyTableCard;
