/*eslint-disable*/
import React, { Fragment, useEffect } from "react";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import { Button, Col, Container, FormGroup, Input, Row } from "reactstrap";

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <FormGroup>
      <Input
        value={filterValue || ""}
        placeholder={`Search ${count} records...`}
        type="text"
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({
  columns,
  data,
  initialFilters,
  filterUpdates,
  className = "",
  getFilteredData = () => {},
  hiddenColumns = [],
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    rows,
    state: { pageIndex, pageSize, sortBy, filters },
    nextPage,
    pageOptions,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        pageSize: 10,
        pageIndex: 0,
        hiddenColumns: hiddenColumns,
        filters: initialFilters || [],
      },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  // TODO this is ugly but I found no better way but to manipulate the prop filterUpdates to prevent rerender
  if (filterUpdates && filterUpdates.length) {
    let current;
    while ((current = filterUpdates.pop())) {
      setFilter(current.id, current.value);
    }
  }

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
    function () {}
  );
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];

  const isFooterShouldBeDisplayed = (groupData) => {
    let isFooterVisible = false;
    groupData.map((group) => {
      group.headers.map((header) => {
        if (header.Footer.length > 0) {
          isFooterVisible = true;
        }
      });
    });
    return isFooterVisible;
  };

  const classNameForCell = (name, suffix) => {
    const regex = new RegExp(/[a-zA-z-0-9-\s]/g);
    const outputString = `${name.match(regex).join("").toLowerCase()}`;
    const withOutMultipleSpaces = outputString.replace(/\s+/g, " ").trim();
    return `${withOutMultipleSpaces.replaceAll(" ", "-")}${suffix}`;
  };

  const changeDropDownMenuStyle = (base, _) => {
    // One ms delay is required to apply change of pointing arrow
    const dropdownMenu = document.querySelector(".react-select__menu");
    setTimeout(() => {
      if (base.top) {
        base.top = "105%";
        base.bottom = "unset";
      } else {
        base.bottom = "105%";
        base.top = "unset";
        dropdownMenu?.classList.add("arrow-at-bottom");
      }
    }, 1);

    return base;
  };

  useEffect(() => {
    getFilteredData(rows);
  }, [sortBy, filters]);

  return (
    <>
      <div
        className={
          "ReactTable -striped -highlight warning-pagination " + className
        }
      >
        <table {...getTableProps()} className="rt-table responsive">
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup) => (
              <Fragment key={headerGroup}>
                <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                  {headerGroup.headers.map((column, _) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={classnames(
                        `rt-th rt-resizable-header ${
                          column.className || ""
                        } ${classNameForCell(column.Header, "-column")}`
                      )}
                    >
                      <div className="rt-resizable-header-content">
                        {column.render("Header")}
                        {!column.disableSortBy && (
                          <span className="pl-1">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i className="fas fa-sort-down" />
                              ) : (
                                <i className="fas fa-sort-up" />
                              )
                            ) : (
                              <i className="fas fa-sort" />
                            )}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
                <tr className="rt-tr">
                  {headerGroup.headers.map((column, key) => (
                    <th
                      key={key}
                      className={classnames(
                        `rt-th rt-resizable-header ${
                          column.className || ""
                        }  ${classNameForCell(column.Header, "-column")}`
                      )}
                    >
                      <div className="rt-resizable-header-content">
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  ))}
                </tr>
              </Fragment>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);

              return (
                <tr
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 }
                  )}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`rt-td ${
                          cell.column.className || ""
                        } ${classNameForCell(cell.column.Header, "-column")}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {isFooterShouldBeDisplayed(footerGroups) ? (
            <tfoot>
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()} className="rt-tfoot">
                  {group.headers.map((column) => (
                    <td
                      {...column.getFooterProps()}
                      className={`rt-td ${classNameForCell(
                        column.Header,
                        "-column"
                      )}`}
                    >
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          ) : null}
        </table>
        <div className="pagination-top">
          <div className="-pagination">
            <div className="-previous">
              <Button
                variant="primary"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </Button>{" "}
            </div>
            <div className="-center">
              <Container>
                <Row className="justify-content-center">
                  <Col md="4" sm="6" xs="12">
                    <Select
                      name="pageSelect"
                      value={{
                        value: pageIndex,
                        label: "Page " + (pageIndex + 1),
                      }}
                      onChange={(value) => {
                        gotoPage(value.value);
                      }}
                      styles={{ menu: changeDropDownMenuStyle }}
                      menuPlacement="auto"
                      options={pageSelectData.map((prop, key) => {
                        return {
                          value: key,
                          label: "Page " + (key + 1),
                        };
                      })}
                      placeholder="Choose Page"
                    />
                  </Col>
                  <Col md="4" sm="6" xs="12">
                    <Select
                      name="numberOfRows"
                      value={{ value: pageSize, label: pageSize + " rows" }}
                      onChange={(value) => {
                        setPageSize(value.value);
                      }}
                      styles={{ menu: changeDropDownMenuStyle }}
                      menuPlacement="auto"
                      options={numberOfRowsData.map((prop) => {
                        return {
                          value: prop,
                          label: prop + " rows",
                        };
                      })}
                      placeholder="Choose Rows"
                    />
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="-next">
              <Button
                variant="primary"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </Button>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
