import transnetBWlogo from "../assets/img/customers-logos/transnetBW_logo.png";
import React, { useContext } from "react";
import { UserContext } from "../utils/userContext";

export const CustomerLogoContainer = () => {
  const { activePermission } = useContext(UserContext);

  return (
    <div className="customer-logo-container" id="customerLogoImg">
      {activePermission?.company === "TBW" ? (
        <img alt="TransnetBW Logo" src={transnetBWlogo} />
      ) : (
        ""
      )}
    </div>
  );
};
