import { sumTotalMonthlyCost } from "./costBreakDownPlotUtils";

const maximumLabelLength = 17;

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.zIndex = "100";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context, tooltipText, event) => {
  const chart = context.chart;
  const tooltip = context.chart.tooltip;

  const tooltipEl = getOrCreateTooltip(chart);
  tooltip.body = [
    {
      before: [],
      lines: [`${tooltipText}`],
      after: [],
    },
  ];

  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body) => {
      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const text = document.createTextNode(body);

      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild(tableBody);
  }

  const positionX = event.x;
  const positionY = chart.chartArea.top + event.y;

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + "px";
  tooltipEl.style.top = positionY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

const getLegendLabel = (label) => {
  if (label.length > maximumLabelLength) {
    return `${label.slice(0, maximumLabelLength)}...`;
  } else {
    return label;
  }
};

const generateDataForLabel = (chart, labelSource) => {
  return chart.data[labelSource].map((item, i) => {
    let isHidden = false;

    if (chart.legend.legendItems && chart.legend.legendItems[i]) {
      isHidden = chart.legend.legendItems[i].hidden;
    }

    return {
      text: getLegendLabel(labelSource === "datasets" ? item.label : item),
      fillStyle:
        labelSource === "datasets"
          ? item.backgroundColor
          : chart.data.datasets[0].backgroundColor[i],
      lineWidth: 0,
      hidden: isHidden,
      datasetIndex: i,
    };
  });
};

export function createCostBreakDownPlotData(categoryAttribute) {
  return {
    labels: [],
    datasets: [],
    options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: true,
          labels: {
            generateLabels: (chart) => {
              // Label source value depends on chart type is can be 'datasets' or 'labels'
              return generateDataForLabel(chart, "datasets");
            },
          },
          onHover: (e, legendItem, legend) => {
            const tooltipText =
              legend.chart.data.datasets[legendItem.datasetIndex].label;

            if (tooltipText.length > maximumLabelLength) {
              externalTooltipHandler(legend, tooltipText, e);
            }
          },
          onLeave: (e, legendItem, legend) => {
            const ci = legend.chart;
            const tooltipEl = ci.canvas.parentNode.querySelector("div");
            if (tooltipEl?.style?.opacity) {
              tooltipEl.style.opacity = "0";
            }
          },
          onClick: (e, legendItem, legend) => {
            const index = legendItem.datasetIndex;
            const ci = legend.chart;

            if (!legendItem.hidden) {
              ci.setDatasetVisibility(index, false);
            } else {
              ci.setDatasetVisibility(index, true);
            }

            ci.update();
            legend.legendItems[index].hidden = !legendItem.hidden;
            legendItem.hidden = !legendItem.hidden;
          },
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item) => {
              return `${item.dataset.label} ${item.formattedValue} €`;
            },
            afterLabel(item) {
              return `Total costs: ${sumTotalMonthlyCost(
                item.label,
                categoryAttribute
              )}€`;
            },
          },
          bodySpacing: 10,
          boxPadding: 5,
          padding: 10,
        },
      },
    },
  };
}

export const dateRanges = [
  { label: "Current Month", value: 1 },
  { label: "Last 3 Months", value: 3 },
  { label: "Last 6 Months", value: 6 },
  { label: "Last 12 Months", value: 12 },
  { label: "Year to Date", value: "year_to_date" },
];

export const monthsNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const seriesBackgroundColors = [
  "rgb(116, 195, 196)",
  "rgb(184, 213, 184)",
  "rgb(215, 180, 158)",
  "rgb(220, 96, 46)",
  "rgb(65, 64, 102)",
  "rgb(193, 102, 107)",
];

export const breakdownCostsPieChartData = (data) => {
  const labels = [];
  const values = [];

  data.forEach((item) => {
    labels.push(item.label);
    values.push(item.value);
  });

  const chartData = {
    labels: labels,
    datasets: [
      {
        labels: "€",
        data: values,
        backgroundColor: [...seriesBackgroundColors],
        borderWidth: 0,
      },
    ],
    options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: true,
          labels: {
            generateLabels: (chart) => {
              // Label source value depends on chart type is can be 'datasets' or 'labels'
              return generateDataForLabel(chart, "labels");
            },
          },
          onHover: (e, legendItem, legend) => {
            const tooltipText =
              legend.chart.data.labels[legendItem.datasetIndex];
            if (tooltipText.length > maximumLabelLength) {
              externalTooltipHandler(legend, tooltipText, e);
            }
          },
          onLeave: (e, legendItem, legend) => {
            const ci = legend.chart;
            const tooltipEl = ci.canvas.parentNode.querySelector("div");
            if (tooltipEl?.style?.opacity) {
              tooltipEl.style.opacity = "0";
            }
          },
          onClick: (e, legendItem, legend) => {
            const index = legendItem.datasetIndex;
            const ci = legend.chart;
            ci.toggleDataVisibility(index);
            ci.update();
            legend.legendItems[index].hidden = !legendItem.hidden;
            legendItem.hidden = !legendItem.hidden;
          },
        },
        tooltip: {
          enabled: false,
          external: function (context) {
            const { chart, tooltip } = context;
            const tooltipEl = getOrCreateTooltip(chart);

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set Text
            if (tooltip.body) {
              const titleLines = tooltip.title || [];
              const bodyLines = tooltip.body.map((b) => b.lines);

              const tableHead = document.createElement("thead");

              titleLines.forEach((title) => {
                const tr = document.createElement("tr");
                tr.style.borderWidth = 0;

                const th = document.createElement("th");
                th.style.borderWidth = 0;
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
              });

              const tableBody = document.createElement("tbody");
              bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i];

                const span = document.createElement("span");
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = "2px";
                span.style.marginRight = "10px";
                span.style.height = "10px";
                span.style.width = "10px";
                span.style.display = "inline-block";

                const tr = document.createElement("tr");
                tr.style.backgroundColor = "inherit";
                tr.style.borderWidth = 0;

                const td = document.createElement("td");
                td.style.borderWidth = 0;

                const text = document.createTextNode(body + "€");

                td.appendChild(span);
                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
              });

              const tableRoot = tooltipEl.querySelector("table");

              // Remove old children
              while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
              }

              // Add new children
              tableRoot.appendChild(tableHead);
              tableRoot.appendChild(tableBody);
            }

            const { offsetLeft: positionX, offsetTop: positionY } =
              chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + "px";
            tooltipEl.style.top = positionY + tooltip.caretY + "px";
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding =
              tooltip.options.padding + "px " + tooltip.options.padding + "px";
          },
        },
      },
    },
  };
  return chartData;
};
