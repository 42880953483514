import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import { AppContext } from "./utils/contextLib";

import UserView from "page/UserView.js";

import Login from "./login/Login";

import { PasswordChange } from "login/PasswordChange";
import { PasswordReset } from "login/PasswordReset";
import { NoAuthenticatedUserError } from "./errors/NoAuthenticatedUserError";
import { UserProvider } from "./utils/userContext";
import { MFASetup } from "./login/MFASetup";
import { notifyError } from "./utils/notification";

const App = () => {
  let history = useHistory();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [mfaSetupProcessStarted, setMfaSetupProcessStarted] = useState(false);
  const [changePasswordProcessStarted, setChangePasswordProcessStarted] =
    useState(false);
  useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  useEffect(onLoad, [history]);

  function onLoad() {
    async function initialize() {
      let user = await Auth.currentSession();
      if (user == null) {
        throw new NoAuthenticatedUserError();
      }

      if (user.challengeName !== void 0) {
        // Password has to be changed
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setChangePasswordProcessStarted(true);
          history.push("/changepassword");
        }
        // MFA setup has to be made
        if (user.challengeName === "MFA_SETUP") {
          setMfaSetupProcessStarted(true);
          history.push("/mfasetup");
        }
      } else {
        setMfaSetupProcessStarted(false);
        setChangePasswordProcessStarted(false);
        userHasAuthenticated(true);
      }
    }

    initialize()
      .catch((e) => {
        console.warn(e);
        // No current active User can be fetched
        // e could be NoAuthenticatedUserError, e message "No current User" or UserNotFoundException from Cognito
        userHasAuthenticated(false);
      })
      .finally(() => {
        setMfaSetupProcessStarted(false);
        setChangePasswordProcessStarted(false);
        setIsAuthenticating(false);
      });
  }

  const retrieveCurrentSessionAndFetchData = (onSuccess) => {
    return Auth.currentSession()
      .then(() => {
        return onSuccess();
      })
      .catch((err) => {
        console.error(err);
        Auth.signOut().then(() => {
          localStorage.removeItem("mfaSetUp");
          userHasAuthenticated(false);
          history?.push("/login");
        });
      });
  };

  const fetchData = (
    fetchFunction,
    onSuccess,
    setError,
    setLoading,
    notifyRef,
    onError = (e) => {}
  ) => {
    setLoading(true);
    setError(null);
    retrieveCurrentSessionAndFetchData(() => {
      fetchFunction()
        .then((data) => {
          onSuccess(data);
        })
        .catch((e) => {
          console.error(e);
          notifyError(notifyRef, e);
          setError(e);
          onError(e);
        })
        .finally(() => setLoading(false));
    });
  };

  const fetchMultipleData = (
    promises,
    setError,
    setLoading,
    notifyRef,
    onError = (e) => {}
  ) => {
    retrieveCurrentSessionAndFetchData(() => {
      setLoading(true);
      setError(null);
      Promise.all(promises)
        .catch((e) => {
          console.error(e);
          notifyError(notifyRef, e);
          setError(e);
          onError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    !isAuthenticating && (
      <BrowserRouter>
        <AppContext.Provider
          value={{
            isAuthenticated,
            userHasAuthenticated,
            setMfaSetupProcessStarted,
            setChangePasswordProcessStarted,
            cognitoUser,
            setCognitoUser,
            fetchData,
            fetchMultipleData,
          }}
        >
          <UserProvider>
            <Switch>
              <Route exact path="/login">
                {isAuthenticated ? <Redirect to="/dashboard" /> : <Login />}
              </Route>
              <Route exact path="/resetpassword">
                {isAuthenticated ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <PasswordReset />
                )}
              </Route>
              <Route path="/changepassword">
                {changePasswordProcessStarted ? (
                  <PasswordChange />
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
              <Route path="/mfasetup">
                {mfaSetupProcessStarted ? (
                  <MFASetup />
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
              {!isAuthenticated && <Redirect to="/login" />}
              <Route path="/" component={UserView} />
            </Switch>
          </UserProvider>
        </AppContext.Provider>
      </BrowserRouter>
    )
  );
};
export default App;
