import React from "react";
import { CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import { Line } from "react-chartjs-2";
import { BTCChart2, chartExample1 } from "../../variables/charts";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";

CapacityCostsCard.propTypes = {};

function CapacityCostsCard(props) {
  return (
    <BtcNoAspectRatioCard label="Capacity Costs">
      <CardHeader>
        <Row>
          <Col xs="7">
            <div className="numbers pull-left">Capacity</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <h6 className="big-title">total costs</h6>
        <Line
          data={chartExample1.data}
          options={chartExample1.options}
          height={300}
          width={826}
        />
      </CardBody>
      <CardBody>
        <Line
          data={BTCChart2.data}
          options={BTCChart2.options}
          height={250}
          width={826}
        />
      </CardBody>
      <CardFooter />
    </BtcNoAspectRatioCard>
  );
}

export default CapacityCostsCard;
