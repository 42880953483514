import React, { useContext, useEffect, useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import { UserContext } from "../../utils/userContext";
import { fetchAlerts } from "./alertResource";
import EventsTableCard from "./EventsTableCard";
import EventsByTypeCard from "./EventsByTypeCard";
import EventsByServiceCard from "./EventsByServiceCard";
import EventsByAlertLevelCard from "./EventsByAlertLevelCard";
import EventsMapCard from "./EventsMapCard";
import { Col, Row } from "reactstrap";
import { UnexpectedError } from "../../errors/UnexpectedError";
import { shouldItemBeVisible } from "../../utils/accountUtil";
import { getMaxProperty } from "../../utils/arrayUtil";
import { useAppContext } from "../../utils/contextLib";
import { EventHistoryPlotCard } from "./EventHistoryPlotCard";
import InspectorFindingsTableCard from "./InspectorFindingsTableCard";

export const Security = () => {
  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);

  // Sets the Card to busy, which means it is just doing something (calculation or async requests)
  const [busy, setBusy] = useState(true);
  const [fetchedAlerts, setFetchedAlerts] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const notifyRef = useRef(null);
  const [alertsError, setAlertsError] = useState(null);
  const [levelFilter, setLevelFilter] = useState(null);
  const [eventTypeFilter, setEventTypeFilter] = useState(null);
  const [serviceFilter, setServiceFilter] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);

  const customerLogoContainer = document.getElementById("customerLogoImg");
  const isCustomerLogoVisible = customerLogoContainer?.innerHTML !== "";

  useEffect(() => {
    let company = activePermission["company"];
    fetchData(
      () => fetchAlerts(company),
      (fetchedAlerts) => {
        if (fetchedAlerts == null) {
          throw new UnexpectedError("Alerts are undefined.");
        }
        setFetchedAlerts(fetchedAlerts);
        setUpdatedAt(getMaxProperty(fetchedAlerts, "updatedAt"));
      },
      setAlertsError,
      setBusy,
      notifyRef
    );
  }, [activePermission, fetchData]);

  useEffect(() => {
    if (!fetchedAlerts) {
      return;
    }
    const alerts = fetchedAlerts.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );
    setAlerts(alerts);
  }, [fetchedAlerts, activeAccountIds]);

  return (
    <section
      aria-labelledby="security-module-page-heading"
      className={`content ${
        isCustomerLogoVisible ? "content-with-customer-logo" : ""
      }`}
    >
      <h2 id="security-module-page-heading" className="visually-hidden">
        Security
      </h2>
      <NotificationAlert ref={notifyRef} />
      <Row>
        {activePermission.extended && (
          <Col xl="4" lg="6">
            <EventHistoryPlotCard />
          </Col>
        )}
        <Col
          xl={activePermission.extended ? 8 : 12}
          lg={activePermission.extended ? 6 : 12}
        >
          <EventsMapCard
            alerts={alerts}
            alertsError={alertsError}
            loading={busy}
          />
        </Col>
      </Row>
      <Row>
        <Col xl="4" lg="6" md="6" sm="6">
          <EventsByAlertLevelCard
            alerts={alerts.filter((item) => !item.solved)}
            alertsError={alertsError}
            setTableFilterValue={setLevelFilter}
            loading={busy}
          />
        </Col>
        <Col xl="4" lg="6" md="6" sm="6">
          <EventsByServiceCard
            alerts={alerts.filter((item) => !item.solved)}
            alertsError={alertsError}
            setTableFilterValue={setServiceFilter}
            loading={busy}
          />
        </Col>
        <Col xl="4" lg="6" md="12">
          <EventsByTypeCard
            alerts={alerts.filter((item) => !item.solved)}
            alertsError={alertsError}
            setTableFilterValue={setEventTypeFilter}
            loading={busy}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EventsTableCard
            alerts={alerts}
            alertsError={alertsError}
            notifyRef={notifyRef}
            levelFilter={levelFilter}
            eventTypeFilter={eventTypeFilter}
            serviceFilter={serviceFilter}
            loading={busy}
            updatedAt={updatedAt}
          />
        </Col>
      </Row>
      {activePermission.extended && (
        <Row>
          <Col>
            <InspectorFindingsTableCard notifyRef={notifyRef} />
          </Col>
        </Row>
      )}
    </section>
  );
};
