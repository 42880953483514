import React from "react";
import { Card } from "reactstrap";

function BtcNoAspectRatioCard({ className = "", children, label }) {
  return (
    <Card className={"btc-card " + className} tag="section" aria-label={label}>
      {children}
    </Card>
  );
}

export default BtcNoAspectRatioCard;
