/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
// react plugin used to create charts
// react plugin for creating vector maps
import { UserContext } from "../../utils/userContext";
// reactstrap components
import { Col, Row } from "reactstrap";
import JiraCostsCard from "./JiraCostsCard";
import AmmCostsCard from "./AmmCostsCard";
import NmsCostsCard from "./NmsCostsCard";
import VmsCostsCard from "./VmsCostsCard";
import CapacityCostsCard from "./CapacityCostsCard";
import TbiCostsCard from "./TbiCostsCard";

const Services = () => {
  const { activePermission } = useContext(UserContext);

  const customerLogoContainer = document.getElementById("customerLogoImg");
  const isCustomerLogoVisible = customerLogoContainer?.innerHTML !== "";

  if (activePermission["company"] === "BTC") {
    return (
      <>
        <section
          aria-labelledby="services-module-page-heading"
          className={`content ${
            isCustomerLogoVisible ? "content-with-customer-logo" : ""
          }`}
        >
          <h2 id="services-module-page-heading" className="visually-hidden">
            Services
          </h2>
          <Row>
            <Col lg="4" sm="6">
              <JiraCostsCard />
            </Col>
            <Col lg="4" sm="6">
              <AmmCostsCard />
            </Col>
            <Col lg="4" sm="6" />
          </Row>
        </section>
      </>
    );
  } else if (activePermission["company"] === "TSOBSS") {
    return (
      <>
        <div
          className={`content ${
            isCustomerLogoVisible ? "content-with-customer-logo" : ""
          }`}
        >
          <Row>
            <Col lg="4" sm="6">
              <NmsCostsCard />
            </Col>
            <Col lg="4" sm="6">
              <VmsCostsCard />
            </Col>
            <Col lg="4" sm="6" />
          </Row>
          <Row>
            <Col lg="4" sm="6">
              <CapacityCostsCard />
            </Col>
            <Col lg="4" sm="6">
              <TbiCostsCard />
            </Col>
            <Col lg="4" sm="6" />
          </Row>
        </div>
      </>
    );
  }
};

export default Services;
