import { API, graphqlOperation } from "aws-amplify";
import { parseAndThrowErrors } from "../../errors/GraphQlError";
import { UnexpectedError } from "../../errors/UnexpectedError";

export async function fetchContactData(company) {
  const contactDataQuery = `query {
      listCompanyConfigParams(input: {tenant: "${company}"}) {
        items {
          contactName
          contactPhone
          contactEmail
          contactName2
          contactPhone2
          contactEmail2
          mailingList
        }
      }
     }`;
  let response;
  try {
    response = await API.graphql(graphqlOperation(contactDataQuery));
  } catch (e) {
    parseAndThrowErrors(e, `contact data of ${company}`);
  }
  if (
    response.data.listCompanyConfigParams.items == null ||
    response.data.listCompanyConfigParams.items.length === 0
  ) {
    throw new UnexpectedError("No contact information received from server.");
  }
  return response.data.listCompanyConfigParams.items[0];
}
