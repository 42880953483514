import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../utils/userContext";
import { getPieChartAlertLevel } from "./constants/guardDuty";
import NotificationAlert from "react-notification-alert";
import { CardBody, CardFooter } from "reactstrap";
import { Pie } from "react-chartjs-2";
import BtcCard from "../../components/BtcCard";
import { shouldItemBeVisible } from "../../utils/accountUtil";
import BtcCardHeader from "../../components/BtcCardHeader";
import { fetchAlerts } from "./alertResource";
import { useAppContext } from "../../utils/contextLib";

SecurityOverviewCard.propTypes = {};

function SecurityOverviewCard() {
  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [fetchedAlerts, setFetchedAlerts] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [alertsError, setAlertsError] = useState(null);
  const notifyRef = useRef(null);
  const [pieChartData, setPieChartData] = useState(getPieChartAlertLevel());

  useEffect(() => {
    let company = activePermission["company"];
    fetchData(
      () => fetchAlerts(company),
      setFetchedAlerts,
      setAlertsError,
      setLoading,
      notifyRef
    );
  }, [activePermission, fetchData]);

  useEffect(() => {
    if (!fetchedAlerts) {
      return;
    }
    const alerts = fetchedAlerts.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );

    setAlerts(alerts);

    let pieChartAlertLevel = getPieChartAlertLevel();
    pieChartAlertLevel.data.datasets[0].data = [
      getCountAlertLevel(alerts, "Low"),
      getCountAlertLevel(alerts, "Medium"),
      getCountAlertLevel(alerts, "High"),
    ];
    setPieChartData(pieChartAlertLevel);
  }, [fetchedAlerts, activeAccountIds]);

  function getCountAlertLevel(items, alertLevel) {
    return items.filter((item) => item.severity === alertLevel && !item.solved)
      .length;
    //return alerts.filter(item => item.severity === alertLevel).length;
  }

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcCard label="Security Events">
        <BtcCardHeader
          title="Security"
          icon="fas fa-shield-alt"
          error={alertsError}
          url="/security"
          description="Events by Alert-Level"
          loading={loading}
        />
        <CardBody>
          <Pie
            data={pieChartData.data}
            options={pieChartData.options}
            type="pie"
          />
        </CardBody>
        <CardFooter>
          <div className="legend">
            <i className="fa fa-circle text-primary" />
            Total number of Events: {alerts.length}
          </div>
        </CardFooter>
      </BtcCard>
    </>
  );
}

export default SecurityOverviewCard;
