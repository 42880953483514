/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Footer from "page/Footer.js";
import AdminNavbar from "page/AdminNavbar.js";
import Sidebar from "page/Sidebar.js";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { UserContext } from "../utils/userContext";
import { Auth } from "aws-amplify";
import ErrorPage from "../modules/errorpage/ErrorPage";
import { CustomError } from "../errors/CustomError";
import { NoAuthenticatedUserError } from "../errors/NoAuthenticatedUserError";
import { getAccessPermissions } from "./accessPermissionResource";
import BtcSpinner from "../components/BtcSpinner";
import { moduleRoutes } from "../modules/moduleRoutes";
import NotificationAlert from "react-notification-alert";

const UserView = (props) => {
  const [errorPageError, setErrorPageError] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const {
    user,
    setUser,
    activePermission,
    activeAccountIds,
    setActivePermission,
    setActiveAccountIds,
  } = useContext(UserContext);
  const notifyRef = useRef(null);
  const location = useLocation();
  const [userModuleRoutes, setUserModuleRoutes] = useState();

  useEffect(onLoad, [setUser]);
  useEffect(removeRetainedTooltips, [location]);

  function removeRetainedTooltips() {
    const tooltipsElements = document.querySelectorAll(".jvectormap-tip");
    tooltipsElements.forEach((tooltip) => {
      tooltip.remove();
    });
  }

  function onLoad() {
    async function initializeUser() {
      const authUser = await Auth.currentUserInfo();
      if (authUser == null) {
        throw new NoAuthenticatedUserError();
      }

      const accessPermissions = await getAccessPermissions(notifyRef);
      const company = authUser.attributes["custom:company"];
      return {
        ...authUser,
        company_list: company.split(","),
        accessPermissions: accessPermissions,
      };
    }

    initializeUser()
      .then((user) => {
        setUser(user);
      })
      .catch((e) => {
        console.error(e);
        if (e instanceof CustomError) {
          setErrorPageError(e);
        } else if (e instanceof Error) {
          setErrorPageError({ header: "Unexpected Error", message: e.message });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // Update activeAccounts if they don't are part of active company
  useEffect(() => {
    if (!activePermission) {
      return;
    }

    const accounts = filterAccounts(activePermission, activeAccountIds);
    if (JSON.stringify(activeAccountIds) !== JSON.stringify(accounts)) {
      setActiveAccountIds(accounts);
    }
    // This hook intentionally omits dependency to activeAccountIds
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePermission]);

  function filterAccounts(activePermission, accounts) {
    let accountIdsInCompany = activePermission.companyAccListObj.map(
      (c) => c.accountId
    );
    let result = accounts.filter(
      (account) => accountIdsInCompany.indexOf(account) !== -1
    );
    return result.length !== 0 ? result : ["all"];
  }

  // If user changes URL or user changes, change active permission and active accountIds
  useEffect(() => {
    if (!user) {
      return;
    }
    const query = new URLSearchParams(location.search);
    const selectedPermission = getPermissionFromUrl(
      location.search,
      activePermission,
      user
    );
    if (activePermission !== selectedPermission) {
      setActivePermission(selectedPermission);
    }

    let newAccountIds = query.get("accountid")?.split(",") || ["all"];
    newAccountIds = filterAccounts(selectedPermission, newAccountIds);

    if (JSON.stringify(activeAccountIds) !== JSON.stringify(newAccountIds)) {
      setActiveAccountIds(newAccountIds);
    }
    // This hook intentionally omits dependency to activeAccountIds and activePermission
    // There should not be any reload when they change, they are only here for equality check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, setActiveAccountIds, setActivePermission, user]);

  useEffect(() => {
    let userModuleRoutes = moduleRoutes(notifyRef).filter((route) =>
      filterRouteWithPermission(route, activePermission)
    );
    setUserModuleRoutes(userModuleRoutes);
  }, [activePermission]);

  function getPermissionFromUrl(search, activePermission, user) {
    const query = new URLSearchParams(location.search);
    let company = query.get("company");
    if (!company && activePermission) {
      company = activePermission.company;
    }
    let index = user.accessPermissions.map((p) => p.company).indexOf(company);
    if (index === -1) {
      index = 0;
    }
    return user.accessPermissions[index];
  }

  function filterRouteWithPermission(route, activePermission) {
    return (
      route.name === "Dashboard" ||
      route.name === "userProfile" ||
      (activePermission &&
        activePermission.companyModules.includes(route.name.toLowerCase()) &&
        (!route.extended || activePermission.extended))
    );
  }

  function getChildRouteObjs(route) {
    return route.childPaths
      ? route.childPaths.map((childPath, childKey) => ({
          name: route.name,
          extended: route.extended,
          route: (
            <Route
              path={route.path + childPath.path}
              render={() => childPath.component}
              key={route.name + "_" + childKey}
            />
          ),
        }))
      : [];
  }

  function getRouteObj(route) {
    return {
      name: route.name,
      extended: route.extended,
      route: (
        <Route
          path={route.path}
          render={() => route.component}
          key={route.name}
        />
      ),
    };
  }

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      {errorPageError ? (
        <ErrorPage error={errorPageError} />
      ) : isLoading ? (
        <BtcSpinner />
      ) : (
        <>
          <Sidebar
            {...props}
            routes={userModuleRoutes}
            bgColor="btc"
            activeColor="info"
          />
          <main className="main-panel" aria-label="module-content">
            <AdminNavbar />
            <Switch>
              {moduleRoutes(notifyRef)
                .flatMap((route) => {
                  let childRouteObjs = getChildRouteObjs(route);
                  let routeObj = getRouteObj(route);
                  return [...childRouteObjs, routeObj];
                })
                .filter((route) =>
                  filterRouteWithPermission(route, activePermission)
                )
                .map((route) => route.route)}
              <Redirect to="/dashboard" />
            </Switch>
            <Footer fluid />
          </main>
        </>
      )}
    </>
  );
};

export default UserView;
