import { API, graphqlOperation } from "aws-amplify";
import { parseAndThrowErrors } from "../../errors/GraphQlError";
import {
  capitalizeFirstLetter,
  constant_notation_to_pretty_string,
} from "../../utils/stringUtil";

export async function fetchActiveFindings(company) {
  const getActiveFindings = `query {
        listActiveInspectorFindings(input: {
              tenant: "${company}"
            }) {
              items{
                accountId
                accountName
                hyperscaler
                date
                updatedAt
                severity
                title
                impactedResource
                impactedResourceType
                findingType
                status
                description
              }
            }
          }
          `;

  try {
    const response = await API.graphql(graphqlOperation(getActiveFindings));
    return mapFindings(response.data.listActiveInspectorFindings.items || []);
  } catch (e) {
    parseAndThrowErrors(e, `inspector findings of ${company}`);
  }
}

function mapFindings(findings) {
  return findings.map((finding) => ({
    ...finding,
    impactedResourceTypePretty: prettify(finding["impactedResourceType"]),
    findingTypePretty: constant_notation_to_pretty_string(
      finding["findingType"]
    ),
    severityIndex: calcSeverityIndex(finding["severity"]),
  }));
}

function prettify(string) {
  return capitalizeFirstLetter(
    string
      .replaceAll("_", " ")
      .toLowerCase()
      .replace("aws", "AWS")
      .replace("ec2", "EC2")
      .replace("ecr", "ECR")
  );
}

function calcSeverityIndex(severity) {
  switch (severity) {
    case "INFORMATIONAL":
      return "01_INFORMATIONAL";
    case "LOW":
      return "02_LOW";
    case "MEDIUM":
      return "03_MEDIUM";
    case "HIGH":
      return "04_HIGH";
    case "CRITICAL":
      return "05_CRITICAL";
    default:
      return "00";
  }
}
