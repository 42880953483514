import { CustomError } from "./CustomError";
import { convertCurrencySymbol } from "../modules/costs/CostsUtil";

export class CurrencyNotEuroException extends CustomError {
  constructor(subject, wrongCurrency) {
    super(
      `Expected currency of ${subject} to be € but got ${convertCurrencySymbol(
        wrongCurrency
      )}`
    );
  }
}
