import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../utils/userContext";
import NotificationAlert from "react-notification-alert";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import { CardBody } from "reactstrap";
import BtcListRow from "../../components/BtcListRow";
import { shouldItemBeVisible } from "../../utils/accountUtil";
import { getMaxProperty } from "../../utils/arrayUtil";
import { fetchHostedZones } from "./networkResource";
import { useAppContext } from "../../utils/contextLib";

export const NetworkOverviewCard = () => {
  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const notifyRef = useRef(null);
  const [busy, setBusy] = useState(true);

  const [fetchedHostedZones, setFetchedHostedZones] = useState(null);
  const [hostedZonesCount, setHostedZonesCount] = useState(null);
  const [activeVpnsCount] = useState(null); // TODO backend information missing
  const [vpnsInTroubleCount] = useState(null); // TODO backend information missing

  const [error, setError] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);

  useEffect(() => {
    const company = activePermission["company"];
    fetchData(
      () => fetchHostedZones(company),
      setFetchedHostedZones,
      setError,
      setBusy,
      notifyRef
    );
  }, [activePermission, fetchData]);

  useEffect(() => {
    if (!fetchedHostedZones) {
      return;
    }

    let hostedZones = fetchedHostedZones.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );

    setHostedZonesCount(hostedZones.length);

    // TODO updatedAt missing in backend
    setUpdatedAt(getMaxProperty(hostedZones, "updatedAt"));
  }, [fetchedHostedZones, activeAccountIds]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcNoAspectRatioCard label="Network" className="card-stats">
        <BtcCardHeader
          title="Network"
          icon="fas fa-network-wired"
          error={error}
          updatedAt={updatedAt}
          loading={busy}
          url="/network"
        />
        <CardBody>
          <BtcListRow
            icon="fas fa-at fa-fix"
            text="Hosted Zones"
            loading={busy}
          >
            {hostedZonesCount != null ? hostedZonesCount : "?"}
          </BtcListRow>
          <BtcListRow
            icon="fas fa-project-diagram fa-fix"
            text="Active VPNs"
            loading={busy}
          >
            {activeVpnsCount != null ? activeVpnsCount : "soon"}
          </BtcListRow>
          <BtcListRow
            icon="fas fa-cloud-rain fa-fix"
            text="VPNs in trouble"
            loading={busy}
          >
            {vpnsInTroubleCount != null ? vpnsInTroubleCount : "soon"}
          </BtcListRow>
        </CardBody>
      </BtcNoAspectRatioCard>
    </>
  );
};
