export function shouldItemBeVisible(item, activeAccountIds) {
  return activeAccountIds.some((activeAccountId) =>
    shouldBeVisible(item.hyperscaler, item.accountId, activeAccountId)
  );
}

export function filterByActiveAccount(items, activeAccountIds) {
  return items.filter((item) => shouldItemBeVisible(item, activeAccountIds));
}

export function shouldBeVisible(hyperscaler, accountId, activeAccountId) {
  if (activeAccountId === "all") {
    return true;
  }
  if (activeAccountId === "aws") {
    // items without hyperscaler will be seen as aws
    return !hyperscaler || hyperscaler === activeAccountId;
  }
  if (activeAccountId === "azure") {
    return hyperscaler === activeAccountId;
  }

  return accountId === activeAccountId;
}
