// Just for Testing..

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CardFooter } from "reactstrap";
import { Line } from "react-chartjs-2";
import {
  generateCostDataset,
  generateCostGraphOptions,
} from "./constants/costsData";
import { UserContext } from "../../utils/userContext";
import * as DateUtil from "../../utils/dateUtil";
import NotificationAlert from "react-notification-alert";
import CustomErrorIcon from "../../components/CustomErrorIcon";
import BtcCard from "../../components/BtcCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import { getListOfCumulatedCostsOrderedByDate } from "./CostsUtil";
import { fetchActualCosts, fetchForecastCosts } from "./costsResource";
import { shouldItemBeVisible } from "../../utils/accountUtil";
import { getMaxProperty } from "../../utils/arrayUtil";
import { useAppContext } from "../../utils/contextLib";
import BtcInfoBadge from "../../components/BtcInfoBadge";
import BtcStretchedContentCardBody from "../../components/BtcStretchedContentCardBody";

export const CostsForecastPlotCard = () => {
  const { fetchMultipleData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const notifyRef = useRef(null);

  // Sets the Card to busy, which means it is just doing something (calculation or async requests)
  const [busy, setBusy] = useState(true);
  const numberOfMonth = 3;
  const [actualCosts, setActualCosts] = useState();
  const [actualCost, setActualCost] = useState();
  const [costsError, setCostsError] = useState(null);
  const [forecastCosts, setForecastCosts] = useState();
  const [updatedAt, setUpdatedAt] = useState(null);
  const [datasets, setDatasets] = useState([generateCostDataset()]);
  const [labels, setLabels] = useState(generateMonthNames(numberOfMonth));
  const [options] = useState(generateCostGraphOptions());

  const getForecastCosts = useCallback(async (company, numberOfMonth) => {
    // Get Date as e.g. 2020-01
    const monthBeginAsString = DateUtil.getISOMonthRelativeToToday(0);
    const monthEndAsString = DateUtil.getISOMonthRelativeToToday(numberOfMonth);
    return await fetchForecastCosts(
      monthEndAsString,
      monthBeginAsString,
      company
    );
  }, []);

  const getActualCost = useCallback(async (company) => {
    const monthBeginAsString = DateUtil.getISOMonthRelativeToToday(0);
    const actualMonthEndAsString = DateUtil.getISOMonthRelativeToToday(1);
    return await fetchActualCosts(
      actualMonthEndAsString,
      monthBeginAsString,
      company
    );
  }, []);

  useEffect(() => {
    const company = activePermission["company"];

    const forecastPromise = getForecastCosts(company, numberOfMonth).then(
      (forecastCosts) => {
        setForecastCosts(forecastCosts);
        setUpdatedAt(getMaxProperty(forecastCosts, "updatedAt"));
      }
    );

    const actualCostPromise = getActualCost(company).then((actualCosts) => {
      setActualCosts(actualCosts);
    });

    fetchMultipleData(
      [forecastPromise, actualCostPromise],
      setCostsError,
      setBusy,
      notifyRef
    );
  }, [activePermission, getForecastCosts, getActualCost, fetchMultipleData]);

  useEffect(() => {
    let monthNames = generateMonthNames(numberOfMonth);
    setLabels(monthNames);
  }, [numberOfMonth]);

  useEffect(() => {
    if (!forecastCosts) {
      return;
    }
    let filteredCosts = forecastCosts.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );

    let cumulatedCostsOrderedByDate =
      getListOfCumulatedCostsOrderedByDate(filteredCosts);
    setDatasets([generateCostDataset(cumulatedCostsOrderedByDate)]);
  }, [forecastCosts, activeAccountIds]);

  useEffect(() => {
    if (!actualCosts) {
      return;
    }
    const filteredActualCosts = actualCosts.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );

    if (filteredActualCosts.length > 0) {
      setActualCost(
        getListOfCumulatedCostsOrderedByDate(filteredActualCosts)[0]
      );
    } else {
      setActualCost(0);
    }
  }, [actualCosts, activeAccountIds]);

  function generateMonthNames(numberOfMonth) {
    return DateUtil.getMonthNames(0, numberOfMonth - 1);
  }

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcCard label="Forecast Costs">
        <BtcCardHeader
          title="Forecast Costs"
          updatedAt={updatedAt}
          icon="fas fa-euro-sign"
          error={costsError}
          url="/costs"
          loading={busy}
        />
        <BtcStretchedContentCardBody>
          <Line
            data={{
              labels: labels,
              datasets: datasets,
            }}
            options={options}
          />
        </BtcStretchedContentCardBody>
        <CardFooter>
          <hr />
          {costsError ? (
            <CustomErrorIcon
              className="btc-small-card-error"
              error={costsError}
            />
          ) : (
            <BtcInfoBadge label="Actual">
              {busy ? "?" : actualCost + " €"}
            </BtcInfoBadge>
          )}
        </CardFooter>
      </BtcCard>
    </>
  );
};
