import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../utils/userContext";
import NotificationAlert from "react-notification-alert";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import { CardBody } from "reactstrap";
import BtcListRow from "../../components/BtcListRow";
import { fetchUserRoles } from "./iamResource";
import { shouldItemBeVisible } from "../../utils/accountUtil";
import { getMaxProperty } from "../../utils/arrayUtil";
import { useAppContext } from "../../utils/contextLib";

export const IamOverviewCard = () => {
  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const notifyRef = useRef(null);
  const [busy, setBusy] = useState(true);

  const [fetchedUserRoles, setFetchedUserRoles] = useState(null);
  const [usersCount, setUsersCount] = useState(null);
  const [activeUsersCount] = useState(null); // TODO backend information missing
  const [adminUsersCount, setAdminUsersCount] = useState(null);

  const [error, setError] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);

  useEffect(() => {
    const company = activePermission["company"];
    fetchData(
      () => fetchUserRoles(company, new Date()),
      setFetchedUserRoles,
      setError,
      setBusy,
      notifyRef
    );
  }, [activePermission, fetchData]);

  useEffect(() => {
    if (!fetchedUserRoles) {
      return;
    }

    let userRoleItems = fetchedUserRoles.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );

    setUsersCount(userRoleItems.length);
    setAdminUsersCount(
      userRoleItems.filter((user) => user.role.includes("admin")).length
    );

    setUpdatedAt(getMaxProperty(userRoleItems, "updatedAt"));
  }, [fetchedUserRoles, activeAccountIds]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcNoAspectRatioCard label="Iam" className="card-stats">
        <BtcCardHeader
          title="Iam"
          icon="far fa-id-badge fa-fix"
          error={error}
          updatedAt={updatedAt}
          loading={busy}
          url="/iam"
        />
        <CardBody>
          <BtcListRow icon="fas fa-user fa-fix" text="Users" loading={busy}>
            {usersCount != null ? usersCount : "?"}
          </BtcListRow>
          <BtcListRow
            icon="fas fa-running fa-fix"
            text="Active Users (90d)"
            loading={busy}
          >
            {activeUsersCount != null ? activeUsersCount : "soon"}
          </BtcListRow>
          <BtcListRow
            icon="fas fa-user-cog fa-fix"
            text="Admin Users"
            loading={busy}
          >
            {adminUsersCount != null ? adminUsersCount : "?"}
          </BtcListRow>
        </CardBody>
      </BtcNoAspectRatioCard>
    </>
  );
};
