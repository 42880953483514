import React from "react";
import PropTypes from "prop-types";
import { CardBody } from "reactstrap";
import { VectorMap } from "react-jvectormap";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import BtcCardHeader from "../../components/BtcCardHeader";

EventsMapCard.propTypes = {
  alerts: PropTypes.array,
  alertsError: PropTypes.object,
};

function EventsMapCard({ alerts, alertsError, loading }) {
  const markerData = alerts.map((item) => ({
    latLng: [item.geoLat, item.geoLon],
    name: item.actor,
  }));

  return (
    <>
      <BtcNoAspectRatioCard label="Events Map">
        <BtcCardHeader title="Events" error={alertsError} loading={loading} />
        <CardBody>
          <VectorMap
            map={"world_mill"}
            markers={markerData}
            backgroundColor="transparent"
            zoomOnScroll={true}
            containerStyle={{
              height: "18.75rem",
            }}
            containerClassName="map"
            regionStyle={{
              initial: {
                fill: "#e4e4e4",
                "fill-opacity": 0.9,
                stroke: "none",
                "stroke-width": 0,
                "stroke-opacity": 0,
              },
            }}
            markerStyle={{
              initial: {
                fill: "#de1f16",
              },
            }}
            series={{
              regions: [
                {
                  scale: ["#ffd699", "#FF9900"],
                  normalizeFunction: "polynomial",
                },
              ],
            }}
          />
        </CardBody>
      </BtcNoAspectRatioCard>
    </>
  );
}

export default EventsMapCard;
