import * as DateUtil from "../../utils/dateUtil";
import { getISODayRelativeToToday } from "../../utils/dateUtil";
import { API, graphqlOperation } from "aws-amplify";
import { parseAndThrowErrors } from "../../errors/GraphQlError";
import { buildMapGroupedByKey } from "../../utils/groupByUtil";
import { uniqueMerge } from "../../utils/arrayUtil";
import { roundTo2Decimals } from "../../utils/mathUtil";
import { CurrencyNotEuroException } from "../../errors/CurrencyNotEuroException";

export async function fetchCosts(company, startMonthOffset) {
  const monthBeginAsString =
    DateUtil.getISOMonthRelativeToToday(startMonthOffset);
  const monthEndAsString = DateUtil.getISOMonthRelativeToToday(1);
  const ygetCosts = `query {
      listCosts(input: {
        month_end: "${monthEndAsString}",
        month_begin: "${monthBeginAsString}",
        service: "SUM",
        tenant: "${company}",
      }) {
        items{
          costs
          tags
          accountId
          date
          hyperscaler
          updatedAt
          currency
          costsV2 {
            costs
            currency
          }
        }
      }
    }
    `;
  try {
    const response = await API.graphql(graphqlOperation(ygetCosts));
    let items = response.data.listCosts.items
      .map((item) => makeCostV2BackwardCompatible(item))
      .filter((item) => item.tags !== "saasFlatrate");
    checkIfCurrencyEuro(items, "currency", "costV2", "sum-costs");
    return items;
  } catch (e) {
    parseAndThrowErrors(e, `costs of ${company}`);
  }
}

export async function fetchForecastCosts(
  monthEndAsString,
  monthBeginAsString,
  company
) {
  const ygetForecastCosts = `query {
      listCosts(input: {
        month_end: "${monthEndAsString}",
        month_begin: "${monthBeginAsString}",
        service: "FORECAST",
        tenant: "${company}",
      }) {
        items{
          costs
          accountId
          date
          hyperscaler
          updatedAt
          currency
          costsV2 {
            costs
            currency
          }
        }
      }
    }
    `;

  try {
    const responseForecast = await API.graphql(
      graphqlOperation(ygetForecastCosts)
    );
    let items = responseForecast.data.listCosts.items.map((item) =>
      makeCostV2BackwardCompatible(item)
    );
    checkIfCurrencyEuro(items, "currency", "costV2", "forecast-costs");
    return items;
  } catch (e) {
    parseAndThrowErrors(e, `forecast-costs of ${company}`);
  }
}

export async function fetchActualCosts(
  actualMonthEndAsString,
  monthBeginAsString,
  company
) {
  const ygetActualCosts = `query {
      listCosts(input: {
        month_end: "${actualMonthEndAsString}",
        month_begin: "${monthBeginAsString}",
        service: "SUM",
        tenant: "${company}",
      }) {
        items{
          costs
          accountId
          date
          hyperscaler
          currency
          costsV2 {
            costs
            currency
          }
        }
      }
    }
    `;

  try {
    const responseActual = await API.graphql(graphqlOperation(ygetActualCosts));
    let items = responseActual.data.listCosts.items.map((item) =>
      makeCostV2BackwardCompatible(item)
    );
    checkIfCurrencyEuro(items, "currency", "costV2", "actual-costs");
    return items;
  } catch (e) {
    parseAndThrowErrors(e, `actual costs of ${company}`);
  }
}

function makeBudgetCostsBackwardCompatible(items) {
  for (const item of items) {
    if (item.actualCostsV2) {
      item.actualCosts = Number(item.actualCostsV2.costs);
      item.currency = item.actualCostsV2.currency;
    } else {
      item.actualCostsV2 = {
        costs: item.actualCosts,
        currency: item.currency,
      };
    }
  }
  for (const item of items) {
    if (item.limitV2) {
      item.limit = Number(item.limitV2.costs);
    } else {
      item.limitV2 = {
        costs: item.limit,
        currency: item.currency,
      };
    }
  }
}

export async function fetchBudgetOfLastDays(company, numberOfDaysFetched) {
  const dayBegin = getISODayRelativeToToday(-numberOfDaysFetched + 1);
  const dayEnd = getISODayRelativeToToday(1);

  const listBudgetCosts = `query {
      listBudgetCosts(input: {
              day_begin: "${dayBegin}",
              day_end: "${dayEnd}",
              tenant: "${company}"
            }) {
              items{
                budgetName
                accountName
                accountId
                actualCosts
                date
                limit
                hyperscaler
                updatedAt
                currency
                actualCostsV2 {
                  costs
                  currency
                  dateOfExchangeRate
                  exchangeRate
                  originalCosts
                  originalCurrency
                }
                limitV2 {
                  costs
                  currency
                  dateOfExchangeRate
                  exchangeRate
                  originalCosts
                  originalCurrency
                }
              }
            }
          }
          `;

  let response;
  try {
    response = await API.graphql(graphqlOperation(listBudgetCosts));
  } catch (e) {
    parseAndThrowErrors(e, `budget costs of ${company}`);
  }

  let items = response.data.listBudgetCosts.items || [];
  makeBudgetCostsBackwardCompatible(items);
  checkIfCurrencyEuro(items, "currency", "actualCostsV2", "budget costs");
  checkIfCurrencyEuro(items, "currency", "limitV2", "budget limit");
  return mergeWithSameBudgetNameAndDate(items);
}

function mergeWithSameBudgetNameAndDate(items) {
  const budgets = items.map((b) => {
    let budgetPercentage;
    budgetPercentage = b.actualCosts / b.limit;
    return {
      date: b.date,
      accountNames: [b.accountName],
      accountIds: [b.accountId],
      accountNamesAsString: b.accountName,
      used: roundTo2Decimals(b.actualCosts),
      limit: roundTo2Decimals(b.limit),
      currency: b.currency,
      actualCostsV2: b.actualCostsV2,
      limitV2: b.limitV2,
      budgetName: b.budgetName,
      budgetPercentage: budgetPercentage,
      budgetPercentageAsString: budgetPercentage.toFixed(4),
      hyperscaler: b.hyperscaler,
      updatedAt: b.updatedAt,
    };
  });

  const budgetsGroupedByNameAndDate = buildMapGroupedByKey(
    budgets,
    (k) => k.budgetName + k.date
  );

  return Array.from(budgetsGroupedByNameAndDate.values()).map(
    (budgetsWithSameName) => mergeBudgetsToOneBudget(budgetsWithSameName)
  );
}

function mergeBudgetsToOneBudget(array) {
  return array.reduce((value, newValue) => {
    const oldLength = value.accountNames.length;
    value.accountNames = uniqueMerge(
      value.accountNames,
      newValue.accountNames
    ).sort();
    if (value.accountNames.length !== oldLength) {
      value.accountNamesAsString = value.accountNames.reduce(
        (a, b) => a + ", " + b
      );
    }
    value.accountIds = uniqueMerge(value.accountIds, newValue.accountIds);
    value.hyperscaler = value.hyperscaler || newValue.hyperscaler;
    if (newValue.updatedAt > value.updatedAt) {
      value.updatedAt = newValue.updatedAt;
    }
    return value;
  });
}

//month_begin: This has to be fixed: either the appsync query has to be changed or
// if all data is allways displayed the month_begin can be removed
export async function fetchServiceCosts(company) {
  const getServiceCosts = `query {
      listServiceCosts(input: {
              month_begin: "20",
              tenant: "${company}"
            }) {
              items{
                costs
                tags
                accountId
                accountName
                resource
                date
                hyperscaler
                updatedAt
                currency
                costsV2 {
                  costs
                  currency
                  dateOfExchangeRate
                  exchangeRate
                  originalCosts
                  originalCurrency
                }
              }
            }
          }
          `;

  let response;
  try {
    response = await API.graphql(graphqlOperation(getServiceCosts));
  } catch (e) {
    parseAndThrowErrors(e, `service costs of ${company}`);
  }

  let items = (
    response.data.listServiceCosts.items.map((item) =>
      mapServiceCostItem(item)
    ) || []
  ).reverse();
  checkIfCurrencyEuro(items, "currency", "costsV2", "service costs");
  return items;
}

function mapServiceCostItem(item) {
  if (item.tags.length === 0) {
    item.tags = "no tag-key";
  } else {
    item.tags = item.tags.join(", ");
  }
  return makeCostV2BackwardCompatible(item);
}

function makeCostV2BackwardCompatible(item) {
  if (item.costsV2) {
    item.costs = Number(item.costsV2.costs);
    item.currency = item.costsV2.currency;
  } else {
    item.costsV2 = {
      costs: item.costs,
      currency: item.currency,
    };
  }
  return item;
}

export async function fetchCostAnomalies(startMonthOffset, company) {
  const getCostAnomalies = `query {
      listCostAnomalies(input: {
        date_begin: "${startMonthOffset}",
        tenant: "${company}",
      }) {
        items{
          accountId
          accountName
          anomalyId
          endDate
          hyperscaler
          maxScore
          service
          startDate
          totalCostImpact
          totalCostImpactCurrency
          updatedAt
          totalCostImpactV2 {
            costs
            currency
            dateOfExchangeRate
            exchangeRate
            originalCosts
            originalCurrency
          }
        }
      }
    }
    `;

  try {
    const responseCostAnomalies = await API.graphql(
      graphqlOperation(getCostAnomalies)
    );
    let items = makeCostAnomaliesBackwardCompatible(
      responseCostAnomalies.data.listCostAnomalies.items
    );
    checkIfCurrencyEuro(
      items,
      "totalCostImpactCurrency",
      "totalCostImpactV2",
      "cost anomaly"
    );
    return items;
  } catch (e) {
    parseAndThrowErrors(e, `cost-anomalies of ${company}`);
  }

  function makeCostAnomaliesBackwardCompatible(items) {
    for (const item of items) {
      if (item.totalCostImpactV2) {
        item.totalCostImpact = Number(item.totalCostImpactV2.costs);
        item.totalCostImpactCurrency = item.totalCostImpactV2.currency;
      } else {
        item.totalCostImpactV2 = {
          costs: item.totalCostImpact,
          currency: item.totalCostImpactCurrency,
        };
      }
    }
    return items;
  }
}

function checkIfCurrencyEuro(
  items,
  currencyAttribute,
  costV2Attribute,
  subject
) {
  for (const item of items) {
    if (
      item[currencyAttribute] !== "EUR" ||
      (item[costV2Attribute] && item[costV2Attribute].currency !== "EUR")
    ) {
      throw new CurrencyNotEuroException(subject, item[currencyAttribute]);
    }
  }
}
