import React, { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import _uniqueId from "lodash/uniqueId";

function CustomErrorIcon({ error, className }) {
  const [id] = useState(_uniqueId("custom-error-"));

  return error ? (
    <>
      <i
        id={id}
        className={`fas fa-exclamation-triangle text-danger ${
          className ? className : ""
        }`}
      />
      <UncontrolledTooltip delay={0} target={id}>
        <b>{error.header ? error.header : "Unexpected Error"}</b> -{" "}
        {error.message}
      </UncontrolledTooltip>
    </>
  ) : null;
}

export default CustomErrorIcon;
