import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../utils/userContext";
import NotificationAlert from "react-notification-alert";
import { CardBody } from "reactstrap";
import { VectorMap } from "react-jvectormap";
import BtcCard from "../../components/BtcCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import { fetchAccountsPerRegion } from "./accountsResource";
import { useAppContext } from "../../utils/contextLib";

AccountsByRegionCard.propTypes = {};

function AccountsByRegionCard() {
  const { fetchData } = useAppContext();

  const { activePermission } = useContext(UserContext);
  const [busy, setBusy] = useState(true);
  const [mapData, setMapData] = useState({});
  const [accountsData, setAccountsData] = useState([]);
  const [accountsError, setAccountsError] = useState(null);
  const notifyRef = useRef(null);

  useEffect(() => {
    let company = activePermission["company"];
    fetchData(
      () => fetchAccountsPerRegion(company),
      onSuccess,
      setAccountsError,
      setBusy,
      notifyRef
    );
  }, [activePermission, fetchData]);

  function onSuccess(accountItems) {
    if (accountItems != null) {
      let mapData = {};
      let newAccountsData = [];
      for (let i in accountItems) {
        mapData[accountItems[i].SK] = accountItems[i].count;

        let accounts = {};
        accounts.country = accountItems[i].SK;
        accounts.count = accountItems[i].count;
        if (accounts.country === "DE") {
          accounts.flag = require("assets/img/flags/DE.png");
        } else if (accounts.country === "FR") {
          accounts.flag = require("assets/img/flags/FR.png");
        } else if (accounts.country === "SE") {
          accounts.flag = require("assets/img/flags/SE.png");
        }
        newAccountsData.push(accounts);
      }

      setAccountsData(newAccountsData);
      setMapData(mapData);
    }
  }

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcCard label="Services by Region">
        <BtcCardHeader
          title="Services by Region"
          error={accountsError}
          loading={busy}
        />
        <CardBody>
          <div className="btc-region-map__container">
            <VectorMap
              map={"europe_mill"}
              backgroundColor="transparent"
              zoomOnScroll={false}
              zoomButtons={false}
              containerClassName="map btc-region-map__map"
              regionStyle={{
                initial: {
                  fill: "#e4e4e4",
                  "fill-opacity": 0.9,
                  stroke: "none",
                  "stroke-width": 0,
                  "stroke-opacity": 0,
                },
              }}
              onRegionTipShow={(e, el, code) => {
                let accountInfo = "";
                let flag = "";
                const accounts = accountsData.filter((i) => i.country === code);
                if (accounts.length > 0) {
                  if (accounts[0].count === "1") {
                    accountInfo = ` (${accounts[0].count} service)`;
                  } else {
                    accountInfo = ` (${accounts[0].count} services)`;
                  }
                  flag = `<img alt='' src="${accounts[0].flag}" /> `;
                }
                el.html(flag + el.html() + accountInfo);
              }}
              series={{
                regions: [
                  {
                    values: mapData,
                    scale: ["#ffd699", "#FF9900"],
                    normalizeFunction: "polynomial",
                  },
                ],
              }}
            />
          </div>
        </CardBody>
      </BtcCard>
    </>
  );
}

export default AccountsByRegionCard;
