import { Button, Form, FormGroup, UncontrolledCollapse } from "reactstrap";
import BtcSpinner from "../components/BtcSpinner";
import { QRCodeSVG } from "qrcode.react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { mfaCodeScheme } from "./authenticationCodeValidation";
import { Auth } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppContext } from "../utils/contextLib";
import { notify } from "../utils/notification";

export const MfaSetupForm = (props) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(mfaCodeScheme),
  });

  const { cognitoUser, setCognitoUser } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [authQrCode, setAuthQrCode] = useState(null);
  const [secretForManualSetup, setSecretForManualSetup] = useState("");
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const location = useLocation();

  const mfaConfiguration = useCallback(async () => {
    try {
      let currentUser;

      if (isAuthenticated) {
        currentUser = await Auth.currentAuthenticatedUser();
      } else {
        currentUser = cognitoUser;
      }

      const res = await Auth.setupTOTP(currentUser);
      const userEmail = `${
        cognitoUser?.attributes?.email ||
        currentUser?.attributes?.email ||
        location.state.email
      }`;
      const authCode =
        "otpauth://totp/" +
        (userEmail ? userEmail : "") +
        "?secret=" +
        res +
        "&issuer=BTC Utility Cloud";
      setSecretForManualSetup(res);
      setAuthQrCode(authCode);
      setCognitoUser(currentUser);
    } catch (e) {
      console.log(e);
    }
    // cognitoUser and setCognitoUser are not included in dependencies because mfaConfiguration function
    // sets the value of Cognito user, adding these dependencies can cause an infinity loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, location]);

  useEffect(() => {
    mfaConfiguration().then();
  }, [mfaConfiguration]);

  const submitMFACode = async (authenticationCode, event) => {
    event.preventDefault();
    try {
      const currentUser = await Auth.verifyTotpToken(
        cognitoUser,
        authenticationCode.mfaCode
      );
      if (currentUser.Status === "SUCCESS") {
        // noinspection JSUnusedLocalSymbols
        await Auth.setPreferredMFA(cognitoUser, "SOFTWARE_TOKEN_MFA");

        notify({
          ref: props.notifyRef,
          type: 2,
          place: "tr",
          message: "Multi-factor authentication successfully activated",
          autoDismiss: 7,
        });

        props.updateMfaState();
      } else if (
        currentUser.getAccessToken() &&
        currentUser.getRefreshToken()
      ) {
        userHasAuthenticated(true);
        history.push({ pathname: "/dashboard" });
      }
    } catch (e) {
      console.log(e);

      if (props.notifyRef) {
        notify({
          ref: props.notifyRef,
          type: 3,
          place: "tr",
          message: "Provided authentication code is invalid",
        });
      }

      setError("mfaCode", {
        type: "custom",
        message: "Provided authentication code is invalid",
      });
    }
  };

  const revealSecretHandler = () => {
    setIsOpen((prev) => {
      return !prev;
    });
  };

  return (
    <Form onSubmit={handleSubmit(submitMFACode)}>
      <>
        <FormGroup>
          <label>Scan the following code with your smartphone's camera</label>
          <div className="row justify-content-center p-3">
            {!authQrCode ? (
              <BtcSpinner />
            ) : (
              <QRCodeSVG
                value={authQrCode}
                bgColor="white"
                fgColor="black"
                includeMargin={false}
                level="L"
                size={150}
              />
            )}
          </div>
        </FormGroup>
        <FormGroup>
          {/* For the link below href attribute is not necessary, it is used to switch state of collapse container only */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            id="linkToggler"
            onClick={revealSecretHandler}
            className="collapse-link-toggler"
          >
            {`${isOpen ? "Hide" : "Show"} secrete key for manual configuration`}
          </a>
          <UncontrolledCollapse toggler="#linkToggler">
            <input
              className="form-control"
              readOnly
              value={secretForManualSetup}
            />
          </UncontrolledCollapse>
        </FormGroup>
        <FormGroup
          className={`form-group ${errors.mfaCode ? "has-danger" : ""}`}
        >
          <label>Enter authentication code in box bellow</label>
          <input
            className="form-control"
            type="text"
            {...register("mfaCode")}
          />
          {errors.mfaCode ? (
            <label role="alert" className="error">
              {errors.mfaCode?.message}
            </label>
          ) : null}
        </FormGroup>
        <Button block color="success" disabled={!!errors.mfaCode} type="submit">
          Submit code
        </Button>
      </>
    </Form>
  );
};
