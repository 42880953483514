import React from "react";
import BtcSpinner from "./BtcSpinner";
import PropTypes from "prop-types";

BtcListRow.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  loading: PropTypes.bool,
};

function BtcListRow({ icon, text, loading = false, children }) {
  return (
    <div className="d-flex card-text-uncapitalized">
      <div>
        <i className={(icon + " " || "") + "fa-btc-like p-2 m-2"} />
      </div>
      <div className="p-2 m-1 btc-shorten-overflow-text">
        <span>{text}</span>
      </div>
      <div className="ml-auto p-2 m-1">
        {loading ? <BtcSpinner /> : children}
      </div>
    </div>
  );
}

export default BtcListRow;
