import React from "react";
import { Label } from "reactstrap";
import PropTypes from "prop-types";
import BtcFormGroup from "./BtcFormGroup";

BtcFormInput.propTypes = {
  register: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  attributeName: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.oneOf(["on", "off"]),
};

function BtcFormInput({
  register,
  formErrors,
  attributeName,
  labelText,
  disabled = false,
  autoComplete = "on",
  type = "text",
}) {
  return (
    <BtcFormGroup formErrors={formErrors} attributeName={attributeName}>
      <Label for={attributeName}>{labelText}</Label>
      <input
        id={attributeName}
        type={type}
        className="form-control"
        disabled={disabled}
        autoComplete={autoComplete}
        {...register(attributeName)}
      />
    </BtcFormGroup>
  );
}

export default BtcFormInput;
