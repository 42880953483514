import * as yup from "yup";
import config from "../config";

yup.addMethod(
  yup.string,
  "minNumberUppercaseLetters",
  function (numberOfLetters, msg) {
    return this.test("passwordTest", msg, function (value) {
      const { createError } = this;
      return (
        value.match(/[A-Z]/g)?.length >= numberOfLetters || createError(msg)
      );
    });
  }
);

yup.addMethod(
  yup.string,
  "minNumberLowercaseLetters",
  function (numberOfLetters, msg) {
    return this.test("passwordTest", msg, function (value) {
      const { createError } = this;
      return (
        value.match(/[a-z]/g)?.length >= numberOfLetters || createError(msg)
      );
    });
  }
);

yup.addMethod(yup.string, "minNumberDigits", function (numberOfLetters, msg) {
  return this.test("passwordTest", msg, function (value) {
    const { createError } = this;
    return value.match(/\d/g)?.length >= numberOfLetters || createError(msg);
  });
});

export const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Field is required")
    .min(
      config.cognitoPasswordPolicyParams.MINIMUM_LENGTH,
      "Password should be at least " +
        config.cognitoPasswordPolicyParams.MINIMUM_LENGTH +
        " characters long"
    )
    .minNumberDigits(
      config.cognitoPasswordPolicyParams.REQURE_NUMBERS === true ? 1 : 0,
      "Password should include at least one number"
    )
    .matches(
      /[ -/:-@[-`{-~]/,
      "Password should include at least one special character of " +
        config.cognitoPasswordPolicyParams.SPECIAL_CHARACTERS
    )
    .minNumberUppercaseLetters(
      config.cognitoPasswordPolicyParams.REQUIRE_UPPER_CASE === true ? 1 : 0,
      "Password should include at least one uppercase letter"
    )
    .minNumberLowercaseLetters(
      config.cognitoPasswordPolicyParams.REQUIRE_LOWER_CASE === true ? 1 : 0,
      "Password should include at least one lowercase letter"
    ),
  retypedPassword: yup
    .string()
    .required("Field is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const passwordWithVerificationCodeSchema = passwordSchema.shape({
  verificationCode: yup.string().required("Field is required"),
});

export const loginScheme = yup.object().shape({
  email: yup
    .string()
    .required("Field is required")
    .email("Please enter a valid email address."),
  password: yup.string().required("Field is required"),
});
