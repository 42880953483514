import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import CompanyEditRoleModulesCard from "./CompanyEditRoleModulesCard";
import { fetchRoles } from "../adminResource";
import { useAppContext } from "../../../utils/contextLib";

CompanyEditModules.propTypes = {
  notifyRef: PropTypes.object,
};

function CompanyEditModules({ notifyRef, companyName }) {
  const { fetchData } = useAppContext();
  let [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchData(
      () => fetchRoles(),
      setRoles,
      () => {},
      () => {},
      notifyRef
    );
  }, [notifyRef, fetchData]);

  return (
    <div>
      {roles.map((role, key) => (
        <Row key={key}>
          <Col lg={{ offset: 0, size: 12 }} xl={{ offset: 2, size: 8 }}>
            <CompanyEditRoleModulesCard
              notifyRef={notifyRef}
              role={role}
              companyName={companyName}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default CompanyEditModules;
