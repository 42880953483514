import React from "react";

BtcWarning.propTypes = {};

function BtcWarning({ text }) {
  return (
    <div className="alert-warning m-3 p-3">
      <b>Warning -</b> {text}
    </div>
  );
}

export default BtcWarning;
