/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
import { Col, Row } from "reactstrap";

import { ServicesCard } from "../services/ServicesCard";
import { CostsPlotCard } from "../costs/CostsPlotCard";
import { IssuesCard } from "../issues/IssuesCard";
import { ContactCard } from "./ContactCard";
import { FlatrateCostsCard } from "../flatratecosts/FlatrateCostsCard";
import AccountsByRegionCard from "./AccountsByRegionCard";
import SecurityOverviewCard from "../security/SecurityOverviewCard";
import { IamOverviewCard } from "../iam/IamOverviewCard";
import { NetworkOverviewCard } from "../network/NetworkOverviewCard";
import { UserContext } from "../../utils/userContext";

const Dashboard = () => {
  let activePermissions = [];

  const { activePermission } = useContext(UserContext);

  if (activePermission) {
    activePermissions = activePermission.companyModules;
  }

  const customerLogoContainer = document.getElementById("customerLogoImg");
  const isCustomerLogoVisible = customerLogoContainer?.innerHTML !== "";

  return (
    <section
      aria-labelledby="dashboard-page-heading"
      className={`content ${
        isCustomerLogoVisible ? "content-with-customer-logo" : ""
      }`}
    >
      <h2 id="dashboard-page-heading" className="visually-hidden">
        Dashboard
      </h2>
      <Row>
        {activePermissions.includes("costs") && (
          <Col xl="3" lg="4" md="6" sm="6">
            <CostsPlotCard />
          </Col>
        )}
        {activePermissions.includes("flatratecosts") && (
          <Col xl="3" lg="4" md="6" sm="6">
            <FlatrateCostsCard />
          </Col>
        )}
        {activePermission.extended && activePermissions.includes("services") && (
          <Col xl="3" lg="4" md="6" sm="6">
            <ServicesCard />
          </Col>
        )}
        {activePermissions.includes("issues") && (
          <Col xl="3" lg="4" md="6" sm="6">
            <IssuesCard />
          </Col>
        )}
        {activePermissions.includes("contact") && (
          <Col xl="3" lg="4" md="6" sm="6">
            <ContactCard />
          </Col>
        )}
        <Col xl="3" lg="4" md="6" sm="6">
          <AccountsByRegionCard />
        </Col>
        {activePermissions.includes("security") && (
          <Col xl="3" lg="4" md="6" sm="6">
            <SecurityOverviewCard />
          </Col>
        )}
        {activePermission.extended && activePermissions.includes("iam") && (
          <Col xl="3" lg="4" md="6" sm="6">
            <IamOverviewCard />
          </Col>
        )}
        {activePermissions.includes("network") && (
          <Col xl="3" lg="4" md="6" sm="6">
            <NetworkOverviewCard />
          </Col>
        )}
      </Row>
    </section>
  );
};

export default Dashboard;
