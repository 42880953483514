import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Button, Form, FormGroup, Label, Modal } from "reactstrap";
import classnames from "classnames";
import BtcWarning from "../../../components/BtcWarning";

AwsAccountBatchLoadModal.propTypes = {
  show: PropTypes.bool,
  companyName: PropTypes.string,
  hyperscaler: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

function AwsAccountBatchLoadModal({
  show,
  companyName,
  hyperscaler,
  onConfirm,
  onClose,
}) {
  const schema = yup.object().shape({
    creationConfirmation: yup
      .string()
      .oneOf(
        ["confirm"],
        `Write 'confirm' to confirm loading of all ${hyperscaler} accounts`
      )
      .required("Write 'confirm' to confirm loading of all accounts"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  function onSubmit() {
    onConfirm();
  }

  return (
    <Modal isOpen={show}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header justify-content-center">
          <h4 className="title title-up text-wrap btc-shorten-overflow-text">
            Load accounts for {companyName}
          </h4>
        </div>
        <div className="modal-body">
          <FormGroup
            className={classnames({
              "has-danger": errors.creationConfirmation,
            })}
          >
            <Label for="creation-confirmation">
              Enter 'confirm' to confirm loading all {hyperscaler} accounts of{" "}
              {companyName}
            </Label>
            <input
              type="text"
              id="creation-confirmation"
              className="form-control"
              name="creation-confirmation"
              {...register("creationConfirmation")}
              autoComplete="off"
            />
          </FormGroup>
          <BtcWarning text="Accounts which are already configured won't be replaced." />
        </div>
        <div className="modal-footer">
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <input
            className="btn btn-success ml-2"
            type="submit"
            value={"Start loading"}
            disabled={!isValid}
          />
        </div>
      </Form>
    </Modal>
  );
}

export default AwsAccountBatchLoadModal;
