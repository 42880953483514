import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
} from "reactstrap";
import BtcSpinner from "../components/BtcSpinner";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordWithVerificationCodeSchema } from "./passwordValidation";

export const PasswordResetNewPasswordForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(passwordWithVerificationCodeSchema),
  });

  return (
    <Form onSubmit={handleSubmit(props.resetPassword)} id="LoginValidation">
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Reset Password</CardTitle>
        </CardHeader>
        <CardBody>
          <FormGroup
            className={`form-group ${errors.password ? "has-danger" : ""}`}
          >
            <label>New Password *</label>
            <input
              className="form-control"
              type="password"
              {...register("password")}
            />
            {errors.password ? (
              <label role="alert" className="error">
                {errors.password?.message}
              </label>
            ) : null}
          </FormGroup>
          <FormGroup
            className={`form-group ${
              errors.retypedPassword ? "has-danger" : ""
            }`}
          >
            <label>Retype New Password *</label>
            <input
              className="form-control"
              type="password"
              {...register("retypedPassword")}
            />
            {errors.retypedPassword ? (
              <label role="alert" className="error">
                {errors.retypedPassword?.message}
              </label>
            ) : null}
          </FormGroup>
          <FormGroup
            className={`form-group ${
              errors.verificationCode ? "has-danger" : ""
            }`}
          >
            <label>Verification Code (sent by Email) *</label>
            <input
              className="form-control"
              type="number"
              {...register("verificationCode")}
            />
            {errors.verificationCode ? (
              <label role="alert" className="error">
                {errors.verificationCode?.message}
              </label>
            ) : null}
          </FormGroup>

          <Button
            block
            color="success"
            disabled={
              !!errors.password ||
              !!errors.retypedPassword ||
              !!errors.verificationCode
            }
            type="submit"
          >
            {props.isLoading ? <BtcSpinner /> : "Change password"}
          </Button>

          <label>* Required fields</label>
        </CardBody>
      </Card>
    </Form>
  );
};
