import React from "react";
import classnames from "classnames";
import { FormGroup } from "reactstrap";
import FormError from "./FormError";
import PropTypes from "prop-types";

BtcFormGroup.propTypes = {
  formErrors: PropTypes.object,
  attributeName: PropTypes.string,
  check: PropTypes.bool,
  className: PropTypes.string,
};

function BtcFormGroup({
  formErrors,
  attributeName,
  className = "",
  check = false,
  children,
}) {
  let error = formErrors;
  // for loop is here so errors for nested objects are also visible
  if (error) {
    for (const part of attributeName.split(".")) {
      error = error[part];
      if (!error) {
        break;
      }
    }
  }

  return (
    <FormGroup
      check={check}
      className={
        className +
        " " +
        classnames({
          "has-danger": error,
        })
      }
    >
      {children}
      <FormError error={error} />
    </FormGroup>
  );
}

export default BtcFormGroup;
