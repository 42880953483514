import BtcCardHeader from "../../components/BtcCardHeader";
import { Button, CardBody, CardFooter } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import BtcListRow from "../../components/BtcListRow";
import BtcCard from "../../components/BtcCard";
import { roundTo2Decimals } from "../../utils/mathUtil";

const CostAnomaliesOverviewCard = (props) => {
  const [numberOfCostAnomalies, setNumberOfCostAnomalies] = useState(0);
  const [totalCostOfAnomalies, setTotalCostOfAnomalies] = useState(0);
  const [isAzureHyperScalerActive, setIsAzureHyperScalerActive] =
    useState(false);
  const notifyRef = useRef(null);

  useEffect(() => {
    setNumberOfCostAnomalies(props.costAnomaliesData.length);

    const totalAnomaliesCost = props.costAnomaliesData
      .map((item) => parseFloat(item.totalCostImpact))
      .reduce((a, b) => a + b, 0);

    setTotalCostOfAnomalies(totalAnomaliesCost);
  }, [props.costAnomaliesData]);

  useEffect(() => {
    if (
      props.activeHyperScalers.includes("azure") &&
      !props.activeHyperScalers.includes("aws")
    ) {
      setIsAzureHyperScalerActive(true);
    } else {
      setIsAzureHyperScalerActive(false);
    }
  }, [props.activeHyperScalers]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcCard label="Cost anomalies overview">
        <BtcCardHeader
          title="Cost anomalies"
          icon="fas fa-euro-sign"
          error={props.anomaliesCostErrors}
          loading={props.loading}
          updatedAt={props?.costAnomaliesData[0]?.updatedAt}
        />
        <CardBody>
          {isAzureHyperScalerActive ? (
            <div className="anomalies-cost-user-prompt">
              <span>
                Dear user, at the moment this feature is not supported by Azure
              </span>
            </div>
          ) : (
            <>
              <span>Last 90 days</span>
              <BtcListRow
                icon="fas fa-exclamation-triangle"
                text="Anomalies detected"
                loading={props.loading}
              >
                {numberOfCostAnomalies || 0}
              </BtcListRow>
              <BtcListRow
                icon="fas fa-solid fa-wallet"
                text="Total cost impact"
                loading={props.loading}
              >
                {`${roundTo2Decimals(totalCostOfAnomalies) || 0}€`}
              </BtcListRow>
            </>
          )}
        </CardBody>
        <CardFooter>
          <hr />
          <Button
            disabled={props.costAnomaliesData.length === 0}
            className="btn-default btn-sm btn-align-right"
            onClick={props.switchDetailsHandler}
          >
            {" "}
            {props.isCostAnamaliesCardVisible ? "Hide details" : "Show details"}
          </Button>
        </CardFooter>
      </BtcCard>
    </>
  );
};

export default CostAnomaliesOverviewCard;
