import React, { useContext, useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { pushHistory } from "../utils/urlUtil";
import { useHistory } from "react-router-dom";
import { UserContext } from "../utils/userContext";

CompanySelectionDropdown.propTypes = {};

function CompanySelectionDropdown() {
  let history = useHistory();
  const { user, activePermission, activeAccountIds } = useContext(UserContext);

  const [selectedCompany, setSelectedCompany] = useState(
    activePermission.company
  );

  useEffect(() => {
    setSelectedCompany(activePermission.company);
  }, [activePermission]);

  function selectCompany(option) {
    if (option === activePermission) {
      return;
    }
    pushHistory(history, option, activeAccountIds);
  }

  return user.accessPermissions.length > 1 ? (
    <UncontrolledDropdown>
      <DropdownToggle
        caret
        className="btn-round btn btn-outline-success"
        id="dropdown-basic"
      >
        {selectedCompany}
      </DropdownToggle>
      <DropdownMenu>
        {user.accessPermissions.map((i) => (
          <DropdownItem
            className="primary-text"
            key={i.company}
            onClick={() => selectCompany(i)}
          >
            {i.company}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <></>
  );
}

export default CompanySelectionDropdown;
