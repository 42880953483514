export function uniqueMerge(a, b) {
  let nonDuplicates = [];
  for (let i = 0, l = b.length; i < l; ++i) {
    if (a.indexOf(b[i]) === -1) {
      nonDuplicates.push(b[i]);
    }
  }
  return a.concat(nonDuplicates);
}

export function sum(array) {
  return array.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
}

export function sumOfAttribute(array, attribute) {
  return sum(array.map((entry) => entry[attribute]));
}

export function distinct(array) {
  return [...new Set(array)];
}

export function getMaxProperty(items, key) {
  return items?.map((c) => c[key]).reduce((a, b) => (a > b ? a : b), null);
}

export function equals(a, b) {
  return a.length === b.length && a.every((v, i) => v === b[i]);
}
