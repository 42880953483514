import BtcCard from "../../components/BtcCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import { CardBody, CardFooter } from "reactstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../utils/userContext";
import { useAppContext } from "../../utils/contextLib";
import { fetchCompanyAccounts } from "./iamResource";
import BtcListRow from "../../components/BtcListRow";

export const IamSubscriptionOverviewCard = () => {
  const { fetchData } = useAppContext();
  const { activePermission } = useContext(UserContext);
  const [busy, setBusy] = useState(true);
  const [error, setError] = useState(null);
  const notifyRef = useRef(null);
  const [companyAccounts, setCompanyAccounts] = useState(0);

  useEffect(() => {
    const company = activePermission["company"];

    fetchData(
      () => fetchCompanyAccounts(company),
      (res) => setCompanyAccounts(res[0]),
      setError,
      setBusy,
      notifyRef
    );
  }, [activePermission, fetchData]);

  return (
    <BtcCard label="Iam" className="card-stats">
      <BtcCardHeader
        title="Organization Accounts"
        icon="far fa-id-badge fa-fix"
        error={error}
        loading={busy}
        url="/iam"
      />
      <CardBody>
        <BtcListRow icon="bi bi-cloud-fill" text="Accounts" loading={busy}>
          {companyAccounts?.accountCount != null
            ? companyAccounts.accountCount
            : "?"}
        </BtcListRow>
        <BtcListRow
          icon="bi bi-cloud-plus-fill"
          text="Accounts Created (30d)"
          loading={busy}
        >
          {companyAccounts?.accountLast30DaysCreatedCount != null
            ? companyAccounts.accountLast30DaysCreatedCount
            : "?"}
        </BtcListRow>
      </CardBody>
      <CardFooter />
    </BtcCard>
  );
};
