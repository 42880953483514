import React, { useContext, useEffect, useState } from "react";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import {
  exportTablePDF,
  exportTableXlsxCsv,
  extractFilteredData,
} from "../../utils/exportTableData";
import { Badge, Button, CardBody, UncontrolledTooltip } from "reactstrap";
import ReactTable from "../../components/ReactTable";
import { prepareForId } from "../../utils/regExpUtil";
import BtcAccountName from "../../components/BtcAccountName";
import { useAppContext } from "../../utils/contextLib";
import { UserContext } from "../../utils/userContext";
import { getMaxProperty } from "../../utils/arrayUtil";
import { fetchActiveFindings } from "./inspectorResource";
import { shouldItemBeVisible } from "../../utils/accountUtil";
import { capitalizeFirstLetter } from "../../utils/stringUtil";
import { DateTime } from "luxon";

InspectorFindingsTableCard.propTypes = {};

const exportConfiguration = {
  tableColumnsNames: [
    "Severity",
    "Title",
    "Account",
    "Hyperscaler",
    "Resource",
    "Resource Type",
    "Type",
    "Date",
  ],
  dataToExport: [
    "severity",
    "title",
    "accountName",
    "hyperscaler",
    "impactedResource",
    "impactedResourceTypePretty",
    "findingTypePretty",
    "date",
  ],
  columnsWidth: [
    { wch: 50 },
    { wch: 175 },
    { wch: 80 },
    { wch: 40 },
    { wch: 80 },
    { wch: 75 },
    { wch: 75 },
    { wch: 75 },
  ],

  columnsWidthXls: [
    { wch: 10 },
    { wch: 35 },
    { wch: 16 },
    { wch: 8 },
    { wch: 16 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
  ],
};

function InspectorFindingsTableCard({ notifyRef }) {
  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);

  const [findings, setFindings] = useState([]);
  const [filteredFindings, setFilteredFindings] = useState([]);
  const [userFilteredFindings, setUserFilteredFindings] = useState([]);
  const [updatedAt, setUpdatedAt] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let company = activePermission["company"];
    fetchData(
      () => fetchActiveFindings(company),
      (fetchedFindings) => {
        setFindings(fetchedFindings);
        setUpdatedAt(getMaxProperty(fetchedFindings, "updatedAt"));
      },
      setError,
      setLoading,
      notifyRef
    );
  }, [activePermission, fetchData, notifyRef]);

  useEffect(() => {
    if (!findings) {
      return;
    }
    const filteredFindings = findings.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );
    setFilteredFindings(filteredFindings);
  }, [findings, activeAccountIds]);

  const updateUserFilteredFindings = (data) => {
    setUserFilteredFindings(extractFilteredData(data));
  };

  function getBadgeVariantBySeverity(severity) {
    switch (severity) {
      case "INFORMATIONAL":
        return "general-green";
      case "LOW":
        return "general-green";
      case "MEDIUM":
        return "warning";
      case "HIGH":
        return "danger";
      case "CRITICAL":
        return "danger";
      default:
        return "warning";
    }
  }

  return (
    <BtcNoAspectRatioCard label="Inspector Findings Table">
      <BtcCardHeader
        title="Inspector Findings"
        error={error}
        loading={loading}
        updatedAt={updatedAt}
        exportDataMenu={true}
        isDataToExportAvailable={userFilteredFindings.length > 0}
        exportExcelFileFn={() =>
          exportTableXlsxCsv(
            "xlsx",
            userFilteredFindings,
            exportConfiguration,
            "inspector-findings"
          )
        }
        exportCsvFileFn={() =>
          exportTableXlsxCsv(
            "csv",
            userFilteredFindings,
            exportConfiguration,
            "inspector-findings"
          )
        }
        exportPdfFileFn={() =>
          exportTablePDF(
            userFilteredFindings,
            exportConfiguration,
            "landscape",
            "inspector-findings"
          )
        }
      />
      <CardBody>
        <ReactTable
          id="inspector-table"
          data={filteredFindings}
          hiddenColumns={["description", "severity"]}
          columns={[
            {
              Header: "Severity",
              accessor: "severityIndex", // index allows sorting, if not low would be higher than medium
              className: "btc-flex-security-level",
              Cell: (props) => (
                <Badge
                  color={getBadgeVariantBySeverity(props.row.values.severity)}
                >
                  {capitalizeFirstLetter(
                    props.row.values.severity.toLowerCase()
                  )}
                </Badge>
              ),
            },
            {
              Header: "Severity Not Visible",
              accessor: "severity",
            },
            {
              Header: "Title",
              accessor: "title",
              className: "btc-flex-security-eventtype",
              Cell: (props) => (
                <>
                  <Button
                    id={prepareForId(props.value)}
                    className="btn btn-neutral p-0 btc-security-table-btn "
                  >
                    {props.value}
                  </Button>
                  <UncontrolledTooltip
                    placement="top"
                    target={prepareForId(props.value)}
                  >
                    {props.row.values.description}
                  </UncontrolledTooltip>
                </>
              ),
            },
            {
              Header: "Description Not Visible",
              accessor: "description",
            },
            {
              Header: "Account",
              accessor: "accountName",
              Cell: (item) => (
                <BtcAccountName
                  accountName={item.value}
                  hyperscaler={item.row.original.hyperscaler}
                />
              ),
            },
            {
              Header: "Resource",
              accessor: "impactedResource",
            },
            {
              Header: "Resource Type",
              accessor: "impactedResourceTypePretty",
            },
            {
              Header: "Type",
              accessor: "findingTypePretty",
            },
            {
              Header: "Date",
              accessor: (value) =>
                DateTime.fromFormat(value.date, "yyyy-LL-dd").toFormat(
                  "dd/LL/yyyy"
                ),
              sortType: (rowA, rowB, id, desc) => {
                if (rowA.values.date > rowB.values.date) return 1;
                if (rowB.values.date > rowA.values.date) return -1;
                return 0;
              },
            },
          ]}
          className="prevent-too-small-columns"
          getFilteredData={updateUserFilteredFindings}
        />
      </CardBody>
    </BtcNoAspectRatioCard>
  );
}

export default InspectorFindingsTableCard;
