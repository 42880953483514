import React from "react";
import { Col, Row } from "reactstrap";
import UserEditForm from "./UserEditForm";

function UserEditPage({ notifyRef }) {
  return (
    <section
      aria-labelledby="user-edit-module-page-heading"
      className="content"
    >
      <h2 id="user-edit-module-page-heading" className="visually-hidden">
        User Edit Page
      </h2>
      <Row>
        <Col>
          <UserEditForm isCreate={false} notifyRef={notifyRef} />
        </Col>
      </Row>
    </section>
  );
}

export default UserEditPage;
