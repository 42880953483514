import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import BtcCardHeader from "../../../components/BtcCardHeader";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createAzureSubscription,
  deleteAzureSubscription,
  fetchAzureSubscription,
  putAzureSubscription,
} from "../adminResource";
import { notifyError, notifySuccess } from "../../../utils/notification";
import { useHistory, useLocation } from "react-router-dom";
import DeletionModal from "../../../components/DeletionModal";
import BtcFormInput from "../../../components/BtcFormInput";
import { useAppContext } from "../../../utils/contextLib";
import { pushHistory } from "../../../utils/urlUtil";
import { UserContext } from "../../../utils/userContext";
import BtcLink from "../../../components/BtcLink";

AzureSubscriptionEditCard.propTypes = {
  notifyRef: PropTypes.object,
  isCreate: PropTypes.bool,
};

function AzureSubscriptionEditCard({ notifyRef, isCreate }) {
  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();

  const [companyName, setCompanyName] = useState();
  const [modalSubscription, setModalSubscription] = useState();
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [backendCall, setBackendCall] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const schema = yup.object().shape({
    accountId: yup
      .string()
      .matches(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/,
        "Subscription ID must be a UUID"
      )
      .required("A Subscription ID is required"),
    accountName: yup
      .string()
      .required("A Subscription Name is required")
      .matches(/^[^#]+$/, "Subscription Name mustn't contain a #"),
    hyperscaler: yup
      .string()
      .oneOf(["azure"], "Hyperscaler must be azure")
      .required("A hyperscaler is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      hyperscaler: "azure",
    },
  });

  useEffect(() => {
    let pathParams = location.pathname
      .split(isCreate ? "subscription-create/" : "subscription-edit/")
      .pop();
    const companyName = pathParams.split("/")[0];
    setCompanyName(companyName);

    if (isCreate) {
      setLoading(false);
      return;
    }

    const accountId = pathParams.split("/").pop();

    fetchData(
      () => fetchAzureSubscription(companyName, accountId),
      (subscription) => {
        setModalSubscription(subscription);
        reset(subscription);
      },
      setError,
      setLoading,
      notifyRef
    );
  }, [reset, location, isCreate, notifyRef, fetchData]);

  function onSubmit(data) {
    const transferData = {
      hyperscaler: data.hyperscaler,
      subscriptionId: data.accountId,
      subscriptionName: data.accountName,
    };
    if (isCreate) {
      handleBackendCall(
        createAzureSubscription(companyName, transferData),
        "Creation of azure subscription was successful.",
        `/admin/company-edit/${companyName}`
      );
    } else {
      handleBackendCall(
        putAzureSubscription(
          companyName,
          transferData.subscriptionId,
          transferData
        ),
        "Update of azure subscription was successful.",
        `/admin/subscription-edit/${companyName}/${data.accountId}`
      );
    }
  }

  function handleBackendCall(backendPromise, successMessage, path) {
    setBackendCall(true);
    backendPromise
      .then(() => {
        setBackendCall(false);
        pushHistory(history, activePermission, activeAccountIds, path);
        notifySuccess(notifyRef, successMessage);
      })
      .catch((e) => {
        setBackendCall(false);
        notifyError(notifyRef, e);
      });
  }

  function doDeleteAzureSubscription() {
    setShowDeletionModal(false);
    handleBackendCall(
      deleteAzureSubscription(companyName, modalSubscription.accountId),
      "Deletion of azure subscription was successful.",
      `/admin/company-edit/${companyName}`
    );
  }

  return (
    <section aria-labelledby="azure-subscription-heading" className="content">
      <h2 id="azure-subscription-heading" className="visually-hidden">
        {isCreate
          ? "Azure Subscription Create Page"
          : "Azure Subscription Edit Page"}
      </h2>
      <Row>
        <Col
          md={{ offset: 1, size: 10 }}
          lg={{ offset: 2, size: 8 }}
          xl={{ offset: 3, size: 6 }}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <BtcCardHeader
                title={
                  isCreate
                    ? "Azure Subscription Creation"
                    : "Azure Subscription Edit"
                }
                loading={loading}
                error={error}
              />
              <CardBody className="px-5 pb-4">
                <BtcFormInput
                  attributeName={"accountId"}
                  labelText={"Subscription ID"}
                  disabled={loading || backendCall || !isCreate}
                  formErrors={errors}
                  register={register}
                />
                <BtcFormInput
                  attributeName={"accountName"}
                  labelText={"Subscription Name"}
                  disabled={loading || backendCall}
                  formErrors={errors}
                  register={register}
                />
                <BtcFormInput
                  attributeName={"hyperscaler"}
                  labelText={"Hyperscaler"}
                  disabled={true}
                  formErrors={errors}
                  register={register}
                />
              </CardBody>
            </Card>
            {!isCreate && (
              <div className="px-4">
                <Button
                  className="pull-left card-shadow btn--no-margin"
                  color="danger"
                  disabled={loading || backendCall}
                  onClick={() => setShowDeletionModal(true)}
                >
                  <i className="fas fa-trash" /> Delete
                </Button>
              </div>
            )}
            <div className="px-4 pull-right">
              <BtcLink
                className="btn btn-success btn--no-margin card-shadow"
                to={`/admin/company-edit/${companyName}`}
                disabled={loading || backendCall}
              >
                Cancel
              </BtcLink>
              <input
                className="btn btn-success pull-right card-shadow btn--no-margin ml-3"
                type="submit"
                value={isCreate ? "Create Azure Subscription" : "Save changes"}
                disabled={loading || !isValid || backendCall || !isDirty}
              />
            </div>
          </Form>
        </Col>
      </Row>
      {modalSubscription && (
        <DeletionModal
          show={showDeletionModal}
          title={`Delete subscription ${modalSubscription.accountName}`}
          deleteButton={`Delete Subscription`}
          onDelete={() => doDeleteAzureSubscription()}
          onClose={() => setShowDeletionModal(false)}
        />
      )}
    </section>
  );
}

export default AzureSubscriptionEditCard;
