import React, { useContext, useEffect, useRef, useState } from "react";
import { CardBody } from "reactstrap";
import ReactTable from "components/ReactTable.js";
import NotificationAlert from "react-notification-alert";
import { UserContext } from "../../utils/userContext";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import { shouldItemBeVisible } from "../../utils/accountUtil";
import BtcCardHeader from "../../components/BtcCardHeader";
import { getMaxProperty } from "../../utils/arrayUtil";
import { roundTo2Decimals } from "../../utils/mathUtil";
import { changeDateFormat } from "../../utils/dateUtil";
import {
  exportTablePDF,
  exportTableXlsxCsv,
  extractFilteredData,
} from "../../utils/exportTableData";
import BtcAccountName from "../../components/BtcAccountName";
import BtcCostWithTooltip from "../../components/BtcCostWithTooltip";

const exportConfiguration = {
  tableColumnsNames: [
    "Month",
    "Account ID",
    "Account name",
    "Resource",
    "Tag",
    "Costs in €",
  ],
  dataToExport: [
    "date",
    "accountId",
    "accountName",
    "resource",
    "tags",
    "costs",
  ],
  columnsWidth: [
    { wch: 75 },
    { wch: 120 },
    { wch: 120 },
    { wch: 120 },
    { wch: 160 },
    { wch: 80 },
  ],
  columnsWidthXls: [
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 30 },
    { wch: 15 },
    { wch: 10 },
  ],
};

export const CostsServiceCard = ({ serviceCosts, loading, error }) => {
  const { activePermission, activeAccountIds } = useContext(UserContext);

  // Sets the Card to busy, which means it is just doing something (calculation or async requests)
  const [filteredServiceCosts, setFilteredServiceCosts] = useState([]);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [filteredTableData, setFilteredTableData] = useState([]);

  const notifyRef = useRef(null);

  useEffect(() => {
    if (!serviceCosts) {
      return;
    }
    const filteredServiceCosts = serviceCosts.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );
    setFilteredServiceCosts(filteredServiceCosts);
    setUpdatedAt(getMaxProperty(filteredServiceCosts, "updatedAt"));
  }, [serviceCosts, activeAccountIds]);

  const getFilteredData = (data) => {
    setFilteredTableData(extractFilteredData(data));
  };

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      {activePermission["company"] === "TSOBSS" ? (
        <div />
      ) : (
        <div className="content">
          <BtcNoAspectRatioCard label="Service Costs">
            <BtcCardHeader
              title="Service Costs"
              updatedAt={updatedAt}
              error={error}
              loading={loading}
              exportDataMenu={true}
              isDataToExportAvailable={filteredTableData.length > 0}
              exportExcelFileFn={() =>
                exportTableXlsxCsv(
                  "xlsx",
                  filteredTableData,
                  exportConfiguration,
                  "service-costs"
                )
              }
              exportCsvFileFn={() =>
                exportTableXlsxCsv(
                  "csv",
                  filteredTableData,
                  exportConfiguration,
                  "service-costs"
                )
              }
              exportPdfFileFn={() =>
                exportTablePDF(
                  filteredTableData,
                  exportConfiguration,
                  "landscape",
                  "service-costs"
                )
              }
            />
            <CardBody>
              <ReactTable
                data={filteredServiceCosts}
                hiddenColumns={["currency", "costsV2", "date"]}
                columns={[
                  {
                    Header: "Month",
                    accessor: (value) => changeDateFormat(value.date, "/"),
                    sortType: (rowA, rowB, id, desc) => {
                      if (rowA.values.date > rowB.values.date) return 1;
                      if (rowB.values.date > rowA.values.date) return -1;
                      return 0;
                    },
                  },
                  {
                    Header: "date", // invisible column, only for sorting
                    accessor: "date",
                  },
                  {
                    Header: "Account-ID",
                    accessor: "accountId",
                  },
                  {
                    Header: "Account-Name",
                    accessor: "accountName",
                    Cell: (item) => (
                      <BtcAccountName
                        accountName={item.value}
                        hyperscaler={item.row.original.hyperscaler}
                      />
                    ),
                  },
                  {
                    Header: "Resource",
                    accessor: "resource",
                  },
                  {
                    Header: "Tag",
                    accessor: "tags",
                  },
                  {
                    // invisible column
                    accessor: "currency",
                  },
                  {
                    // invisible column
                    accessor: "costsV2",
                  },
                  {
                    Header: "Costs",
                    accessor: "costs",
                    Cell: (item) => (
                      <BtcCostWithTooltip costsV2={item.row.values.costsV2} />
                    ),
                    Footer: (info) => {
                      return (
                        <span>
                          <b>
                            Sum Costs:{" "}
                            {roundTo2Decimals(
                              info.filteredRows
                                .map((row) => row.values.costs)
                                .reduce((a, b) => a + b, 0)
                            )}{" "}
                            €
                          </b>
                        </span>
                      );
                    },
                  },
                ]}
                className="prevent-too-small-columns"
                getFilteredData={getFilteredData}
              />
            </CardBody>
          </BtcNoAspectRatioCard>
        </div>
      )}
    </>
  );
};
