import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
} from "reactstrap";
import BtcSpinner from "../components/BtcSpinner";
import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please provide a valid E-Mail")
    .required("An E-Mail is required"),
});

export const PasswordResetEmailForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
  });

  return (
    <Form
      onSubmit={handleSubmit(props.sendVerificationCodeHandler)}
      id="VerificationCode"
    >
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Reset Password</CardTitle>
        </CardHeader>
        <CardBody>
          <FormGroup
            className={`form-group ${errors.email ? "has-danger" : ""}`}
          >
            <label>Email Address *</label>
            <input
              id="email"
              name="email"
              className="form-control"
              {...register("email")}
            />
            {errors.email ? (
              <label role="alert" className="error">
                {errors.email?.message}
              </label>
            ) : null}
          </FormGroup>

          <Button block color="success" disabled={!!errors.email} type="submit">
            {props.isLoading ? <BtcSpinner /> : "Send verification code"}
          </Button>
          <label>* Required field</label>
        </CardBody>
      </Card>
    </Form>
  );
};
