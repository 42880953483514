import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Button, Form, FormGroup, Label, Modal } from "reactstrap";
import classnames from "classnames";
import BtcWarning from "../../../components/BtcWarning";

CompanyCreateModal.propTypes = {
  show: PropTypes.bool,
  companyName: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

function CompanyCreateModal({ show, companyName, onConfirm, onClose }) {
  const schema = yup.object().shape({
    creationConfirmation: yup
      .string()
      .oneOf(["create"], "Write 'create' to confirm creation")
      .required("Write 'create' to confirm creation"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  function onSubmit() {
    onConfirm();
  }

  return (
    <Modal isOpen={show}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header justify-content-center">
          <h4 className="title title-up text-wrap btc-shorten-overflow-text">
            Create company {companyName}
          </h4>
        </div>
        <div className="modal-body">
          <FormGroup
            className={classnames({
              "has-danger": errors.creationConfirmation,
            })}
          >
            <Label for="creation-confirmation">
              Enter 'create' to confirm creation
            </Label>
            <input
              type="text"
              id="creation-confirmation"
              className="form-control"
              name="creation-confirmation"
              {...register("creationConfirmation")}
              autoComplete="off"
            />
          </FormGroup>
          <BtcWarning text="Companies can't be deleted (yet)." />
        </div>
        <div className="modal-footer">
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <input
            className="btn btn-success ml-2"
            type="submit"
            value={"Create Company"}
            disabled={!isValid}
          />
        </div>
      </Form>
    </Modal>
  );
}

export default CompanyCreateModal;
