import React from "react";
import PropTypes from "prop-types";
import BtcCardHeader from "../../../components/BtcCardHeader";
import { Card, CardBody } from "reactstrap";
import BtcFormInput from "../../../components/BtcFormInput";

UserDetailsFormCard.propTypes = {
  isCreate: PropTypes.bool,
  userLoading: PropTypes.bool,
  register: PropTypes.func,
  errors: PropTypes.array,
  disabled: PropTypes.bool,
};

function UserDetailsFormCard({
  isCreate,
  userLoading,
  register,
  formErrors,
  error,
  disabled,
}) {
  return (
    <Card>
      <BtcCardHeader title="User Details" loading={userLoading} error={error} />
      <CardBody className="px-5 pb-4">
        <BtcFormInput
          attributeName={"email"}
          labelText={"E-Mail"}
          disabled={!isCreate || disabled}
          formErrors={formErrors}
          register={register}
        />
      </CardBody>
    </Card>
  );
}

export default UserDetailsFormCard;
