export function putSearchParams(url, activePermission, activeAccountIds) {
  const query = buildQuery(activePermission, activeAccountIds);
  return url + query;
}

export function buildQuery(activePermission, activeAccountIds) {
  return `?company=${
    activePermission.company
  }&accountid=${activeAccountIds.join(",")}`;
}

export function pushHistory(
  history,
  activePermission,
  activeAccountIds,
  pathname = history.location.pathname
) {
  const filteredAccountIds = filterAccounts(activePermission, activeAccountIds);
  const query = buildQuery(activePermission, filteredAccountIds);
  let newPath = getPath(pathname, activePermission);
  history.push({
    pathname: newPath,
    search: query,
  });
}

function getPath(pathname, activePermission) {
  const currentModuleName = pathname.split("/")[1];
  const allActiveUserModules =
    activePermission.companyModules.concat("user-profile");
  const isUserHaveAccess = allActiveUserModules.includes(currentModuleName);

  return isUserHaveAccess ? pathname : "/dashboard";
}

function filterAccounts(activePermission, accounts) {
  let accountIdsInCompany = activePermission.companyAccListObj.map(
    (c) => c.accountId
  );
  let result = accounts.filter(
    (account) => accountIdsInCompany.indexOf(account) !== -1
  );
  return result.length !== 0 ? result : ["all"];
}
