import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import logo from "../assets/img/BTC-logo.png";
import { MfaSetupForm } from "./MfaSetupForm";

export const MFASetup = () => {
  return (
    <div className="content">
      <Row>
        <Col sm="3" md="4" xl="5" />
        <Col sm="6" md="4" xl="2">
          <div className="login-logo">
            <a
              href="http://www.btc-ag.com"
              target="blank"
              className="logo-normal"
            >
              <img src={logo} alt="BTC-AG" />
            </a>
            <p>BTC Utility Cloud Dashboard</p>
          </div>
        </Col>
        <Col sm="3" md="4" xl="5" />
      </Row>
      <Row>
        <Col sm="3" md="4" xl="5" />
        <Col sm="6" md="4" xl="2">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Setting up MFA</CardTitle>
            </CardHeader>
            <CardBody>
              <span>
                Configure authentication app like Google Authenticator or Auth
                to turn on multi-factor authentication
              </span>
              <MfaSetupForm />
            </CardBody>
          </Card>
        </Col>
        <Col sm="3" md="4" xl="5" />
      </Row>
      <Row>
        <Col />
      </Row>
    </div>
  );
};
