// ##############################
// // // Chart variables
// #############################

const chartExample1 = {
  data: {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
    datasets: [
      {
        backgroundColor: "#0076de",
        fill: "+2",
        label: "Costs in €",
        borderColor: "#0076de",
        pointRadius: 1,
        pointHoverRadius: 0,
        borderWidth: 3,
        barPercentage: 1.6,
        tension: 0.4,
        data: [1050, 950, 1400, 1650, 1300, 1100, 1130, 1160, 1275],
        pointBorderColor: "rgba(0,0,0,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointHitRadius: 10,
      },
    ],
  },
  options: {
    legend: {
      display: true,
    },

    tooltip: {
      enabled: true,
    },

    scales: {
      y: {
        // stacked: true,
        ticks: {
          fontColor: "#9f9f9f",
          beginAtZero: false,
          maxTicksLimit: 3,
          //padding: 20
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },

      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
        },
      },
    },
  },
};

const BTCChart2 = {
  data: {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
    datasets: [
      {
        backgroundColor: "#fbd200",
        label: "Users",
        borderColor: "#fbd200",
        fill: false,
        pointHoverRadius: 0,
        borderWidth: 3,
        barPercentage: 1.6,
        tension: 0.4,
        data: [100, 120, 140, 160, 400, 450, 500, 460, 700, 720],
        pointBorderColor: "rgba(0,0,0,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        display: true,
      },

      tooltip: {
        enabled: true,
      },
    },

    scales: {
      y: {
        ticks: {
          fontColor: "#9f9f9f",
          beginAtZero: false,
          maxTicksLimit: 5,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },

      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
        },
      },
    },
  },
};

// #########################################
// // // used inside src/views/Dashboard.js and src/views/Charts.js
// Data defined for AMM Service Card
// #########################################
const chartExample13 = {
  data: {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
    datasets: [
      {
        backgroundColor: "#0076de",
        fill: "+2",
        label: "Costs in €",
        borderColor: "#0076de",
        pointRadius: 1,
        pointHoverRadius: 0,
        borderWidth: 3,
        barPercentage: 1.6,
        tension: 0.4,
        data: [1350, 1150, 1500, 1850, 1300, 1350, 1820, 980, 1225],
        pointBorderColor: "rgba(0,0,0,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointHitRadius: 10,
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        ticks: {
          fontColor: "#9f9f9f",
          beginAtZero: false,
          maxTicksLimit: 3,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },

      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
        },
      },
    },
  },
};

const BTCChart3 = {
  data: {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
    datasets: [
      {
        backgroundColor: "#fbd200",
        label: "Endpoints",
        borderColor: "#fbd200",
        fill: false,
        pointHoverRadius: 0,
        borderWidth: 3,
        barPercentage: 1.6,
        data: [10050, 10165, 10369, 11583, 12020, 12290, 13200, 15000, 15100],
        pointBorderColor: "rgba(0,0,0,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        display: true,
      },

      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        ticks: {
          fontColor: "#9f9f9f",
          beginAtZero: false,
          maxTicksLimit: 5,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },

      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
        },
      },
    },
  },
};

module.exports = {
  chartExample1,
  BTCChart2,
  // used for Charts view AMM in services
  chartExample13,
  BTCChart3,
};
