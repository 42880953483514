import { useContext, createContext } from "react";

export const AppContext = createContext({
  // this definition is here so auto-completion in IDE works
  // real code is in App.js
  fetchData: (
    fetchFunction,
    onSuccess,
    setError,
    setLoading,
    notifyRef,
    onError
  ) => {},
  fetchMultipleData: (
    promises,
    setError,
    setLoading,
    notifyRef,
    onError = (e) => {}
  ) => {},
});

export function useAppContext() {
  return useContext(AppContext);
}
