import { notify } from "../utils/notification";

export function onError(error, ref) {
  let message = error.toString();

  if (message.includes("Username cannot be empty")) {
    notify({
      ref: ref,
      type: 4,
      place: "tc",
      message: 'Email Address must be set to use "Forgot Password" option.',
    });
    return;
  }

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    message = error.message;
  } else if (message.includes("UserNotFoundException: ")) {
    message = message.split("UserNotFoundException: ")[1];
  } else if (message.includes("NotAuthorizedException: ")) {
    message = message.split("NotAuthorizedException: ")[1];
  }

  notify({ ref: ref, type: 3, place: "tc", message: message });
}
