/**
 * Returns list of names of corresponding month relative to today from monthStartOffset until monthEndOffset.
 *
 * If today is 2.12.2021, it will return for monthStartOffset = - 2 and monthEndOffset = 1
 * ['October', 'November', 'December', 'January']
 *
 * nameVersion = 'short' returns ['Oct', 'Nov', 'Dec', 'Jan']
 *
 */
export function getMonthNames(
  monthStartOffset = 0,
  monthEndOffset = 0,
  nameVersion = "long"
) {
  if (monthStartOffset === monthEndOffset) {
    return [getMonthNameRelativeToToday(monthStartOffset)];
  }

  let list = [];
  for (let i = monthStartOffset; i < monthEndOffset + 1; i++) {
    list.push(getMonthNameRelativeToToday(i, nameVersion));
  }

  return list;
}

/**
 * Returns list of iso strings of corresponding month relative to today from monthStartOffset until monthEndOffset.
 *
 * If today is 2.12.2021, it will return for monthStartOffset = - 2 and monthEndOffset = 1
 * ['2021-10', '2021-11', '2021-12', '2022-01']
 *
 */
export function getISOMonths(monthStartOffset = 0, monthEndOffset = 0) {
  if (monthStartOffset === monthEndOffset) {
    return [getISOMonthRelativeToToday(monthStartOffset)];
  }

  let list = [];
  for (let i = monthStartOffset; i < monthEndOffset + 1; i++) {
    list.push(getISOMonthRelativeToToday(i));
  }

  return list;
}

/**
 * Returns name of a month
 * e.g. monthOffset of -1 would be last month
 * So if today is the 02.12.2012, it will return 'November'
 */
export function getMonthNameRelativeToToday(monthOffset, nameVersion = "long") {
  let date = getFirstOfMonthRelativeToTodayAsUTC(monthOffset);
  return date.toLocaleString("en-US", { month: nameVersion, timeZone: "UTC" });
}

export function getMonthNameFromISODateString(
  isoDateString,
  nameVersion = "long"
) {
  return new Date(isoDateString).toLocaleString("en-US", {
    month: nameVersion,
    timeZone: "UTC",
  });
}

/**
 * Return month in format YYYY-MM relative to today with an monthOffset.
 * e.g. monthOffset of -1 would be last month
 */
export function getISOMonthRelativeToToday(monthOffset) {
  // + 1 , since javascript counts month starting with 0
  let date = getFirstOfMonthRelativeToTodayAsUTC(monthOffset);
  // Here UTC date is important, since toISOString uses always UTC
  return date.toISOString().substring(0, 7);
}

function getFirstOfMonthRelativeToTodayAsUTC(monthOffset) {
  let date = new Date();
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth() + monthOffset, 1)
  );
}

/**
 * Return day in format YYYY-MM-DD relative to today with an dayOffset.
 * e.g. dayOffset of -1 would be yesterday
 */
export function getISODayRelativeToToday(dayOffset) {
  let date = new Date();
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + dayOffset)
  );
  // Here UTC date is important, since toISOString uses always UTC
  return date.toISOString().substring(0, 10);
}

export function convertISOToLocal(dateString) {
  return new Date(dateString).toLocaleString([], {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

/*
 * Changes date from string YYYY-MM to format MM/YYYY
 * Date separator can be changed
 */

export const changeDateFormat = (date, separator) => {
  const year = date.substring(0, 4);
  const month = date.substring(5);
  return `${month}${separator}${year}`;
};

export function calculateMonthOffset(numberOfMonth) {
  return -(numberOfMonth - 1);
}

export function filterEntriesWithDateBetween(
  array,
  startMonthOffset,
  endMonthOffset,
  dateAttribute = "date"
) {
  const start = getISOMonthRelativeToToday(startMonthOffset);
  const end = getISOMonthRelativeToToday(endMonthOffset);
  return array.filter(
    (entry) => start <= entry[dateAttribute] && entry[dateAttribute] <= end
  );
}
