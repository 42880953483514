import PropTypes from "prop-types";
import React from "react";
import { convertCurrencySymbol } from "../modules/costs/CostsUtil";
import BtcExchangeRateInfoSymbolWithTooltip from "./BtcExchangeRateInfoSymbolWithTooltip";

BtcCostWithTooltip.propTypes = {
  costsV2: PropTypes.object,
};

function CostDiv({ costs, currency, children }) {
  return (
    <>
      {costs}&nbsp;{convertCurrencySymbol(currency)} {children}
    </>
  );
}

export default function BtcCostWithTooltip({ costsV2 }) {
  return (
    <CostDiv costs={costsV2.costs} currency={costsV2.currency}>
      {costsV2.exchangeRate && (
        <BtcExchangeRateInfoSymbolWithTooltip costsV2={costsV2} />
      )}
    </CostDiv>
  );
}
