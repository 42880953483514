import React from "react";
import { Card } from "reactstrap";

function BtcCard({
  children,
  className = "card-stats",
  label,
  twoCardCells = false,
}) {
  return (
    <section
      aria-label={label}
      className={
        "btc-card btc-card--aspect-ratio" +
        (twoCardCells ? " btc-two-card-cells" : "")
      }
    >
      <Card className={className}>{children}</Card>
    </section>
  );
}

export default BtcCard;
