import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.tableLayouts = {
  defaultLayout: {
    hLineWidth: function (i, node) {
      if (i === 0 || i === node.table.body.length) {
        return 0;
      }
      return i === node.table.headerRows ? 2 : 1;
    },
    vLineWidth: function (i) {
      return 0;
    },
    hLineColor: function (i) {
      return i === 1 ? "black" : "#aaa";
    },
    paddingLeft: function (i) {
      return i === 0 ? 0 : 8;
    },
    paddingRight: function (i, node) {
      return i === node.table.widths.length - 1 ? 0 : 8;
    },
    fillColor: function (rowIndex) {
      return rowIndex % 2 === 0 ? "white" : "#f8f8f8";
    },
  },
};

const prepareDataToExport = (data, dataset, dataToExport) => {
  let resultArray = [];
  data.forEach((dataItem) => {
    const tempArr = [];
    dataToExport.forEach((objectKey) => {
      tempArr.push(dataItem[objectKey]);
    });
    resultArray.push(tempArr);
  });
  resultArray.unshift(dataset);
  return resultArray;
};

export const extractFilteredData = (data) => {
  const filteredData = [];
  data.forEach((item) => filteredData.push(item.original));
  return filteredData;
};

export const exportTableXlsxCsv = (
  fileFormat,
  data,
  exportConfiguration,
  exportedFileName
) => {
  const { tableColumnsNames, dataToExport, columnsWidth, columnsWidthXls } =
    exportConfiguration;
  const workbook = XLSX.utils.book_new();
  const array = prepareDataToExport(data, tableColumnsNames, dataToExport);
  const worksheet = XLSX.utils.aoa_to_sheet(array);

  worksheet["!cols"] = columnsWidthXls || columnsWidth;

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const exportFileDate = new Date().toISOString().slice(0, 10);
  XLSX.writeFile(
    workbook,
    `${exportedFileName || "Report"}_${exportFileDate}.${fileFormat}`
  );
};

export const exportTablePDF = (
  data,
  exportConfiguration,
  pageOrientation,
  exportedFileName
) => {
  const { columnsWidth, tableColumnsNames, dataToExport } = exportConfiguration;
  const dataColumnNames = [...tableColumnsNames];
  const exportData = prepareDataToExport(data, dataColumnNames, dataToExport);

  const widthsArray = columnsWidth.map((item) => item.wch);

  const docDef = {
    pageSize: "A4",
    pageOrientation: pageOrientation,

    footer: function (currentPage, pageCount) {
      return {
        text: `Page ${currentPage}/${pageCount}`,
        alignment: "center",
      };
    },
    content: [
      {
        layout: "defaultLayout",
        table: {
          headerRows: 1,
          widths: widthsArray,
          body: exportData,
        },
      },
    ],
  };
  const exportFileDate = new Date().toISOString().slice(0, 10);
  pdfMake
    .createPdf(docDef)
    .download(`${exportedFileName || "Report"}_${exportFileDate}`);
};
