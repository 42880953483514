/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Col, Row } from "reactstrap";

import { CostsPlotCard } from "./CostsPlotCard";
import { CostsForecastPlotCard } from "./CostsForecastPlotCard";
import { CostsServiceCard } from "./CostsServiceCard";
import BudgetCard from "./BudgetCard";
import {
  fetchBudgetOfLastDays,
  fetchCostAnomalies,
  fetchServiceCosts,
} from "./costsResource";
import { UserContext } from "../../utils/userContext";
import NotificationAlert from "react-notification-alert";
import BudgetPlotCard from "./BudgetPlotCard";
import { shouldBeVisible, shouldItemBeVisible } from "../../utils/accountUtil";
import { useAppContext } from "../../utils/contextLib";
import { CostsBreakdownPlotCard } from "./CostsBreakdownPlotCard";
import { CostsBreakdownCard } from "./CostsBreakdownCard";
import CostAnomaliesOverviewCard from "./CostAnomaliesOverviewCard";
import CostAnomaliesCard from "./CostAnomaliesCard";
import { buildMapGroupedByKey } from "../../utils/groupByUtil";

const { DateTime } = require("luxon");

function Costs() {
  const { fetchData } = useAppContext();
  const { user, activePermission, activeAccountIds } = useContext(UserContext);
  const notifyRef = useRef(null);

  const [fetchedBudgets, setFetchedBudgets] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [budgetError, setBudgetError] = useState(null);
  const [budgetLoading, setBudgetLoading] = useState(true);
  const [serviceCosts, setServiceCosts] = useState([]);
  const [serviceCostsError, setServiceCostsError] = useState(null);
  const [serviceCostsLoading, setServiceCostsLoading] = useState(true);
  const [selectedBudgetName, setSelectedBudgetName] = useState(null);
  const [
    isCostBreakdownCardForResourceVisible,
    setIsCostBreakdownCardForResourceVisible,
  ] = useState(false);
  const [
    isCostBreakdownCardForTagVisible,
    setIsCostBreakdownCardForTagVisible,
  ] = useState(false);
  const [isCostAnomaliesVisible, setIsCostAnomaliesVisible] = useState(false);
  const [anomaliesCostLoading, setAnomaliesCostLoading] = useState(true);
  const [anomaliesCostErrors, setAnomaliesCostErrors] = useState(null);
  const [unfilteredCostAnomalies, setUnfilteredCostAnomalies] = useState([]);
  const [costAnomalies, setCostAnomalies] = useState([]);
  const [activeHyperscalers, setActiveHyperscalers] = useState([]);

  const customerLogoContainer = document.getElementById("customerLogoImg");
  const isCustomerLogoVisible = customerLogoContainer?.innerHTML !== "";

  useEffect(() => {
    const company = activePermission["company"];
    fetchData(
      () => fetchServiceCosts(company),
      setServiceCosts,
      setServiceCostsError,
      setServiceCostsLoading,
      notifyRef
    );
  }, [activePermission, fetchData]);

  useEffect(() => {
    const company = activePermission["company"];
    fetchData(
      () => fetchBudgetOfLastDays(company, 7),
      setFetchedBudgets,
      setBudgetError,
      setBudgetLoading,
      notifyRef
    );
  }, [activePermission, fetchData]);

  useEffect(() => {
    if (!fetchedBudgets) {
      return;
    }
    let visibleBudgets = fetchedBudgets.filter((item) =>
      activeAccountIds.some((activeAccountId) =>
        item.accountIds.some((accountId) =>
          shouldBeVisible(item.hyperscaler, accountId, activeAccountId)
        )
      )
    );

    setBudgets(visibleBudgets);
    if (visibleBudgets.length > 0) {
      setSelectedBudgetName(visibleBudgets[0].budgetName);
    } else {
      setSelectedBudgetName(null);
    }
  }, [fetchedBudgets, activeAccountIds]);

  const handleShowDetails = (module) => {
    switch (module) {
      case "resource":
        setIsCostBreakdownCardForResourceVisible((prevState) => !prevState);
        setIsCostBreakdownCardForTagVisible(false);
        setIsCostAnomaliesVisible(false);
        break;
      case "tag":
        setIsCostBreakdownCardForTagVisible((prevState) => !prevState);
        setIsCostBreakdownCardForResourceVisible(false);
        setIsCostAnomaliesVisible(false);
        break;
      case "anomalies":
        setIsCostAnomaliesVisible((prevState) => !prevState);
        setIsCostBreakdownCardForResourceVisible(false);
        setIsCostBreakdownCardForTagVisible(false);
        break;
      default:
        break;
    }
  };

  const switchCostBreakdownForResourceVisibility = () => {
    handleShowDetails("resource");
  };

  const switchCostBreakdownForTagVisibility = () => {
    handleShowDetails("tag");
  };

  const extractSelectedHyperscalers = useCallback(() => {
    const selectedAccount = activeAccountIds;
    const company = activePermission["company"];
    const activeHyperscalersArr = [];

    const userAccounts = user.accessPermissions.filter(
      (singleAccessPermission) => singleAccessPermission.company === company
    )[0].companyAccListObj;

    const activeHyperscallers = selectedAccount.flatMap((selectedAccount) =>
      userAccounts.filter(
        (userAccount) => userAccount.accountId === selectedAccount
      )
    );

    activeHyperscallers.forEach((activeHyperscaler) => {
      if (!activeHyperscalersArr.includes(activeHyperscaler.hyperscaler)) {
        activeHyperscalersArr.push(activeHyperscaler.hyperscaler || "all");
      }
    });

    setActiveHyperscalers(activeHyperscalersArr);
  }, [activePermission, user.accessPermissions, activeAccountIds]);

  useEffect(() => {
    const company = activePermission["company"];
    const date = DateTime.now();
    const startMonth = date.minus({ month: 3 }).toFormat("yyyy-LL-dd");
    fetchData(
      () => fetchCostAnomalies(startMonth, company),
      setUnfilteredCostAnomalies,
      setAnomaliesCostErrors,
      setAnomaliesCostLoading,
      notifyRef
    );
  }, [activePermission, fetchData]);

  useEffect(() => {
    const costs = unfilteredCostAnomalies.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );

    const getAnomalyId = (item) => {
      return item.anomalyId;
    };

    const dataGroupedByAnomalyId = buildMapGroupedByKey(costs, getAnomalyId);

    const outPutData = [];

    for (let value of dataGroupedByAnomalyId.values()) {
      let outServices = [];
      let outAccountsId = [];
      value.forEach((item) => {
        if (!outServices.includes(item.service)) {
          outServices.push(item.service);
        }

        if (!outAccountsId.includes(item.accountId)) {
          outAccountsId.push(item.accountId);
        }
      });

      const outObj = value[0];
      outObj.service = outServices.join(", ");
      outObj.accountId = outAccountsId.join(", ");
      outPutData.push(outObj);
    }

    setCostAnomalies(outPutData);

    extractSelectedHyperscalers();
  }, [activeAccountIds, extractSelectedHyperscalers, unfilteredCostAnomalies]);

  const switchCostAnomaliesVisability = () => {
    handleShowDetails("anomalies");
  };

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <section
        aria-labelledby="costs-module-page-heading"
        className={`content ${
          isCustomerLogoVisible ? "content-with-customer-logo" : ""
        }`}
      >
        <h2 id="costs-module-page-heading" className="visually-hidden">
          Costs
        </h2>
        <Row>
          <Col xl="3" lg="4" md="6" sm="6">
            <CostsPlotCard />
          </Col>
          {activePermission.extended && (
            <Col xl="3" lg="4" md="6" sm="6">
              <CostsForecastPlotCard />
            </Col>
          )}
          {activePermission.extended && (
            <>
              <Col xl="3" lg="4" md="6" sm="6">
                <CostsBreakdownPlotCard
                  serviceCosts={serviceCosts}
                  error={serviceCostsError}
                  loading={serviceCostsLoading}
                  switchDetailsHandler={
                    switchCostBreakdownForResourceVisibility
                  }
                  isCostBreakdownCardVisible={
                    isCostBreakdownCardForResourceVisible
                  }
                  categoryAttribute="resource"
                  categoryLabel="resource"
                />
              </Col>
              <Col xl="3" lg="4" md="6" sm="6">
                <CostsBreakdownPlotCard
                  serviceCosts={serviceCosts}
                  error={serviceCostsError}
                  loading={serviceCostsLoading}
                  switchDetailsHandler={switchCostBreakdownForTagVisibility}
                  isCostBreakdownCardVisible={isCostBreakdownCardForTagVisible}
                  categoryAttribute="tags"
                  categoryLabel="tag"
                />
              </Col>
            </>
          )}
          <Col xl="3" lg="4" md="6" sm="6">
            <BudgetPlotCard
              budgets={budgets}
              budgetError={budgetError}
              loading={budgetLoading}
              selectedBudgetName={selectedBudgetName}
            />
          </Col>
          {activePermission.extended && (
            <Col xl="3" lg="4" md="6" sm="6">
              <CostAnomaliesOverviewCard
                switchDetailsHandler={switchCostAnomaliesVisability}
                isCostAnamaliesCardVisible={isCostAnomaliesVisible}
                loading={anomaliesCostLoading}
                anomaliesCostErrors={anomaliesCostErrors}
                costAnomaliesData={costAnomalies}
                activeHyperScalers={activeHyperscalers}
              />
            </Col>
          )}
        </Row>
        {activePermission.extended && (
          <>
            <Row>
              <Col>
                {isCostAnomaliesVisible && (
                  <CostAnomaliesCard
                    loading={anomaliesCostLoading}
                    anomaliesCostErrors={anomaliesCostErrors}
                    costAnomaliesData={costAnomalies}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {isCostBreakdownCardForResourceVisible && (
                  <CostsBreakdownCard
                    serviceCosts={serviceCosts}
                    error={serviceCostsError}
                    loading={serviceCostsLoading}
                    switchDetailsHandler={
                      switchCostBreakdownForResourceVisibility
                    }
                    categoryAttribute="resource"
                    categoryLabel="resource"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {isCostBreakdownCardForTagVisible && (
                  <CostsBreakdownCard
                    serviceCosts={serviceCosts}
                    error={serviceCostsError}
                    loading={serviceCostsLoading}
                    switchDetailsHandler={switchCostBreakdownForTagVisibility}
                    categoryAttribute="tags"
                    categoryLabel="tag"
                  />
                )}
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <BudgetCard
              budgets={budgets}
              budgetError={budgetError}
              loading={budgetLoading}
              selectedBudgetName={selectedBudgetName}
              onSelectBudgetName={(budget) => setSelectedBudgetName(budget)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CostsServiceCard
              serviceCosts={serviceCosts}
              error={serviceCostsError}
              loading={serviceCostsLoading}
            />
          </Col>
        </Row>
      </section>
    </>
  );
}

export default Costs;
