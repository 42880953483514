import React, { useContext } from "react";
import { putSearchParams } from "../utils/urlUtil";
import { UserContext } from "../utils/userContext";
import { Link } from "react-router-dom";

function BtcNavLink({ to, className = "", children, disabled = false }) {
  const { activePermission, activeAccountIds } = useContext(UserContext);

  return (
    <Link
      to={putSearchParams(to, activePermission, activeAccountIds)}
      className={className}
      disabled={disabled}
    >
      {children}
    </Link>
  );
}

export default BtcNavLink;
