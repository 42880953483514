import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import CompanyEditModules from "./CompanyEditModules";
import AccountsTableCard from "../accounts/AccountsTableCard";
import CompanyEditParamsCard from "./CompanyEditParamsCard";
import { useLocation } from "react-router-dom";
import { fetchCompanyAccounts } from "../adminResource";
import { useAppContext } from "../../../utils/contextLib";
import BtcLink from "../../../components/BtcLink";

CompanyEditPage.propTypes = {
  notifyRef: PropTypes.object,
};

function CompanyEditPage({ notifyRef }) {
  const { fetchData } = useAppContext();
  const location = useLocation();
  const [error, setError] = useState();
  const [accounts, setAccounts] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState(true);

  let pathParts = location.pathname.split("/");
  let companyName = pathParts.pop();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData(
      () => fetchCompanyAccounts(companyName),
      (res) => {
        setAccounts(res.awsAccounts);
        setSubscriptions(res.azureSubscriptions);
      },
      setError,
      setAccountsLoading,
      notifyRef
    );
  }, [notifyRef, companyName, fetchData]);

  return (
    <section
      aria-labelledby="company-edit-module-page-heading"
      className="content"
    >
      <h2 id="company-edit-module-page-heading" className="visually-hidden">
        Company Edit Page{" "}
      </h2>
      <Row>
        <Col lg={{ offset: 0, size: 12 }} xl={{ offset: 2, size: 8 }}>
          <AccountsTableCard
            companyName={companyName}
            accounts={accounts}
            error={error}
            loading={accountsLoading}
            notifyRef={notifyRef}
            hyperscaler={"aws"}
            hyperscalerLabel={"AWS"}
            editLink={"/admin/account-edit"}
            createLink={"/admin/account-create"}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={{ offset: 0, size: 12 }} xl={{ offset: 2, size: 8 }}>
          <AccountsTableCard
            companyName={companyName}
            accounts={subscriptions}
            error={error}
            loading={accountsLoading}
            notifyRef={notifyRef}
            hyperscaler={"azure"}
            hyperscalerLabel={"Azure"}
            editLink={"/admin/subscription-edit"}
            createLink={"/admin/subscription-create"}
          />
        </Col>
      </Row>
      <CompanyEditModules notifyRef={notifyRef} companyName={companyName} />
      <Row>
        <Col lg={{ offset: 0, size: 12 }} xl={{ offset: 2, size: 8 }}>
          <CompanyEditParamsCard
            notifyRef={notifyRef}
            companyName={companyName}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={{ offset: 0, size: 12 }} xl={{ offset: 2, size: 8 }}>
          <BtcLink
            className="btn btn-success btn--no-margin card-shadow pull-right mr-3"
            to="/admin"
          >
            Cancel
          </BtcLink>
        </Col>
      </Row>
    </section>
  );
}

export default CompanyEditPage;
