const { convertRemToPixels } = require("../../../utils/pixelUtil");
const {
  generateLinePlotDataset,
  generateLinePlotOption,
} = require("../../../utils/plotUtil");

function generateCostGraphOptions() {
  let option = generateLinePlotOption();
  option.plugins.tooltip.callbacks = {
    label: (item) => ` ${item.formattedValue} €`,
  };
  return option;
}

function generateOptionsForBudget(limit) {
  let costGraphOptions = generateCostGraphOptions();
  if (limit) {
    costGraphOptions.plugins.autocolors = false;
    costGraphOptions.plugins.annotation = {
      annotations: {
        line1: {
          type: "line",
          yMin: limit,
          yMax: limit,
          borderColor: "#de1f16",
          borderWidth: convertRemToPixels(0.1875),
          borderDash: [convertRemToPixels(0.5), convertRemToPixels(0.25)],
        },
      },
    };
  }

  return costGraphOptions;
}

function generateCostDataset(data = [], segment = {}) {
  return generateLinePlotDataset(data, "Costs in Euro", "#0076de", segment);
}

function generateCostPreset() {
  return {
    labels: [],
    datasets: [generateCostDataset()],
  };
}

module.exports = {
  generateCostDataset,
  generateCostPreset,
  generateCostGraphOptions,
  generateOptionsForBudget,
};
