import React from "react";
import BtcAzureLogo from "./BtcAzureLogo";
import PropTypes from "prop-types";

BtcHyperscalerLogo.propTypes = {
  hyperscaler: PropTypes.string,
};

function BtcHyperscalerLogo({ hyperscaler }) {
  switch (hyperscaler) {
    case "aws":
      return <i className="fab fa-aws" />;
    case "azure":
      return <BtcAzureLogo />;
    default:
      return "";
  }
}

export default BtcHyperscalerLogo;
