import React from "react";
import PropTypes from "prop-types";

FormError.propTypes = {
  error: PropTypes.object,
};

function FormError({ error }) {
  return error ? (
    <span className="error" role="alert">
      {error.message}
    </span>
  ) : null;
}

export default FormError;
