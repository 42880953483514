import { CardBody, CardFooter } from "reactstrap";
import BtcCardHeader from "../../components/BtcCardHeader";
import React, { useContext, useEffect, useRef, useState } from "react";
import { fetchUserRoles } from "./iamResource";
import { UserContext } from "../../utils/userContext";
import { shouldItemBeVisible } from "../../utils/accountUtil";
import { getMaxProperty } from "../../utils/arrayUtil";
import { Pie } from "react-chartjs-2";
import BtcCard from "../../components/BtcCard";
import {
  getRoleName,
  getUniqueUserList,
  prepareLabelsAndData,
  prepareRoleShortList,
} from "./plotRolesUtil";
import { useAppContext } from "../../utils/contextLib";

const prepareRolesDataForChart = (rolesData) => {
  const uniqueUsers = getUniqueUserList(rolesData);

  const roleList = {};
  for (const userRole of Object.values(uniqueUsers)) {
    userRole.forEach((roleItem) => {
      const currentRole = getRoleName(roleItem);
      if (roleList[currentRole]) {
        roleList[currentRole].amount++;
      } else {
        roleList[currentRole] = {
          amount: 1,
        };
      }
    });
  }

  const roleShortList = prepareRoleShortList(roleList);
  const chartData = prepareLabelsAndData(roleShortList);
  return buildChartSetup(chartData);
};

const buildChartSetup = (chartData) => {
  return {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.data,
        borderWidth: 0,
        backgroundColor: [
          "rgb(0, 56, 105)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
          "rgb(148, 201, 169)",
          "rgb(218, 245, 255)",
          "rgb(19, 38, 47)",
          "rgb(14, 71, 73)",
          "rgb(165, 181, 191)",
          "rgb(127, 85, 125)",
          "rgb(243, 131, 117)",
        ],
      },
    ],
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
  };
};

export const IamOverviewPlotCard = () => {
  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const notifyRef = useRef(null);
  const [busy, setBusy] = useState(true);
  const [error, setError] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [fetchedUserRoles, setFetchedUserRoles] = useState(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const company = activePermission["company"];
    fetchData(
      () => fetchUserRoles(company, new Date()),
      (userRoles) => {
        const plotData = prepareRolesDataForChart(userRoles);
        setChartData(plotData);
        setFetchedUserRoles(userRoles);
      },
      setError,
      setBusy,
      notifyRef
    );
  }, [activePermission, fetchData]);

  useEffect(() => {
    if (!fetchedUserRoles) {
      return;
    }

    let userRoleItems = fetchedUserRoles.filter((item) =>
      shouldItemBeVisible(item, activeAccountIds)
    );

    const plotData = prepareRolesDataForChart(userRoleItems);
    setChartData(plotData);

    setUpdatedAt(getMaxProperty(fetchedUserRoles, "updatedAt"));
  }, [fetchedUserRoles, activeAccountIds]);

  return (
    <BtcCard label="Iam" className="card-stats">
      <BtcCardHeader
        title="Unique IAM users"
        icon="far fa-id-badge fa-fix"
        error={error}
        updatedAt={updatedAt}
        loading={busy}
        url="/iam"
      />
      <CardBody>
        {chartData && (
          <Pie data={chartData} options={chartData.options} type="pie" />
        )}
      </CardBody>
      <CardFooter />
    </BtcCard>
  );
};
