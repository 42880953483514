export function convertMapToValueArrayOrderedByKey(map) {
  return [...map.entries()].sort().map((entry) => entry[1]);
}

export function convertMapToEntryArrayOrderedByKey(
  map,
  supplier = (key, value) => ({
    key: key,
    value: value,
  })
) {
  return [...map.entries()].sort().map((entry) => supplier(entry[0], entry[1]));
}
