import React, { useContext, useEffect, useRef, useState } from "react";
import { CardFooter, Col, Row } from "reactstrap";
import { UserContext } from "../../utils/userContext";
import * as DateUtil from "../../utils/dateUtil";
import {
  calculateMonthOffset,
  filterEntriesWithDateBetween,
  getISOMonths,
} from "../../utils/dateUtil";
import NotificationAlert from "react-notification-alert";
import CustomErrorIcon from "../../components/CustomErrorIcon";
import BtcCard from "../../components/BtcCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import { getMaxProperty } from "../../utils/arrayUtil";
import { useAppContext } from "../../utils/contextLib";
import BtcMonthDropdown from "../../components/BtcMonthDropdown";
import BtcStretchedContentCardBody from "../../components/BtcStretchedContentCardBody";
import { fetchAlertCount } from "./alertResource";
import { Line } from "react-chartjs-2";
import {
  generateLinePlotDataset,
  generateLinePlotOption,
} from "../../utils/plotUtil";
import {
  buildMapGroupedByKey,
  groupByKeyAndBuildSum,
} from "../../utils/groupByUtil";
import { convertMapToValueArrayOrderedByKey } from "../../utils/sortUtil";
import { filterByActiveAccount } from "../../utils/accountUtil";

export const EventHistoryPlotCard = () => {
  const [monthOptions] = useState([6, 12]);

  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const notifyRef = useRef(null);

  const [busy, setBusy] = useState(true);
  const [numberOfMonth, setNumberOfMonth] = useState(6);
  const [error, setError] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [alertCount, setAlertCount] = useState();

  const [datasets, setDatasets] = useState(generateEventHistoryDatasets());
  const [labels, setLabels] = useState(generateMonthNames(numberOfMonth));
  const [options] = useState(generateEventHistoryOptions());

  useEffect(() => {
    const maxStartMonthOffset = calculateMonthOffset(
      monthOptions[monthOptions.length - 1]
    );
    fetchData(
      () => fetchAlertCount(activePermission["company"], maxStartMonthOffset),
      (alertCount) => {
        setAlertCount(alertCount);
        setUpdatedAt(getMaxProperty(alertCount, "updatedAt"));
      },
      setError,
      setBusy,
      notifyRef
    );
  }, [monthOptions, activePermission, fetchData]);

  useEffect(() => {
    let monthNames = generateMonthNames(numberOfMonth);
    setLabels(monthNames);
  }, [numberOfMonth]);

  useEffect(() => {
    if (!alertCount) {
      return;
    }

    let filteredAccountCount = filterByActiveAccount(
      alertCount,
      activeAccountIds
    );
    const startMonthOffset = calculateMonthOffset(numberOfMonth);
    filteredAccountCount = filterEntriesWithDateBetween(
      filteredAccountCount,
      startMonthOffset,
      0
    );
    const alertCountBySeverity = buildMapGroupedByKey(
      filteredAccountCount,
      (entry) => entry.severity
    );

    const lowData = buildListOrderedByDate(
      alertCountBySeverity.get("LOW") || [],
      startMonthOffset
    );
    const mediumData = buildListOrderedByDate(
      alertCountBySeverity.get("MEDIUM") || [],
      startMonthOffset
    );
    const highData = buildListOrderedByDate(
      alertCountBySeverity.get("HIGH") || [],
      startMonthOffset
    );

    const datasets = generateEventHistoryDatasets(
      lowData,
      mediumData,
      highData
    );

    setDatasets(datasets);
  }, [alertCount, numberOfMonth, activeAccountIds]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcCard label="Event History">
        <BtcCardHeader
          title="Event History"
          updatedAt={updatedAt}
          icon="fas fa-shield-alt"
          error={error}
          loading={busy}
        />
        <BtcStretchedContentCardBody>
          <Line
            data={{
              labels: labels,
              datasets: datasets,
            }}
            options={options}
          />
        </BtcStretchedContentCardBody>
        <CardFooter>
          <hr />
          <Row>
            <Col xs="4">
              {error && (
                <CustomErrorIcon
                  className="btc-small-card-error"
                  error={error}
                />
              )}
            </Col>
            <Col xs="8">
              <BtcMonthDropdown
                className="pull-right"
                numberOfMonth={numberOfMonth}
                setNumberOfMonth={setNumberOfMonth}
              />
            </Col>
          </Row>
        </CardFooter>
      </BtcCard>
    </>
  );
};

function generateMonthNames(numberOfMonth) {
  const startMonthOffset = calculateMonthOffset(numberOfMonth);
  return DateUtil.getMonthNames(startMonthOffset, 0);
}

function buildListOrderedByDate(eventCountList, startMonthOffset) {
  const countMap = groupByKeyAndBuildSum(
    eventCountList,
    (e) => e.date,
    (e) => parseInt(e.count)
  );
  setMonthToZeroIfNoDataAvailable(countMap, startMonthOffset);
  return convertMapToValueArrayOrderedByKey(countMap);
}

function setMonthToZeroIfNoDataAvailable(countMap, startMonthOffset) {
  const months = getISOMonths(startMonthOffset, 0);

  for (let month of months) {
    if (!countMap.has(month)) {
      countMap.set(month, 0);
    }
  }
}

function generateEventHistoryDatasets(
  lowData = [],
  mediumData = [],
  highData = []
) {
  const lowDataset = generateLinePlotDataset(lowData, "Low", "#28A745FF");
  const mediumDataset = generateLinePlotDataset(
    mediumData,
    "Medium",
    "#eca202"
  );
  const highDataset = generateLinePlotDataset(highData, "High", "#de1f16");
  return [lowDataset, mediumDataset, highDataset];
}

function generateEventHistoryOptions() {
  return generateLinePlotOption();
}
