import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Label,
  Row,
} from "reactstrap";
import BtcCardHeader from "../../../components/BtcCardHeader";
import { useForm } from "react-hook-form";
import { capitalizeFirstLetter } from "../../../utils/stringUtil";
import Select from "react-select";
import {
  fetchCompanyModulesForRole,
  fetchModules,
  putCompanyRoleModules,
} from "../adminResource";
import { notifyError, notifySuccess } from "../../../utils/notification";
import { useAppContext } from "../../../utils/contextLib";

CompanyEditRoleModulesCard.propTypes = {
  notifyRef: PropTypes.object,
  role: PropTypes.string,
};

function CompanyEditRoleModulesCard({ notifyRef, companyName, role }) {
  const { fetchMultipleData } = useAppContext();
  const [backendCall, setBackendCall] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [error, setError] = useState();

  const [selectedModule, setSelectedModule] = useState();
  const [moduleOptions, setModuleOptions] = useState([]);
  const [allModuleOptions, setAllModuleOptions] = useState([]);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      modules: [],
    },
  });

  const watchedModules = watch("modules");

  useEffect(() => {
    const companyModulesPromise = fetchCompanyModulesForRole(
      companyName,
      role
    ).then((modules) => {
      reset({ modules: modules });
    });
    const modulesPromise = fetchModules().then((modules) => {
      if (companyName === "SYSADMIN") {
        setAllModuleOptions(modules);
      } else {
        setAllModuleOptions(modules.filter((module) => module !== "admin"));
      }
    });
    fetchMultipleData(
      [companyModulesPromise, modulesPromise],
      setError,
      setOptionsLoading,
      notifyRef
    );
  }, [companyName, notifyRef, reset, role, fetchMultipleData]);

  useEffect(() => {
    if (!watchedModules) {
      return;
    }
    setModuleOptions(
      allModuleOptions.filter((option) => !watchedModules.includes(option))
    );
  }, [watchedModules, allModuleOptions]);

  function onSubmit(data) {
    setBackendCall(true);
    putCompanyRoleModules(companyName, role, data.modules)
      .then(() => {
        notifySuccess(notifyRef, `Update of ${role} modules was successful.`);
      })
      .catch((e) => notifyError(notifyRef, e))
      .finally(() => {
        setBackendCall(false);
        reset(data); // to remove dirty state
      });
  }

  function addModuleTag(selectedModule) {
    setModules([...watchedModules, selectedModule.value]);
    setSelectedModule(null);
  }

  function removeModuleTag(module) {
    setModules(watchedModules.filter((a) => a !== module));
  }

  function setModules(modules) {
    setValue("modules", modules, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <BtcCardHeader
          title={"Visible " + capitalizeFirstLetter(role) + " Modules"}
          error={error}
          loading={optionsLoading}
        />
        <CardBody className="px-5 pb-4">
          <p>
            This setting controls which modules are visible in the UI by{" "}
            {role.toLowerCase()} users.
          </p>
          <p>
            <b>Important:</b> It does NOT control which data is accessible via
            API, so it is no security feature!
          </p>
          <div className="btc-tag-edit">
            <div className="btc-tag-edit__elements d-flex flex-wrap btc-gap">
              {(watchedModules || []).map((module, key) => (
                <span
                  className="badge badge-primary"
                  key={"btc-tag-edit-" + module + key}
                >
                  {module}
                  <button
                    type="button"
                    className="btn badge-close"
                    aria-label="Remove"
                    disabled={backendCall || optionsLoading}
                    onClick={() => {
                      removeModuleTag(module);
                    }}
                  >
                    <i className="fas fa-times" />
                  </button>
                </span>
              ))}
            </div>
            <Row className="mt-4">
              <Col sm="6">
                <Label for="module" className="visually-hidden">
                  Module
                </Label>
                <Select
                  id="module"
                  placeholder="Select Module..."
                  value={selectedModule}
                  isDisabled={backendCall || optionsLoading}
                  onChange={(selection) => setSelectedModule(selection)}
                  options={moduleOptions.map((company) => {
                    return {
                      value: company,
                      label: company,
                    };
                  })}
                />
              </Col>
              <Col sm="2">
                <Button
                  className={"btn--no-margin pull-right btn-round"}
                  onClick={() => addModuleTag(selectedModule)}
                  disabled={!selectedModule || backendCall || optionsLoading}
                >
                  <i className="fas fa-plus" />
                </Button>
              </Col>
            </Row>
          </div>
        </CardBody>
        <CardFooter>
          <input
            className="btn btn-success pull-right"
            type="submit"
            value={"Save"}
            disabled={backendCall || optionsLoading || !isDirty || error}
          />
        </CardFooter>
      </Card>
    </Form>
  );
}

export default CompanyEditRoleModulesCard;
