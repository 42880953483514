import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

ConfirmDialog.propTypes = {
  text: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

function ConfirmDialog({ children, show, onConfirm, onCancel }) {
  return (
    <Modal isOpen={show} toggle={onCancel}>
      <ModalHeader>Are you sure?</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter className="right-side">
        <Button color="danger" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="success" onClick={onConfirm}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmDialog;
