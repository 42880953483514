import { API, graphqlOperation } from "aws-amplify";
import { parseAndThrowErrors } from "../../errors/GraphQlError";
import { UnexpectedError } from "../../errors/UnexpectedError";

export async function fetchTickets(company, date) {
  const dateAsString = date.toISOString().substring(0, 10);
  const getTickets = `query {
      getJiraTicketsByMonth(input: {
          date: "${dateAsString}",
          tenant: "${company}",
        }) {
          items{
            jiraUrl
            updatedAt
            incidents
            openRequests
            inProgress
            done30d
          }
        }
      }
      `;
  let response;
  try {
    response = await API.graphql(graphqlOperation(getTickets));
  } catch (e) {
    parseAndThrowErrors(e, `jira tickets of ${company}`);
  }

  return parseResponseData(response);
}

function parseResponseData(response) {
  let tickets = response.data.getJiraTicketsByMonth;
  if (tickets.items == null || tickets.items.length === 0) {
    throw new UnexpectedError("No jira tickets are defined");
  }
  const items = tickets.items[0];
  return {
    incidents: items.incidents,
    openRequests: items.openRequests,
    inProgress: items.inProgress,
    done30d: items.done30d,
    updatedAt: tickets.items[0].updatedAt,
    jiraUrl: tickets.items[0].jiraUrl,
  };
}
