import logo from "assets/img/BTC-logo.png";
import { Auth } from "aws-amplify";
import React, { useRef, useState } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { useAppContext } from "../utils/contextLib";
import { onError } from "./errorLib";
import { MfaCodeForm } from "./MfaCodeForm";
import { LoginForm } from "./LoginForm";

export default function Login() {
  const {
    userHasAuthenticated,
    setMfaSetupProcessStarted,
    setChangePasswordProcessStarted,
    cognitoUser,
    setCognitoUser,
  } = useAppContext();
  let history = useHistory();
  const notifyRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [step, setStep] = useState("loginFormStep1");

  async function handleSubmitLogin(userData) {
    setIsLoading(true);
    try {
      const user = await Auth.signIn(userData.email, userData.password);
      setCognitoUser(user);
      // Check if there is a Challenge
      if (user.challengeName !== void 0) {
        // Password has to be changed
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setChangePasswordProcessStarted(true);
          history.push({
            pathname: "/changepassword",
            state: { email: userData.email },
          });
        }
        if (user.challengeName === "MFA_SETUP") {
          setMfaSetupProcessStarted(true);
          history.push({
            pathname: "/mfasetup",
            state: {
              email: userData.email,
            },
          });
        }
        if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
          const mfaSetUp = { user: user?.username, mfaStatus: true };
          localStorage.setItem("mfaSetUp", JSON.stringify(mfaSetUp));
          setStep("mfaCodeFormStep2");
        }
        userHasAuthenticated(false);
      } else {
        const mfaSetUp = { user: cognitoUser?.username, mfaStatus: false };
        localStorage.setItem("mfaSetUp", JSON.stringify(mfaSetUp));
        userHasAuthenticated(true);
        setMfaSetupProcessStarted(false);
        setChangePasswordProcessStarted(false);
        history.push({ pathname: "/dashboard" });
      }
    } catch (e) {
      onError(e, notifyRef);
      userHasAuthenticated(false);
      setIsLoading(false);
      setMfaSetupProcessStarted(false);
      setChangePasswordProcessStarted(false);
    }
  }

  async function handleForgotPassword() {
    setIsLoading(true);

    history.push({
      pathname: "/resetpassword",
    });

    setIsLoading(false);
  }

  async function handleLoginMFACode(authenticationCode) {
    try {
      await Auth.confirmSignIn(
        cognitoUser,
        authenticationCode.mfaCode,
        "SOFTWARE_TOKEN_MFA"
      ).then(() => {
        setMfaSetupProcessStarted(false);
      });

      userHasAuthenticated(true);
      history.push({ pathname: "/dashboard" });
    } catch (e) {
      userHasAuthenticated(false);
      console.log(e);
      document.getElementsByName("mfaCode")[0].value = "";
      setStep("loginFormStep1");
      onError(
        "Your authentication information is incorrect. Please try again.",
        notifyRef
      );
      setIsLoading(false);
    }
  }

  return (
    <div className="content">
      <Row>
        <Col sm="3" md="4" xl="5" />
        <Col sm="6" md="4" xl="2">
          <div className="login-logo">
            <a
              href="http://www.btc-ag.com"
              target="blank"
              className="logo-normal"
            >
              <img src={logo} alt="BTC-AG" />
            </a>
            <p>BTC Utility Cloud Dashboard</p>
          </div>
        </Col>
        <Col sm="3" md="4" xl="5" />
      </Row>
      <Row>
        <Col sm="3" md="4" xl="5" />
        <Col sm="6" md="4" xl="2">
          <NotificationAlert ref={notifyRef} />
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Login</CardTitle>
            </CardHeader>
            <CardBody>
              {step === "loginFormStep1" ? (
                <LoginForm
                  handleLogin={handleSubmitLogin}
                  isLoading={isLoading}
                />
              ) : (
                <MfaCodeForm handleMfaCode={handleLoginMFACode} />
              )}
            </CardBody>
            {step === "loginFormStep1" && (
              <CardFooter className="text-center">
                <Button
                  className="btn btn-neutral p-0"
                  onClick={handleForgotPassword}
                >
                  Forgot Password?
                </Button>
              </CardFooter>
            )}
          </Card>
        </Col>
        <Col sm="3" md="4" xl="5" />
      </Row>
      <Row>
        <Col />
      </Row>
    </div>
  );
}
