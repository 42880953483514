import React from "react";
import { CardBody, CardFooter } from "reactstrap";
import { Pie } from "react-chartjs-2";
import { getMaxDate, getMinDate } from "./alertDateUtil";
import { getPieChartAlertLevel } from "./constants/guardDuty";
import PropTypes from "prop-types";
import BtcCard from "../../components/BtcCard";
import BtcCardHeader from "../../components/BtcCardHeader";

EventsByAlertLevelCard.propTypes = {
  alerts: PropTypes.array,
  alertsError: PropTypes.object,
  setTableFilterValue: PropTypes.func,
};

function EventsByAlertLevelCard({
  alerts,
  alertsError,
  setTableFilterValue,
  loading,
}) {
  let pieChartAlertLevel = getPieChartAlertLevel(setTableFilterValue, alerts);
  pieChartAlertLevel.data.datasets[0].data = [
    getCountAlertLevel(alerts, "Low"),
    getCountAlertLevel(alerts, "Medium"),
    getCountAlertLevel(alerts, "High"),
  ];

  function getCountAlertLevel(items, alertLevel) {
    return items.filter((item) => item.severity === alertLevel).length;
  }

  return (
    <>
      <BtcCard label="Security Events by Alert-Level">
        <BtcCardHeader
          title="Security Events"
          error={alertsError}
          description="grouped by Alert-Level"
          loading={loading}
        />
        <CardBody>
          <Pie
            data={pieChartAlertLevel.data}
            options={pieChartAlertLevel.options}
            type="pie"
          />
        </CardBody>
        <CardFooter>
          <div className="legend">
            <i className="fa fa-circle text-primary" />
            Total number of Events: {alerts.length}
          </div>
          <hr />
          <div className="stats">
            <i className="fa fa-calendar" />
            {getMinDate(alerts)} - {getMaxDate(alerts)}
          </div>
        </CardFooter>
      </BtcCard>
    </>
  );
}

export default EventsByAlertLevelCard;
