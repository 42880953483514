import * as yup from "yup";

yup.addMethod(yup.string, "onlyNumbers", function (msg) {
  return this.test("mfaCodeTest", msg, function (value) {
    const { createError } = this;
    return value.match(/^\d*$/g) || createError(msg);
  });
});

export const mfaCodeScheme = yup.object().shape({
  mfaCode: yup
    .string()
    .required("Authentication code is required")
    .onlyNumbers("Authentication code has no valid format")
    .max(6, "Authentication code must have length less or equal to 6"),
});
