import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody } from "reactstrap";
import BtcCardHeader from "../../../components/BtcCardHeader";
import { useHistory } from "react-router-dom";
import ReactTable from "../../../components/ReactTable";
import AccountBatchLoadModal from "./AWSAccountBatchLoadModal";
import { pushHistory } from "../../../utils/urlUtil";
import { notifyError, notifySuccess } from "../../../utils/notification";
import { UserContext } from "../../../utils/userContext";
import { loadAccountsInBatch } from "../adminResource";
import BtcLink from "../../../components/BtcLink";

AccountsTableCard.propTypes = {
  notifyRef: PropTypes.object,
};

function AccountsTableCard({
  companyName,
  hyperscaler,
  hyperscalerLabel,
  accounts,
  error,
  loading,
  notifyRef,
  editLink,
  createLink,
}) {
  const history = useHistory();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const [showBatchLoadModal, setShowBatchLoadModal] = useState(false);
  const [backendCall, setBackendCall] = useState(false);

  function startAccountsBatchLoad() {
    setShowBatchLoadModal(false);
    handleBackendCall(
      loadAccountsInBatch(companyName, hyperscaler),
      `Batch load of ${hyperscaler} accounts was started. This may take multiple minutes.`,
      `/admin/company-edit/${companyName}`
    );
  }

  function handleBackendCall(backendPromise, successMessage, path) {
    setBackendCall(true);
    backendPromise
      .then(() => {
        setBackendCall(false);
        pushHistory(history, activePermission, activeAccountIds, path);
        notifySuccess(notifyRef, successMessage);
      })
      .catch((e) => {
        setBackendCall(false);
        notifyError(notifyRef, e);
      });
  }

  return (
    <Card>
      <BtcCardHeader
        title={`${hyperscalerLabel} accounts`}
        error={error}
        loading={loading}
        childSize={6}
      >
        <div className={"pull-right"}>
          <Button
            className="btn btn-primary"
            disabled={backendCall}
            onClick={() => setShowBatchLoadModal(true)}
          >
            Load Accounts
          </Button>
          <BtcLink
            className="btn btn-primary ml-3"
            to={`${createLink}/${companyName}`}
            disabled={backendCall}
          >
            Add Account
          </BtcLink>
        </div>
      </BtcCardHeader>
      <CardBody>
        <ReactTable
          data={accounts}
          columns={[
            {
              Header: "Account ID",
              accessor: "accountId",
            },
            {
              Header: "Account Name",
              accessor: "accountName",
            },
            {
              Header: "Actions",
              className: "rt-th--right-align btc-10p-column",
              disableSortBy: true,
              disableFilters: true,
              Cell: (item) => {
                return (
                  <div className="actions-right">
                    <BtcLink
                      className="btn btn-sm btn-icon btn-link btn-warning edit"
                      to={`${editLink}/${companyName}/${item.row.original.accountId}`}
                    >
                      <i className="fa fa-edit" />
                    </BtcLink>{" "}
                  </div>
                );
              },
            },
          ]}
        />
      </CardBody>
      <AccountBatchLoadModal
        show={showBatchLoadModal}
        companyName={companyName}
        hyperscaler={hyperscaler}
        onConfirm={() => startAccountsBatchLoad()}
        onClose={() => setShowBatchLoadModal(false)}
      />
    </Card>
  );
}

export default AccountsTableCard;
