import { API, graphqlOperation } from "aws-amplify";
import { parseAndThrowErrors } from "../../errors/GraphQlError";

export async function fetchAccountsPerRegion(company) {
  const accountsPerRegion = `query {
            listAccountsPerRegion(input: {
              tenant: "${company}",
            }) {
              items{
                SK
                count
              }
            }
          }
          `;

  try {
    let responseAPR = await API.graphql(graphqlOperation(accountsPerRegion));
    return responseAPR.data.listAccountsPerRegion.items;
  } catch (e) {
    parseAndThrowErrors(e, `accounts per region of ${company}`);
  }
}
