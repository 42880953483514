import { Auth } from "aws-amplify";
import { HttpError } from "../errors/HttpError";
import { capitalizeFirstLetter } from "./stringUtil";

function makeRequest(body, url, describingAction, type) {
  return Auth.currentSession()
    .then((session) => {
      const currentIdToken = session.getIdToken().getJwtToken();
      return fetch(url, {
        method: type,
        mode: "cors",
        body: body ? JSON.stringify(body) : undefined,
        headers: {
          Authorization: currentIdToken,
          "Content-Type": "application/json",
        },
      });
    })
    .then((response) => handleErrors(describingAction + " failed.", response))
    .then((response) => response.json)
    .catch((e) => {
      if (e instanceof HttpError) {
        throw e;
      }
      throw new HttpError(
        `An unexpected error happened, when ${describingAction}.`,
        "Unexpected error"
      );
    });
}

async function handleErrors(msg, response) {
  if (!response.ok) {
    let message = capitalizeFirstLetter(msg);
    let json = await response.json();
    if (json) {
      message += " Details: " + json;
    }
    throw new HttpError(message, response.statusText);
  }
  return response;
}

// describingAction should be as gerund like "sending mail" or "updating user"
export function doPost(body, url, describingAction) {
  return makeRequest(body, url, describingAction, "POST");
}

// describingAction should be as gerund like "sending mail" or "updating user"
export function doPut(body, url, describingAction) {
  return makeRequest(body, url, describingAction, "PUT");
}

// describingAction should be as gerund like "sending mail" or "updating user"
export function doDelete(url, describingAction) {
  return makeRequest(null, url, describingAction, "DELETE");
}
