import React, { useState, createContext } from "react";

export const UserContext = createContext({
  activeAccountIds: ["all"],
});

export const UserProvider = (props) => {
  const [user, setUser] = useState();
  const [activePermission, setActivePermission] = useState();
  const [activeAccountIds, setActiveAccountIds] = useState();

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        activePermission,
        setActivePermission,
        activeAccountIds,
        setActiveAccountIds,
      }}
    >
      {[props.children]}
    </UserContext.Provider>
  );
};
