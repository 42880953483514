import React from "react";
import { Col, Row } from "reactstrap";
import UserTableCard from "./user/UserTableCard";
import CompanyTableCard from "./company/CompanyTableCard";

function Admin({ notifyRef }) {
  const customerLogoContainer = document.getElementById("customerLogoImg");
  const isCustomerLogoVisible = customerLogoContainer?.innerHTML !== "";

  return (
    <section
      aria-labelledby="admin-module-page-heading"
      className={`content ${
        isCustomerLogoVisible ? "content-with-customer-logo" : ""
      }`}
    >
      <h2 id="admin-module-page-heading" className="visually-hidden">
        Admin
      </h2>
      <Row>
        <Col>
          <UserTableCard notifyRef={notifyRef} />
        </Col>
      </Row>
      <Row>
        <Col>
          <CompanyTableCard notifyRef={notifyRef} />
        </Col>
      </Row>
    </section>
  );
}

export default Admin;
