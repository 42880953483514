/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Costs from "modules/costs/Costs.js";
import Dashboard from "modules/dashboard/Dashboard.js";
import Services from "modules/services/Services.js";
import UserProfile from "modules/userprofile/UserProfile.js";
import { Security } from "./security/Security";
import Iam from "./iam/Iam";
import Network from "./network/Network";
import Admin from "./admin/Admin";
import UserEditPage from "./admin/user/UserEditPage";
import UserCreatePage from "./admin/user/UserCreatePage";
import CompanyCreatePage from "./admin/company/CompanyCreatePage";
import CompanyEditPage from "./admin/company/CompanyEditPage";
import AzureSubscriptionEditCard from "./admin/subscriptions/AzureSubscriptionEditCard";
import AWSAccountEditCard from "./admin/accounts/AWSAccountEditCard";

export const moduleRoutes = (notifyRef) => {
  return [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "far fa-chart-bar",
      component: <Dashboard />,
    },
    {
      path: "/iam",
      name: "IAM",
      icon: "far fa-id-badge",
      component: <Iam />,
    },
    {
      path: "/costs",
      name: "Costs",
      icon: "fas fa-euro-sign",
      component: <Costs />,
    },
    {
      path: "/services",
      name: "Services",
      icon: "far fa-list-alt",
      component: <Services />,
      extended: true,
    },
    {
      path: "/security",
      name: "Security",
      icon: "fas fa-shield-alt",
      component: <Security />,
    },
    {
      path: "/network",
      name: "Network",
      icon: "fas fa-network-wired",
      component: <Network />,
    },
    {
      path: "/admin",
      name: "Admin",
      icon: "fas fa-broom",
      component: <Admin notifyRef={notifyRef} />,
      childPaths: [
        {
          path: "/user-edit",
          component: <UserEditPage notifyRef={notifyRef} />,
        },
        {
          path: "/user-create",
          component: <UserCreatePage notifyRef={notifyRef} />,
        },
        {
          path: "/company-edit",
          component: <CompanyEditPage notifyRef={notifyRef} />,
        },
        {
          path: "/company-create",
          component: <CompanyCreatePage notifyRef={notifyRef} />,
        },
        {
          path: "/subscription-create",
          component: (
            <AzureSubscriptionEditCard notifyRef={notifyRef} isCreate={true} />
          ),
        },
        {
          path: "/subscription-edit",
          component: (
            <AzureSubscriptionEditCard notifyRef={notifyRef} isCreate={false} />
          ),
        },
        {
          path: "/account-create",
          component: (
            <AWSAccountEditCard notifyRef={notifyRef} isCreate={true} />
          ),
        },
        {
          path: "/account-edit",
          component: (
            <AWSAccountEditCard notifyRef={notifyRef} isCreate={false} />
          ),
        },
      ],
    },
    {
      path: "/user-profile",
      name: "userProfile",
      icon: "far fa-user",
      component: <UserProfile />,
    },
  ];
};
