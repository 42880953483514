import React, { useContext, useEffect, useRef, useState } from "react";
import { CardFooter, Col, Row } from "reactstrap";
import {
  generateCostDataset,
  generateCostGraphOptions,
} from "./constants/costsData";
import { UserContext } from "../../utils/userContext";
import * as DateUtil from "../../utils/dateUtil";
import {
  calculateMonthOffset,
  filterEntriesWithDateBetween,
  getISOMonths,
} from "../../utils/dateUtil";
import NotificationAlert from "react-notification-alert";
import CustomErrorIcon from "../../components/CustomErrorIcon";
import BtcCard from "../../components/BtcCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import { fetchCosts } from "./costsResource";
import { getMaxProperty, sum } from "../../utils/arrayUtil";
import { filterByActiveAccount } from "../../utils/accountUtil";
import { useAppContext } from "../../utils/contextLib";
import BtcMonthDropdown from "../../components/BtcMonthDropdown";
import BtcInfoBadge from "../../components/BtcInfoBadge";
import BtcStretchedContentCardBody from "../../components/BtcStretchedContentCardBody";
import * as GroupByUtil from "../../utils/groupByUtil";
import { convertMapToValueArrayOrderedByKey } from "../../utils/sortUtil";
import { Line } from "react-chartjs-2";

export const CostsPlotCard = () => {
  const [monthOptions] = useState([6, 12]);

  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const notifyRef = useRef(null);

  // Sets the Card to busy, which means it is just doing something (calculation or async requests)
  const [busy, setBusy] = useState(true);
  const [numberOfMonth, setNumberOfMonth] = useState(6);
  const [cumCostsPerTimeFrame, setCumCostsPerTimeFrame] = useState(0);
  const [costsError, setCostsError] = useState(null);
  const [costsWarning, setCostsWarning] = useState(null);
  const [costs, setCosts] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [datasets, setDatasets] = useState([generateCostDataset()]);
  const [labels, setLabels] = useState(generateMonthNames(numberOfMonth));
  const [options] = useState(generateCostGraphOptions());

  useEffect(() => {
    setCostsWarning(null);
    const maxStartMonthOffset = calculateMonthOffset(
      monthOptions[monthOptions.length - 1]
    );
    fetchData(
      () => fetchCosts(activePermission["company"], maxStartMonthOffset),
      (data) => {
        setCosts(data);
        setUpdatedAt(getMaxProperty(data, "updatedAt"));
      },
      setCostsError,
      setBusy,
      notifyRef
    );
  }, [monthOptions, activePermission, fetchData]);

  useEffect(() => {
    let monthNames = generateMonthNames(numberOfMonth);
    setLabels(monthNames);
  }, [numberOfMonth]);

  useEffect(() => {
    if (!costs) {
      return;
    }
    const filteredAndCumulatedCosts = filterAndCumulateCosts(
      costs,
      activeAccountIds,
      numberOfMonth
    );

    // makes last month of line plot dashed
    const dash = (ctx) => {
      return ctx.p0DataIndex > filteredAndCumulatedCosts.length - 3
        ? [6, 6]
        : [6, 0];
    };
    setDatasets([
      generateCostDataset(filteredAndCumulatedCosts, {
        borderDash: (ctx) => dash(ctx),
      }),
    ]);
    setCostsWarning(
      buildCostsWarning(filteredAndCumulatedCosts, numberOfMonth)
    );
    setCumCostsPerTimeFrame(sum(filteredAndCumulatedCosts));
  }, [costs, numberOfMonth, activeAccountIds]);

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcCard label="Costs">
        <BtcCardHeader
          title="Costs"
          updatedAt={updatedAt}
          icon="fas fa-euro-sign"
          error={costsError}
          warning={costsWarning}
          url="/costs"
          loading={busy}
        />
        <BtcStretchedContentCardBody>
          <Line
            data={{
              labels: labels,
              datasets: datasets,
            }}
            options={options}
          />
        </BtcStretchedContentCardBody>
        <CardFooter>
          <hr />
          <Row>
            <Col xs="4">
              {costsError ? (
                <CustomErrorIcon
                  className="btc-small-card-error"
                  error={costsError}
                />
              ) : (
                <BtcInfoBadge label="Overall">
                  {busy ? "?" : cumCostsPerTimeFrame + " €"}
                </BtcInfoBadge>
              )}
            </Col>
            <Col xs="8">
              <BtcMonthDropdown
                className="pull-right"
                numberOfMonth={numberOfMonth}
                setNumberOfMonth={setNumberOfMonth}
              />
            </Col>
          </Row>
        </CardFooter>
      </BtcCard>
    </>
  );
};

function buildCostsWarning(costsInSelectedMonths, numberOfMonth) {
  if (
    costsInSelectedMonths.length > numberOfMonth - 1 &&
    costsInSelectedMonths[numberOfMonth - 1] > 0
  ) {
    return null;
  }
  return "There are no costs determined for this month yet. However, this may be due to the fact, that the cloud provider needs some days to update the costs.";
}

function generateMonthNames(numberOfMonth) {
  const startMonthOffset = calculateMonthOffset(numberOfMonth);
  return DateUtil.getMonthNames(startMonthOffset, 0);
}

function filterAndCumulateCosts(costInput, activeAccountIds, numberOfMonth) {
  const costsFilteredByAccount = filterByActiveAccount(
    costInput,
    activeAccountIds
  );
  const startMonthOffset = calculateMonthOffset(numberOfMonth);
  const costsFilteredByAccountAndMonth = filterEntriesWithDateBetween(
    costsFilteredByAccount,
    startMonthOffset,
    0
  );

  let filteredAndCumulatedCostsMap = GroupByUtil.groupByKeyAndBuildSum(
    costsFilteredByAccountAndMonth,
    (e) => e.date,
    (e) => e.costs
  );
  setMonthToZeroIfNoDataAvailable(
    filteredAndCumulatedCostsMap,
    startMonthOffset
  );
  return convertMapToValueArrayOrderedByKey(filteredAndCumulatedCostsMap);
}

function setMonthToZeroIfNoDataAvailable(costsGroupedByDate, startMonthOffset) {
  const months = getISOMonths(startMonthOffset, 0);
  for (let month of months) {
    if (!costsGroupedByDate.has(month)) {
      costsGroupedByDate.set(month, 0);
    }
  }
}
