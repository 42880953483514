/*
 * There are better ways to do stage specific variables (like https://stackoverflow.com/a/61908673)
 * However this made problems on Windows. If someone has a better way, please create a ticket.
 */
const stageConf = {
  dev: {
    COGNITO_USER_POOL_ID: "eu-central-1_JjgaU7lWj",
    COGNITO_APP_CLIENT_ID: "2bhmk9m8rh2hale8i84v7sedku",
    GRAPHQL_API:
      "https://k7zzfd6mf5exbjvezgnkozkvbq.appsync-api.eu-central-1.amazonaws.com/graphql",
    MAIL_API:
      "https://ss5s9gk0jk.execute-api.eu-central-1.amazonaws.com/dev/sendMail",
    SYSADMIN_API:
      "https://6f808s17ba.execute-api.eu-central-1.amazonaws.com/dev",
  },
  kon: {
    COGNITO_USER_POOL_ID: "eu-central-1_WRAHwDFzx",
    COGNITO_APP_CLIENT_ID: "355icaisjck6qfd4npr0797tj5",
    GRAPHQL_API:
      "https://cp7sksoyvvemtc74hjp3vuub64.appsync-api.eu-central-1.amazonaws.com/graphql",
    MAIL_API:
      "https://zinx39vnmi.execute-api.eu-central-1.amazonaws.com/kon/sendMail",
    SYSADMIN_API:
      "https://pfaldt1v27.execute-api.eu-central-1.amazonaws.com/kon",
  },
  prod: {
    COGNITO_USER_POOL_ID: "eu-central-1_esOxzSION",
    COGNITO_APP_CLIENT_ID: "77qs7fe6bsta59lnbdsmofre7b",
    GRAPHQL_API:
      "https://5cicp23ubzfvbns53f3am66mwa.appsync-api.eu-central-1.amazonaws.com/graphql",
    MAIL_API:
      "https://ogfyerl0sd.execute-api.eu-central-1.amazonaws.com/prod/sendMail",
    SYSADMIN_API:
      "https://u10lc86f40.execute-api.eu-central-1.amazonaws.com/prod",
  },
};

const stage = process.env.REACT_APP_ENV;

let config = {
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: stageConf[stage].COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: stageConf[stage].COGNITO_APP_CLIENT_ID,
  },
  graphql: {
    URL: stageConf[stage].GRAPHQL_API,
    REGION: "eu-central-1",
    AUTHENTICATION_TYPE: "AMAZON_COGNITO_USER_POOLS",
  },
  mailApi: {
    URL: stageConf[stage].MAIL_API,
    REGION: "eu-central-1",
    AUTHENTICATION_TYPE: "AMAZON_COGNITO_USER_POOLS",
  },
  sysadminApi: {
    URL: stageConf[stage].SYSADMIN_API,
    REGION: "eu-central-1",
    AUTHENTICATION_TYPE: "AMAZON_COGNITO_USER_POOLS",
  },
  cognitoPasswordPolicyParams: {
    MINIMUM_LENGTH: 8,
    REQUIRE_LOWER_CASE: true,
    REQURE_NUMBERS: true,
    REQUIRE_SYMBOLS: true,
    REQUIRE_UPPER_CASE: true,
    TEMPORARY_PASSWORD_VALIDITY_DAYS: 7,
    SPECIAL_CHARACTERS: "[!@#$%^&*()_+-=[]{};':\"\\|,.<>/?]*$/",
  },
};

export default config;
