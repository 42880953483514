function getPieChartService(callback) {
  return {
    data: {
      labels: ["EC2", "IAM", "s3"],
      datasets: [
        {
          label: "Emails",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ["#E8E5DA", "#9EB7E5", "#304C89"],
          borderWidth: 0,
          barPercentage: 1.6,
          data: [],
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
      },
      onClick: function (evt) {
        let element = this.getElementsAtEventForMode(
          evt,
          "nearest",
          { intersect: true },
          false
        );
        if (element.length > 0) {
          callback(this.data.labels[element[0].index]);
        }
      },
      tooltip: {
        enabled: true,
      },
      scales: {
        y: {
          ticks: {
            display: false,
          },
          grid: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
        x: {
          grid: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

function getDoughnutChartEventType(callback) {
  return {
    data: {
      datasets: [
        {
          backgroundColor: [
            "#304C89",
            "#648DE5",
            "#9EB7E5",
            "#E8E5DA",
            "#c5c8cb",
            "#4A6DB7",
            "#23234d",
          ],
          data: [],
        },
      ],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        "Backdoor",
        "Behavior",
        "Recon",
        "Trojan",
        "UnauthorizedAccess",
        "Policy",
        "Discovery",
      ],
    },
    options: {
      maintainAspectRatio: false,
      onClick: function (evt) {
        let element = this.getElementsAtEventForMode(
          evt,
          "nearest",
          { intersect: true },
          false
        );
        if (element.length > 0) {
          callback(this.data.labels[element[0].index]);
        }
      },
    },
  };
}

function getPieChartAlertLevel(callback) {
  return {
    data: {
      labels: ["Low", "Medium", "High"],
      datasets: [
        {
          label: "Emails",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ["#28A745FF", "#eca202", "#de1f16"],
          borderWidth: 0,
          barPercentage: 1.6,
          data: [],
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
      },
      onClick: function (evt) {
        let element = this.getElementsAtEventForMode(
          evt,
          "nearest",
          { intersect: true },
          false
        );
        if (element.length > 0 && callback) {
          callback(this.data.labels[element[0].index]);
        }
      },
      tooltip: {
        enabled: true,
      },
      scales: {
        y: {
          ticks: {
            display: false,
          },
          grid: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },

        x: {
          grid: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

module.exports = {
  getPieChartService,
  getDoughnutChartEventType,
  getPieChartAlertLevel,
};
