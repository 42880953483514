import PropTypes from "prop-types";
import React from "react";
import { convertCurrencySymbol } from "../modules/costs/CostsUtil";
import { roundTo2Decimals } from "../utils/mathUtil";
import BtcExchangeRateInfoSymbolWithTooltip from "./BtcExchangeRateInfoSymbolWithTooltip";

BtcBudgetWithTooltip.propTypes = {
  actualCostsV2: PropTypes.object,
  limitV2: PropTypes.object,
  className: PropTypes.string,
};

function BudgetDiv({
  actualCosts,
  limit,
  currency,
  children,
  actualCostsClassName,
}) {
  return (
    <>
      <span className={actualCostsClassName}>
        {roundTo2Decimals(actualCosts)}
      </span>{" "}
      /{" "}
      <span>
        {roundTo2Decimals(limit)}&nbsp;{convertCurrencySymbol(currency)}{" "}
        {children}
      </span>
    </>
  );
}

export default function BtcBudgetWithTooltip({
  actualCostsV2,
  limitV2,
  actualCostsClassName,
}) {
  return (
    <BudgetDiv
      actualCosts={actualCostsV2.costs}
      limit={limitV2.costs}
      currency={actualCostsV2.currency}
      actualCostsClassName={actualCostsClassName}
    >
      {actualCostsV2.exchangeRate && (
        <BtcExchangeRateInfoSymbolWithTooltip costsV2={actualCostsV2} />
      )}
    </BudgetDiv>
  );
}
