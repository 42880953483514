import React, { useState } from "react";
import {
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import CustomErrorIcon from "./CustomErrorIcon";
import CustomWarningIcon from "./CustomWarningIcon";
import { convertISOToLocal } from "../utils/dateUtil";
import _uniqueId from "lodash/uniqueId";
import BtcSpinner from "./BtcSpinner";
import PropTypes from "prop-types";
import BtcLink from "./BtcLink";

BtcCardHeader.propTypes = {
  title: PropTypes.string,
  error: PropTypes.object,
  updatedAt: PropTypes.string,
  url: PropTypes.string,
  foreignPageUrl: PropTypes.string,
  icon: PropTypes.string,
  warning: PropTypes.string,
  loading: PropTypes.bool,
};

function BtcCardHeader({
  title,
  error,
  updatedAt,
  url,
  foreignPageUrl,
  icon,
  warning,
  description,
  loading = false,
  exportDataMenu,
  exportExcelFileFn,
  exportCsvFileFn,
  exportPdfFileFn,
  isDataToExportAvailable,
  children,
  childSize = 0,
}) {
  const [tooltipId] = useState(_uniqueId("btc-card-header-tooltip-"));

  const leftColumnWidth = () => {
    if (icon && !exportDataMenu) {
      return 10;
    } else if ((!icon && exportDataMenu) || (icon && exportDataMenu)) {
      return 9;
    } else {
      return 12;
    }
  };

  return (
    <div className="btc-card-title">
      <Row>
        <Col xs={leftColumnWidth() - childSize}>
          <div>
            <CardTitle tag="h3">
              {url ? (
                <>
                  <BtcLink className="btc-title-link p-0 " to={url}>
                    {title} <CustomErrorIcon error={error} />{" "}
                    <CustomWarningIcon message={warning} />
                  </BtcLink>
                  <BtcSpinner
                    className="vertical-align-middle"
                    loading={!error && loading}
                  />
                </>
              ) : foreignPageUrl ? (
                <>
                  <a
                    className="btc-title-link p-0 "
                    target="_blank"
                    href={foreignPageUrl}
                    rel="noopener noreferrer"
                  >
                    {title} <CustomErrorIcon error={error} />{" "}
                    <CustomWarningIcon message={warning} />
                  </a>
                  <BtcSpinner
                    className="vertical-align-middle"
                    loading={!error && loading}
                  />
                </>
              ) : (
                <>
                  {title} <CustomErrorIcon error={error} />{" "}
                  <CustomWarningIcon message={warning} />
                  <BtcSpinner
                    className="vertical-align-middle"
                    loading={!error && loading}
                  />
                </>
              )}
            </CardTitle>
            {!updatedAt && !description && (
              // some filler content to make loading of updatedAt smoother
              <p className="updated-label">&nbsp;</p>
            )}
            {updatedAt && !description && (
              <p className="updated-label">
                Updated at {convertISOToLocal(updatedAt)}
              </p>
            )}
            {description && (
              <p className="card-category">
                {description}{" "}
                {updatedAt && (
                  <>
                    <i
                      id={tooltipId}
                      className="fas fa-info-circle"
                      aria-label={`Updated at ${convertISOToLocal(updatedAt)}`}
                    />
                    <UncontrolledTooltip delay={0} target={tooltipId}>
                      Updated at {convertISOToLocal(updatedAt)}
                    </UncontrolledTooltip>
                  </>
                )}
              </p>
            )}
          </div>
        </Col>
        {exportDataMenu && (
          <Col className="table-header-dropdown-menu">
            <UncontrolledDropdown>
              <DropdownToggle
                caret
                className="btn-round btn btn-outline-primary"
                id="dropdown-basic"
                disabled={!isDataToExportAvailable}
              >
                Export data
              </DropdownToggle>
              <DropdownMenu>
                {exportExcelFileFn && (
                  <DropdownItem
                    className="primary-text"
                    onClick={exportExcelFileFn}
                  >
                    Excel File
                  </DropdownItem>
                )}
                {exportCsvFileFn && (
                  <DropdownItem
                    className="primary-text"
                    onClick={exportCsvFileFn}
                  >
                    CSV File
                  </DropdownItem>
                )}
                {exportPdfFileFn && (
                  <DropdownItem
                    className="primary-text"
                    onClick={exportPdfFileFn}
                  >
                    PDF File
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        )}
        {children && <Col xs={childSize}>{children}</Col>}
        {icon && !exportDataMenu && (
          <Col xs="2">
            <div className="icon-big icon-right icon-success">
              <i className={icon} />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default BtcCardHeader;
