import * as GroupByUtil from "../../utils/groupByUtil";
import { convertMapToValueArrayOrderedByKey } from "../../utils/sortUtil";

export function getCumulatedCostsGroupedByDate(costs) {
  return GroupByUtil.groupByKeyAndBuildSum(
    costs,
    (e) => e.date,
    (e) => e.costs
  );
}

export function getListOfCumulatedCostsOrderedByDate(costs) {
  let costsGroupedByDate = getCumulatedCostsGroupedByDate(costs);
  return convertMapToValueArrayOrderedByKey(costsGroupedByDate);
}

export function convertCurrencySymbol(currency) {
  if (!currency) return;
  return new Intl.NumberFormat("en", { style: "currency", currency: currency })
    .formatToParts(1)
    .find((part) => (part.type = "currency")).value;
}
