import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import { deleteUser, fetchUser, pushUser, putUser } from "../adminResource";
import { notifyError, notifySuccess } from "../../../utils/notification";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UserDetailsFormCard from "./UserDetailsFormCard";
import CompanyAssignmentsFormCard from "./CompanyAssignmentsFormCard";
import DeletionModal from "../../../components/DeletionModal";
import { useAppContext } from "../../../utils/contextLib";
import { pushHistory } from "../../../utils/urlUtil";
import { UserContext } from "../../../utils/userContext";
import BtcLink from "../../../components/BtcLink";

UserEditForm.propTypes = {};

function UserEditForm({ isCreate, notifyRef }) {
  const { fetchData } = useAppContext();
  const { activePermission, activeAccountIds } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const [userLoading, setUserLoading] = useState(true);
  const [error, setError] = useState();

  const [modalUser, setModalUser] = useState();
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [backendCall, setBackendCall] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Please provide a valid E-Mail")
      .required("An E-Mail is required"),
    companyAssignments: yup
      .array()
      .min(1, "At least one company assignment must be present.")
      .required("required"),
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: {
      companyAssignments: [],
    },
  });
  const watchCompanyAssignments = watch("companyAssignments");

  useEffect(() => {
    if (isCreate) {
      setUserLoading(false);
      return;
    }

    let pathParts = location.pathname.split("/");
    let userEMail = pathParts.pop();

    fetchData(
      () => fetchUser(userEMail),
      (user) => {
        setModalUser(user);
        reset(user);
      },
      setError,
      setUserLoading,
      notifyRef
    );
  }, [reset, location, isCreate, notifyRef, fetchData]);

  function onSubmit(data) {
    if (isCreate) {
      handleBackendCall(
        pushUser(data),
        "Creation of user was successful.",
        `/admin`
      );
    } else {
      handleBackendCall(
        putUser(data.userName, data),
        "Update of user was successful.",
        `/admin/user-edit/${data.email}`
      );
    }
  }

  function doDeleteUser() {
    setShowDeletionModal(false);
    handleBackendCall(
      deleteUser(modalUser.userName),
      "Deletion of user was successful.",
      `/admin`
    );
  }

  function handleBackendCall(backendPromise, successMessage, path) {
    setBackendCall(true);
    backendPromise
      .then(() => {
        setBackendCall(false);
        pushHistory(history, activePermission, activeAccountIds, path);
        notifySuccess(notifyRef, successMessage);
      })
      .catch((e) => {
        setBackendCall(false);
        notifyError(notifyRef, e);
      });
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col
            md={{ offset: 1, size: 10 }}
            lg={{ offset: 2, size: 8 }}
            xl={{ offset: 3, size: 6 }}
          >
            <UserDetailsFormCard
              isCreate={isCreate}
              userLoading={userLoading}
              register={register}
              formErrors={errors}
              error={error}
              disabled={backendCall}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ offset: 1, size: 10 }}
            lg={{ offset: 2, size: 8 }}
            xl={{ offset: 3, size: 6 }}
          >
            <CompanyAssignmentsFormCard
              userLoading={userLoading}
              companyAssignments={watchCompanyAssignments}
              notifyRef={notifyRef}
              setValue={setValue}
              formErrors={errors}
              disabled={backendCall}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ offset: 1, size: 10 }}
            lg={{ offset: 2, size: 8 }}
            xl={{ offset: 3, size: 6 }}
          >
            {!isCreate && (
              <div className="px-4">
                <Button
                  className="pull-left card-shadow btn--no-margin"
                  color="danger"
                  disabled={userLoading || backendCall}
                  onClick={() => setShowDeletionModal(true)}
                >
                  <i className="fas fa-trash" /> Delete user
                </Button>
              </div>
            )}
            <div className="px-4 pull-right">
              <BtcLink
                className="btn btn-success btn--no-margin card-shadow"
                to="/admin"
                disabled={userLoading || backendCall}
              >
                Cancel
              </BtcLink>
              <input
                className="btn btn-success card-shadow btn--no-margin ml-3"
                type="submit"
                value={isCreate ? "Create user" : "Save changes"}
                disabled={userLoading || !isValid || backendCall}
              />
            </div>
          </Col>
        </Row>
      </Form>
      {modalUser && (
        <DeletionModal
          show={showDeletionModal}
          title={`Delete user ${modalUser.email}`}
          deleteButton={`Delete User`}
          onDelete={() => doDeleteUser()}
          onClose={() => setShowDeletionModal(false)}
        />
      )}
    </>
  );
}

export default UserEditForm;
