import React, { useContext, useEffect, useRef, useState } from "react";
import { Badge, CardBody, Col, Row } from "reactstrap";
import { API, graphqlOperation } from "aws-amplify";
import { UserContext } from "../../utils/userContext";
import * as DateUtil from "../../utils/dateUtil";
import NotificationAlert from "react-notification-alert";
import { parseAndThrowErrors } from "../../errors/GraphQlError";
import BtcSpinner from "../../components/BtcSpinner";
import BtcCardHeader from "../../components/BtcCardHeader";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import { useAppContext } from "../../utils/contextLib";

export const FlatrateCostsCard = () => {
  const { fetchData } = useAppContext();
  const { user, activePermission } = useContext(UserContext);
  const notifyRef = useRef(null);
  const [busy, setBusy] = useState(true);
  const [costFlatrate, setCostFlatrate] = useState(0);
  const [costsError, setCostsError] = useState(null);

  useEffect(onLoad, [user.activeAccountId, activePermission, fetchData]);

  function onLoad() {
    async function getCosts(company) {
      const monthBeginAsString = DateUtil.getISOMonthRelativeToToday(-1);
      const monthEndAsString = DateUtil.getISOMonthRelativeToToday(1);
      let items = await getCostItems(
        company,
        monthEndAsString,
        monthBeginAsString
      );
      items = items.filter((item) => item.tags === "saasFlatrate");

      if (items == null || items.length < 1) {
        return;
      }

      return items[items.length - 1].costs;
    }

    const company = activePermission["company"];
    fetchData(
      () => getCosts(company),
      setCostFlatrate,
      setCostsError,
      setBusy,
      notifyRef
    );
  }

  async function getCostItems(company, monthEndAsString, monthBeginAsString) {
    const ygetCosts = `query {
      listCosts(input: {
        month_end: "${monthEndAsString}",
        month_begin: "${monthBeginAsString}",
        service: "SUM",
        tenant: "${company}",
      }) {
        items{
          costs
          tags
        }
      }
    }
    `;
    try {
      const response = await API.graphql(graphqlOperation(ygetCosts));
      return response.data.listCosts.items;
    } catch (e) {
      parseAndThrowErrors(e, `flat-rate-costs of ${company}`);
    }
  }

  return (
    <>
      <NotificationAlert ref={notifyRef} />
      <BtcNoAspectRatioCard className="card-stats">
        <BtcCardHeader
          title="Costs"
          icon="fas fa-euro-sign"
          error={costsError}
        />
        <CardBody>
          <Row>
            <Col md="12 pt-3">
              <div>
                <p>
                  <b>Flatrate</b>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="8" xs="7">
              <div className="numbers">
                <p className="card-category">Costs per month: </p>
              </div>
            </Col>
            <Col xs="4">
              <div className="pull-right">
                <h6>
                  <Badge className="has-text-btc-blue" color="info" pill>
                    {costsError ? (
                      "?"
                    ) : busy ? (
                      <BtcSpinner />
                    ) : (
                      costFlatrate + "€"
                    )}
                  </Badge>
                </h6>
              </div>
            </Col>
          </Row>
        </CardBody>
      </BtcNoAspectRatioCard>
    </>
  );
};
