export function getMinDate(alerts) {
  return getAlertDateByFilter(alerts, (alertItems) =>
    Math.min.apply(null, alertItems)
  );
}

export function getMaxDate(alerts) {
  return getAlertDateByFilter(alerts, (alertItems) =>
    Math.max.apply(null, alertItems)
  );
}

function getAlertDateByFilter(alerts, oneElementFilter) {
  if (alerts.length <= 0) {
    return "";
  }
  const date = oneElementFilter(
    alerts.map(function (e) {
      return e.firstSeen;
    })
  );
  const timestamp = parseInt(date * 1000);
  const splitdate = new Date(parseInt(timestamp)).toISOString().split("T");
  return splitdate[0] + " " + splitdate[1].split(".")[0];
}
