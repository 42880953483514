import React, { useEffect } from "react";
import { FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";
import BtcFormGroup from "./BtcFormGroup";
import { useController } from "react-hook-form";

BtcFormCheckboxArray.propTypes = {
  control: PropTypes.object.isRequired,
  formErrors: PropTypes.object,
  arrayAttributeName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  arrayDefinition: PropTypes.object.isRequired,
};

const objectMap = (obj, fn) =>
  Object.entries(obj).map(([k, v], i) => fn(k, v, i));

function BtcFormCheckboxArray({
  control,
  formErrors,
  arrayAttributeName,
  arrayDefinition,
  disabled = false,
}) {
  const { field } = useController({
    control,
    name: arrayAttributeName,
  });

  const [internalValue, setInternalValue] = React.useState(field.value || []);

  useEffect(() => {
    setInternalValue(field.value);
  }, [field.value]);

  return (
    // https://github.com/react-hook-form/react-hook-form/issues/476#issuecomment-974636432
    <BtcFormGroup formErrors={formErrors} attributeName={arrayAttributeName}>
      <>
        {objectMap(arrayDefinition, (arrayValue, arrayLabel) => {
          return (
            <FormGroup check key={"Group_" + arrayValue} className={"mt-3"}>
              <Label check>
                <input
                  onChange={(e) => {
                    const valueCopy = internalValue.filter(
                      (item) => item !== e.target.value
                    );
                    if (e.target.checked) {
                      valueCopy.push(arrayValue);
                    }
                    // send data to react hook form
                    field.onChange(valueCopy);
                  }}
                  key={arrayValue}
                  type="checkbox"
                  value={arrayValue}
                  className="form-control"
                  disabled={disabled}
                  checked={internalValue.indexOf(arrayValue) !== -1}
                />
                {arrayLabel} <span className="form-check-sign" />
              </Label>
            </FormGroup>
          );
        })}
      </>
    </BtcFormGroup>
  );
}

export default BtcFormCheckboxArray;
