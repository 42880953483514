import React, { useContext } from "react";
import { putSearchParams } from "../utils/urlUtil";
import { UserContext } from "../utils/userContext";
import { NavLink } from "react-router-dom";

function BtcNavLink({ children, name, to }) {
  const { activePermission, activeAccountIds } = useContext(UserContext);

  return (
    <NavLink
      aria-label={name}
      to={putSearchParams(to, activePermission, activeAccountIds)}
      activeClassName=""
    >
      {children}
    </NavLink>
  );
}

export default BtcNavLink;
