export const getRoleName = (roleName) => {
  const name = roleName.split("/");
  return name[1] || roleName;
};

export const getUniqueUserList = (userList) => {
  const uniqueUsers = {};

  userList.forEach((user) => {
    if (!uniqueUsers[user.username]) {
      uniqueUsers[user.username] = [user.role];
    } else if (
      uniqueUsers[user.username] &&
      !uniqueUsers[user.username].includes(user.role)
    ) {
      uniqueUsers[user.username].push(user.role);
    }
  });

  return uniqueUsers;
};

export const prepareLabelsAndData = (roleList) => {
  const labelsArray = [];
  const dataArray = [];

  for (const [key, value] of Object.entries(roleList)) {
    labelsArray.push(key);
    dataArray.push(value.amount);
  }

  return { labels: labelsArray, data: dataArray };
};

export const prepareRoleShortList = (roleList) => {
  const shortList = {};
  for (const [key, value] of Object.entries(roleList)) {
    if (key === "admin" || key === "btc-admin" || key === "poweruser") {
      shortList[key] = { amount: value.amount };
    } else {
      const previousAmountSum = shortList["others"]?.amount || 0;
      shortList["others"] = { amount: previousAmountSum + value.amount };
    }
  }
  return shortList;
};
