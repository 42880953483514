import React from "react";
import PropTypes from "prop-types";
import { useFieldArray } from "react-hook-form";
import BtcFormGroup from "./BtcFormGroup";
import { Button, Col, Row } from "reactstrap";

BtcFormValueArray.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  formErrors: PropTypes.object,
  arrayAttributeName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

// https://codesandbox.io/s/react-hook-form-multiple-usefieldarray-in-a-form-ffboe?file=/src/index.js
function BtcFormValueArray({
  register,
  control,
  formErrors,
  arrayAttributeName,
  disabled,
}) {
  const {
    fields: textFields,
    append: appendObject,
    remove: removeObject,
  } = useFieldArray({ control, name: arrayAttributeName });

  return (
    <BtcFormGroup formErrors={formErrors} attributeName={arrayAttributeName}>
      <Row>
        <Col>
          {textFields.map((item, index) => {
            return (
              <BtcFormGroup
                formErrors={formErrors}
                attributeName={`${arrayAttributeName}.${index}`}
                key={item.id}
              >
                <Row>
                  <Col sm="11" md="11">
                    <BtcFormGroup
                      formErrors={formErrors}
                      attributeName={`${arrayAttributeName}.${index}.value`}
                    >
                      <input
                        className={"form-control"}
                        key={item.id}
                        {...register(`${arrayAttributeName}.${index}.value`)}
                      />
                    </BtcFormGroup>
                  </Col>
                  <Col sm="1" md="1">
                    <Button
                      type="button"
                      className="btn--no-margin btn-round"
                      aria-label="Remove"
                      disabled={disabled}
                      onClick={() => {
                        removeObject(index);
                      }}
                    >
                      <i className="fas fa-times" />
                    </Button>
                  </Col>
                </Row>
              </BtcFormGroup>
            );
          })}
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className={"btn--no-margin btn-round ml-2"}
            type="button"
            onClick={() => {
              appendObject({ value: "" });
            }}
            disabled={disabled}
          >
            <i className="fas fa-plus" />
          </Button>
        </Col>
      </Row>
    </BtcFormGroup>
  );
}

export default BtcFormValueArray;

export function mapFromStringArray(stringArray) {
  return stringArray.map((string) => ({ value: string }));
}

export function mapFromObjectArray(objectArray) {
  return objectArray.map((ob) => ob.value);
}
