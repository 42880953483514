import BtcCardHeader from "../../components/BtcCardHeader";
import { Card, CardBody } from "reactstrap";
import { roundTo2Decimals } from "../../utils/mathUtil";
import ReactTable from "../../components/ReactTable";
import BtcCostWithTooltip from "../../components/BtcCostWithTooltip";
const { DateTime } = require("luxon");

const CostAnomaliesCard = (props) => {
  return (
    <>
      <Card className="card-stats">
        <BtcCardHeader
          title="Cost Anomalies"
          error={props.anomaliesCostErrors}
          loading={props.loading}
        />
        <CardBody>
          <ReactTable
            data={props.costAnomaliesData}
            hiddenColumns={["totalCostImpactCurrency", "totalCostImpactV2"]}
            columns={[
              {
                Header: "Start Date",
                accessor: "startDate",
                Cell: (date) => {
                  return DateTime.fromFormat(
                    date.cell.value,
                    "yyyy-LL-dd"
                  ).toFormat("dd/LL/yyyy");
                },
              },
              {
                Header: "End Date",
                accessor: "endDate",
                Cell: (date) => {
                  return DateTime.fromFormat(
                    date.cell.value,
                    "yyyy-LL-dd"
                  ).toFormat("dd/LL/yyyy");
                },
              },
              {
                Header: "Anomaly Score",
                accessor: "maxScore",
              },
              {
                Header: "Service",
                accessor: "service",
              },
              {
                Header: "Account ID",
                accessor: "accountId",
              },
              {
                Header: "Account Name",
                accessor: "accountName",
              },
              {
                // Invisible column
                accessor: "totalCostImpactCurrency",
              },
              {
                // Invisible column
                accessor: "totalCostImpactV2",
              },
              {
                Header: "Total Cost Impact",
                accessor: "totalCostImpact",
                Cell: (info) => (
                  <BtcCostWithTooltip
                    costsV2={info.row.values.totalCostImpactV2}
                  />
                ),
                Footer: (info) => {
                  return (
                    <span>
                      <b>
                        Sum Costs:{" "}
                        {roundTo2Decimals(
                          info.filteredRows
                            .map((row) =>
                              parseFloat(row.values.totalCostImpact)
                            )
                            .reduce((a, b) => a + b, 0)
                        )}{" "}
                        €
                      </b>
                    </span>
                  );
                },
              },
            ]}
            className="prevent-too-small-columns"
          />
        </CardBody>
      </Card>
    </>
  );
};

export default CostAnomaliesCard;
