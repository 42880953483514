import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { useAppContext } from "../utils/contextLib";
import logo from "assets/img/BTC-logo.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BtcSpinner from "../components/BtcSpinner";
import { passwordSchema } from "./passwordValidation";

export const PasswordChange = () => {
  const {
    setMfaSetupProcessStarted,
    setChangePasswordProcessStarted,
    cognitoUser,
  } = useAppContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(passwordSchema),
  });

  const { userHasAuthenticated } = useAppContext();
  const location = useLocation();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  async function changePassword(data) {
    setIsLoading(true);
    try {
      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        Auth.completeNewPassword(cognitoUser, data.password).then(() => {
          if (cognitoUser.challengeName === "MFA_SETUP") {
            setMfaSetupProcessStarted(true);
            history.push({
              pathname: "/mfasetup",
              state: {
                email: location.state.email,
              },
            });
          } else {
            userHasAuthenticated(true);
            setChangePasswordProcessStarted(false);
            setMfaSetupProcessStarted(false);
            history.push({ pathname: "/dashboard" });
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="content">
      <Row>
        <Col sm="3" md="4" xl="5" />
        <Col sm="6" md="4" xl="2">
          <div className="login-logo">
            <a
              href="http://www.btc-ag.com"
              target="blank"
              className="logo-normal"
            >
              <img src={logo} alt="BTC-AG" />
            </a>
            <p>BTC Utility Cloud Dashboard</p>
          </div>
        </Col>
        <Col sm="3" md="4" xl="5" />
      </Row>
      <Row>
        <Col sm="3" md="4" xl="5" />
        <Col sm="6" md="4" xl="2">
          <Form onSubmit={handleSubmit(changePassword)} id="LoginValidation">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Change Password</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup
                  className={`form-group ${
                    errors.password ? "has-danger" : ""
                  }`}
                >
                  <label>New Password *</label>
                  <input
                    autoFocus
                    className="form-control"
                    type="password"
                    {...register("password")}
                  />
                  {errors.password ? (
                    <label role="alert" className="error">
                      {errors.password?.message}
                    </label>
                  ) : null}
                </FormGroup>
                <FormGroup
                  className={`form-group ${
                    errors.retypedPassword ? "has-danger" : ""
                  }`}
                >
                  <label>Retype New Password *</label>
                  <input
                    className="form-control"
                    type="password"
                    {...register("retypedPassword")}
                  />
                  {errors.retypedPassword ? (
                    <label role="alert" className="error">
                      {errors.retypedPassword?.message}
                    </label>
                  ) : null}
                </FormGroup>
                <Button
                  block
                  color="success"
                  disabled={!!errors.password || !!errors.retypedPassword}
                  type="submit"
                >
                  {isLoading ? <BtcSpinner /> : "Change password"}
                </Button>
                <label>* Required fields</label>
              </CardBody>
            </Card>
          </Form>
        </Col>
        <Col sm="3" md="4" xl="5" />
      </Row>
      <Row>
        <Col />
      </Row>
    </div>
  );
};
