import {
  monthsNames,
  seriesBackgroundColors,
} from "./costBreakdownPlotRecources";

const removeServiceNameFromLabel = (label) => {
  let labelOut;
  if (label.includes("Amazon")) {
    labelOut = label.replace("Amazon", "").trim();
  } else if (label.includes("AWS")) {
    labelOut = label.replace("AWS", "").trim();
  } else {
    labelOut = label.replace("Azure", "").trim();
  }
  return labelOut;
};

const shortenLongLabel = (longLabel) => {
  const namesArray = longLabel.split("-");
  const nameToShorten = namesArray[0].split(" ");
  let shortLabelOut = "";

  const filteredNamesToShorten = nameToShorten.filter((item) => {
    return item.length > 0 && !item.includes("(") && !item.includes(")");
  });

  if (filteredNamesToShorten.length > 2) {
    filteredNamesToShorten.forEach((name) => {
      shortLabelOut += name[0];
    });
  } else {
    shortLabelOut = filteredNamesToShorten.join(" ");
  }

  if (namesArray[1]) {
    shortLabelOut += ` -${namesArray[1]}`;
  }

  return shortLabelOut;
};

const getValuesForServices = (category, data, filteringFn) => {
  const dataArray = [];

  for (const value of Object.values(data)) {
    const costsArr = value.filter((item) => filteringFn(item, category));
    const sum = costsArr.reduce((accumulator = 0, currentValue) => {
      // Accumulator is required here to get previous value
      // noinspection JSUnusedAssignment
      return (accumulator += currentValue.costs);
    }, 0);

    dataArray.push(sum);
  }

  return dataArray;
};

export const getCostsLabels = (data, categoryAttribute) => {
  const costsLabels = [];
  data.forEach((item) => {
    if (!costsLabels.includes(item[categoryAttribute])) {
      costsLabels.push(item[categoryAttribute]);
    }
  });

  return costsLabels;
};

export const sumCosts = (data, costLabel, categoryAttribute) => {
  const costsItems = data.filter(
    (item) => item[categoryAttribute] === costLabel
  );
  let sum;

  if (costsItems.length > 1) {
    sum = costsItems.reduce((prevValue = 0, currentValue = 0) => {
      return prevValue + currentValue?.costs;
    }, 0);
  } else {
    sum = costsItems[0].costs;
  }

  return sum;
};

export const sumOther = (data, dataOffset) => {
  const subArray = data.slice(dataOffset);

  let sum;
  if (subArray.length > 1) {
    sum = subArray.reduce((prevValue, currentValue) => {
      return prevValue + currentValue.value;
    }, 0);
  } else {
    sum = subArray[0] ? subArray[0].value : 0;
  }

  return Math.round(sum * 100) / 100;
};

export const shortenDataPlotLabel = (label) => {
  const labelOut = removeServiceNameFromLabel(label);
  return shortenLongLabel(labelOut);
};

export const getLabelsFromData = (data) => {
  const labels = [];
  for (const key of Object.keys(data)) {
    const year = key.slice(0, 4);
    const month = key.slice(5);
    labels.push(`${monthsNames[parseInt(month) - 1]} ${year}`);
  }

  return labels;
};

export const getFiveFourMostExpensive = (data) => {
  const summaryServiceCosts = {};

  const sortedValues = [];
  const topFour = [];

  for (const objectValues of Object.values(data))
    objectValues.forEach((item2) => {
      if (summaryServiceCosts[item2.category]) {
        summaryServiceCosts[item2.category] += item2.costs;
      } else {
        summaryServiceCosts[item2.category] = item2.costs;
      }
    });

  for (const [key, values] of Object.entries(summaryServiceCosts)) {
    sortedValues.push({ category: key, summaryCost: values });
  }

  sortedValues.sort((a, b) => b.summaryCost - a.summaryCost);

  for (let i = 0; i < Math.min(sortedValues.length, 4); i++) {
    topFour.push(sortedValues[i].category);
  }

  return topFour;
};

export const createDatasets = (labels, topCategories, data) => {
  let datasets;
  const categories = topCategories;
  categories.push("Others");

  datasets = categories.map((category, i) => {
    let categoryCost;

    if (category !== "Others") {
      categoryCost = getValuesForServices(category, data, (item, category) => {
        return item.category === category;
      });
    } else {
      categoryCost = getValuesForServices(
        categories,
        data,
        (item, categories) => {
          return !categories.includes(item.category);
        }
      );
    }
    const categoryLabel = shortenDataPlotLabel(category);
    return {
      label: categoryLabel,
      data: categoryCost,
      backgroundColor: seriesBackgroundColors[i],
      borderColor: seriesBackgroundColors[i],
      borderWidth: 1,
      barThickness: 15,
    };
  });

  return datasets;
};

export const sumTotalMonthlyCost = (monthName, categoryAttribute) => {
  const labels = JSON.parse(
    sessionStorage.getItem(categoryAttribute + "plotLabels")
  );
  const datasets = JSON.parse(
    sessionStorage.getItem(categoryAttribute + "plotDatasets")
  );

  const indexOfData = labels.indexOf(monthName);
  let totalMonthlyCost = 0;
  datasets.forEach((dataset) => {
    totalMonthlyCost += dataset.data[indexOfData];
  });
  return totalMonthlyCost.toFixed(2);
};
