import { API, graphqlOperation } from "aws-amplify";
import { parseAndThrowErrors } from "../../errors/GraphQlError";

export async function fetchHostedZones(company) {
  const hostedZoneQuery = `query {
      listHostedZones(input: {
        tenant: "${company}",
      }) {
        items{
          hostedZoneName
          accountId
          accountName
          hyperscaler
          hostedZoneType
        }
      }
    }
    `;
  try {
    const response = await API.graphql(graphqlOperation(hostedZoneQuery));
    return response.data.listHostedZones.items;
  } catch (e) {
    parseAndThrowErrors(e, `hosted zones of ${company}`);
  }
}
