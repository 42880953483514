// Just for Testing..

import React, { useEffect, useState } from "react";
import { Button, CardBody, CardFooter } from "reactstrap";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";
import BtcCardHeader from "../../components/BtcCardHeader";
import BtcListRow from "../../components/BtcListRow";

export const ServicesCard = ({
  title = "{title}",
  value = "{value}",
  icon = "question",
}) => {
  const [refreshState, setRefreshState] = useState(false);
  // Sets the Card to busy, which means it is just doing something (calculation or async requests)
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    setBusy(true);
    let timeout = setTimeout(() => {
      setBusy(false);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [refreshState]);

  return (
    <BtcNoAspectRatioCard label="Services" className="card-stats">
      <BtcCardHeader
        title="Services"
        icon="far fa-list-alt"
        loading={busy}
        url="/services"
      />
      <CardBody>
        <BtcListRow icon="fas fa-cloud" text="Managed Service" loading={busy}>
          <i className="fas fa-btc fa-check fa-lg text-success" />
        </BtcListRow>
        <BtcListRow icon="fas fa-cloud" text="JIRA" loading={busy}>
          <i className="fas fa-wrench fa-lg text-warning " />
        </BtcListRow>
        <BtcListRow icon="fab fa-aws" text="AWS Account" loading={busy}>
          <i className="fas fa-check fa-lg text-success" />
        </BtcListRow>
        <BtcListRow
          icon="fab fa-microsoft fa-fix"
          text="Azure Subscription"
          loading={busy}
        >
          <i className="fas fa-check fa-lg text-success" />
        </BtcListRow>
      </CardBody>
      <CardFooter>
        <hr />
        <div className="stats">
          <Button
            color="primary"
            size="sm"
            onClick={() => setRefreshState(!refreshState)}
          >
            {" "}
            <i className="fas fa-retweet" />
            refresh
          </Button>{" "}
        </div>
      </CardFooter>
    </BtcNoAspectRatioCard>
  );
};
