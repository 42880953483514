export class CustomError extends Error {
  header;
  authorizationText;
  constructor(msg, header = "Unexpected Error", authorizationText = "") {
    super(msg);
    this.name = this.constructor.name;
    this.header = header;
    this.authorizationText = authorizationText;
  }
}
