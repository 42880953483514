import { convertRemToPixels } from "./pixelUtil";

export function generateLinePlotOption() {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        ticks: {
          fontColor: "#9f9f9f",
          maxTicksLimit: 3,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          fontColor: "#9f9f9f",
        },
      },
    },
  };
}

export function generateLinePlotDataset(
  data = [],
  label,
  color = "#0076de",
  segment = {}
) {
  return {
    backgroundColor: color,
    label: label,
    borderColor: color,
    fill: false,
    borderWidth: convertRemToPixels(0.1875),
    barPercentage: 1.6,
    tension: 0.4,
    data: data,
    pointBackgroundColor: color,
    pointBorderColor: "#000",
    pointBorderWidth: convertRemToPixels(0.0625),
    pointHoverRadius: convertRemToPixels(0.315),
    pointHoverBackgroundColor: color,
    pointHoverBorderWidth: convertRemToPixels(0.125),
    pointRadius: convertRemToPixels(0.125),
    pointHitRadius: convertRemToPixels(0.625),
    segment,
  };
}
