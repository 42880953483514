import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Form } from "reactstrap";
import BtcCardHeader from "../../../components/BtcCardHeader";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { pushCompany } from "../adminResource";
import { notifyError, notifySuccess } from "../../../utils/notification";
import { useHistory } from "react-router-dom";
import CompanyCreateModal from "./CompanyCreateModal";
import BtcFormInput from "../../../components/BtcFormInput";
import { pushHistory } from "../../../utils/urlUtil";
import { UserContext } from "../../../utils/userContext";
import BtcLink from "../../../components/BtcLink";

CompanyCreateCard.propTypes = {
  notifyRef: PropTypes.object,
};

function CompanyCreateCard({ notifyRef }) {
  const history = useHistory();
  const { activePermission, activeAccountIds } = useContext(UserContext);

  const [modalCompany, setModalCompany] = useState();
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [backendCall, setBackendCall] = useState(false);

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[A-Z-]+$/,
        "Please provide a valid name containing only uppercase letters or dashes"
      )
      .required("An name is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  function onSubmit(data) {
    setModalCompany(data);
    setShowCreationModal(true);
  }

  function doCreateCompany(company) {
    setBackendCall(true);
    pushCompany(company)
      .then(() => {
        setBackendCall(false);
        pushHistory(
          history,
          activePermission,
          activeAccountIds,
          "/admin/company-edit/" + company.name
        );

        notifySuccess(notifyRef, "Creation of company was successful.");
      })
      .catch((e) => {
        setBackendCall(false);
        notifyError(notifyRef, e);
      });
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <BtcCardHeader title="Company Creation" />
          <CardBody className="px-5 pb-4">
            <BtcFormInput
              attributeName={"name"}
              labelText={"Company Name"}
              disabled={backendCall}
              formErrors={errors}
              register={register}
              autoComplete={"off"}
            />
          </CardBody>
        </Card>
        <div className="pull-right ">
          <BtcLink
            className="btn btn-success btn--no-margin card-shadow"
            to="/admin"
            disabled={backendCall}
          >
            Cancel
          </BtcLink>
          <input
            className="btn btn-success card-shadow btn--no-margin ml-3"
            type="submit"
            value={"Create company"}
            disabled={backendCall || !isValid}
          />
        </div>
      </Form>
      {modalCompany && (
        <CompanyCreateModal
          show={showCreationModal}
          companyName={modalCompany.name}
          onConfirm={() => doCreateCompany(modalCompany)}
          onClose={() => setShowCreationModal(false)}
        />
      )}
    </>
  );
}

export default CompanyCreateCard;
