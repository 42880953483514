/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import { Amplify, Auth } from "aws-amplify";
import { Chart, registerables } from "chart.js";

import config from "./config";
import "assets/scss/bootstrap-custom.scss";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import App from "App";
import { convertRemToPixels } from "./utils/pixelUtil";
import annotationPlugin from "chartjs-plugin-annotation";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  // https://github.com/aws-amplify/amplify-js/issues/3326#issuecomment-577010244
  // only idToken has customClaims like custom:company. So we override default behaviour (which would us access token)
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});
let myAppConfig = {
  aws_appsync_graphqlEndpoint: config.graphql.URL,
  aws_appsync_region: config.graphql.REGION,
  aws_appsync_authenticationType: config.graphql.AUTHENTICATION_TYPE,
};

Chart.defaults.font.size = convertRemToPixels(0.8125);
Chart.defaults.font.family =
  '"Montserrat", "Helvetica Neue", Arial, sans-serif';
Chart.defaults.locale = "en-GB";
Chart.register(...registerables, annotationPlugin);

Amplify.configure(myAppConfig);
ReactDOM.render(<App />, document.getElementById("root"));
