import React from "react";
import PropTypes from "prop-types";
import { CardBody, CardFooter } from "reactstrap";
import { Doughnut } from "react-chartjs-2";
import { getDoughnutChartEventType } from "./constants/guardDuty";
import { getMaxDate, getMinDate } from "./alertDateUtil";
import BtcCard from "../../components/BtcCard";
import BtcCardHeader from "../../components/BtcCardHeader";

EventsByTypeCard.propTypes = {
  alerts: PropTypes.array,
  alertsError: PropTypes.object,
  setTableFilterValue: PropTypes.func,
};

function EventsByTypeCard({
  alerts,
  alertsError,
  setTableFilterValue,
  loading,
}) {
  let doughnutChartEventType = getDoughnutChartEventType(
    setTableFilterValue,
    alerts
  );
  doughnutChartEventType.data.datasets[0].data = [
    getCountEventType(alerts, "Backdoor"),
    getCountEventType(alerts, "Behavior"),
    getCountEventType(alerts, "Recon"),
    getCountEventType(alerts, "Trojan"),
    getCountEventType(alerts, "UnauthorizedAccess"),
    getCountEventType(alerts, "Policy"),
    getCountEventType(alerts, "Discovery"),
  ];

  function getCountEventType(items, eventType) {
    return items.filter((item) => item.eventType.startsWith(eventType)).length;
  }

  return (
    <>
      <BtcCard label="Security Events by Event-Type">
        <BtcCardHeader
          title="Security Events"
          error={alertsError}
          description="grouped by Event-Type"
          loading={loading}
        />
        <CardBody>
          <Doughnut
            data={doughnutChartEventType.data}
            options={doughnutChartEventType.options}
            width={456}
            height={300}
            type="doughnut"
          />
        </CardBody>
        <CardFooter>
          <div className="legend">
            <i className="fa fa-circle text-primary" />
            Total number of Events: {alerts.length}
          </div>
          <hr />
          <div className="stats">
            <i className="fa fa-calendar" />
            {getMinDate(alerts)} - {getMaxDate(alerts)}
          </div>
        </CardFooter>
      </BtcCard>
    </>
  );
}

export default EventsByTypeCard;
