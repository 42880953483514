import { API, graphqlOperation } from "aws-amplify";
import { parseAndThrowErrors } from "../../errors/GraphQlError";

export async function fetchUserRoles(company, date) {
  let dateAsString = date.toISOString().substring(0, 10);
  const getUserRoles = `query {
      listUserRoles(input: {
              month_begin: "${dateAsString}",
              tenant: "${company}"
            }) {
              items{
                accountId
                accountName
                role
                username
                mailMessageId
                hyperscaler
                updatedAt
              }
            }
          }
          `;

  try {
    let response = await API.graphql(graphqlOperation(getUserRoles));
    return response.data.listUserRoles.items;
  } catch (e) {
    parseAndThrowErrors(e, `user roles of ${company}`);
  }
}

export async function fetchCompanyAccounts(company) {
  const getCompanyAccounts = `query {
    listAccountCount(input: {tenant: "${company}"}) {
      items {
        accountCount
        accountLast30DaysCreatedCount
      }
    }
  }`;

  try {
    let response = await API.graphql(graphqlOperation(getCompanyAccounts));
    return response.data.listAccountCount.items;
  } catch (e) {
    parseAndThrowErrors(e, `account for ${company}`);
  }
}
