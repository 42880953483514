import React, { useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import logo from "assets/img/BTC-logo.png";
import { notify } from "../utils/notification";
import NotificationAlert from "react-notification-alert";
import { PasswordResetEmailForm } from "./PasswordResetEmailForm";
import { PasswordResetNewPasswordForm } from "./PasswordResetNewPasswordForm";
import { onError } from "./errorLib";
import { PasswordResetPasswordChanged } from "./PasswordResetPasswordChanged";

export const PasswordReset = () => {
  let history = useHistory();
  const notifyRef = useRef(null);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [changePasswordStep, setChangePasswordStep] = useState("step1");

  const sendVerificationCodeHandler = async (data) => {
    setIsLoading(true);

    try {
      setEmail(data.email);
      await Auth.forgotPassword(data.email);
      notify({
        ref: notifyRef,
        type: 2,
        place: "tc",
        message: "Your verification code have been sent to the given email.",
      });
      setChangePasswordStep("step2");
    } catch (e) {
      onError(e, notifyRef);
    }
    setIsLoading(false);
  };

  const resetPassword = async (data) => {
    setIsLoading(true);

    try {
      await Auth.forgotPasswordSubmit(
        email,
        data.verificationCode,
        data.password
      );
      setChangePasswordStep("step3");
    } catch (e) {
      onError(e, notifyRef);
      setIsLoading(false);
    }
  };

  const redirectToLogin = () => {
    history.push({
      pathname: "/login",
    });
  };

  return (
    <div className="content">
      <NotificationAlert ref={notifyRef} />
      <Row>
        <Col sm="3" md="4" xl="5" />
        <Col sm="6" md="4" xl="2">
          <div className="login-logo">
            <a
              href="http://www.btc-ag.com"
              target="blank"
              className="logo-normal"
            >
              <img src={logo} alt="BTC-AG" />
            </a>
            <p>BTC Utility Cloud Dashboard</p>
          </div>
        </Col>
        <Col sm="3" md="4" xl="5" />
      </Row>
      <Row>
        <Col sm="3" md="4" xl="5" />
        <Col sm="6" md="4" xl="2">
          {changePasswordStep === "step1" ? (
            <PasswordResetEmailForm
              sendVerificationCodeHandler={sendVerificationCodeHandler}
              isLoading={isLoading}
            />
          ) : changePasswordStep === "step2" ? (
            <PasswordResetNewPasswordForm
              resetPassword={resetPassword}
              isLoading={isLoading}
            />
          ) : (
            <PasswordResetPasswordChanged redirectToLogin={redirectToLogin} />
          )}
        </Col>
        <Col sm="3" md="4" xl="5" />
      </Row>
      <Row>
        <Col />
      </Row>
    </div>
  );
};
