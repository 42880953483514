export function capitalizeFirstLetter(string) {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * ABC_DEF --> Abc Def
 */
export function constant_notation_to_pretty_string(string) {
  if (!string) {
    return string;
  }
  return string
    .split("_")
    .map((word) => capitalizeFirstLetter(word.toLowerCase()))
    .join(" ");
}
