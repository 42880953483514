import React, { useEffect, useState } from "react";
import { Card, CardBody, FormGroup, Input, Label } from "reactstrap";
import ReactTable from "../../components/ReactTable";
import { buildMapGroupedByKey } from "../../utils/groupByUtil";
import { getMaxProperty } from "../../utils/arrayUtil";
import BtcCardHeader from "../../components/BtcCardHeader";
import PropTypes from "prop-types";
import { roundTo2Decimals } from "../../utils/mathUtil";
import BtcAccountName from "../../components/BtcAccountName";
import BtcBudgetWithTooltip from "../../components/BtcBudgetWithTooltip";

BudgetCard.propTypes = {
  budgets: PropTypes.array,
  budgetError: PropTypes.object,
  loading: PropTypes.bool,
  selectedBudgetName: PropTypes.string,
  onSelectBudgetName: PropTypes.func,
};

function BudgetCard({
  budgets,
  budgetError,
  loading,
  selectedBudgetName,
  onSelectBudgetName,
}) {
  const [updatedAt, setUpdatedAt] = useState(null);
  const [newestBudgets, setNewestBudgets] = useState([]);

  useEffect(() => {
    setUpdatedAt(getMaxProperty(budgets, "updatedAt"));
    const budgetsTemp = budgets.map((b) => {
      return {
        ...b,
        firstDate: b.date,
        firstUsed: b.used,
        usedIncreaseOverLastDaysInPercentage: "0.00%",
      };
    });
    const budgetsGroupedByName = buildMapGroupedByKey(
      budgetsTemp,
      (k) => k.budgetName
    );
    const budgetsWithNewestDateEach = Array.from(
      budgetsGroupedByName.values()
    ).map((budgetsWithSameName) =>
      mergeBudgetsToOneBudget(budgetsWithSameName)
    );
    setNewestBudgets(budgetsWithNewestDateEach);
  }, [budgets]);

  function mergeBudgetsToOneBudget(array) {
    return array.reduce((value, newValue) => {
      if (newValue.date < value.firstDate) {
        value.firstDate = newValue.date;
        value.firstUsed = newValue.used;
      }
      if (newValue.date > value.date) {
        value.date = newValue.date;
        value.used = newValue.used;
        value.limit = newValue.limit;
        value.currency = newValue.currency;
        value.actualCostsV2 = newValue.actualCostsV2;
        value.limitV2 = newValue.limitV2;
        value.budgetPercentage = newValue.budgetPercentage;
        value.budgetPercentageAsString = newValue.budgetPercentageAsString;
      }
      value.usedIncreaseOverLastDaysInPercentage =
        ((value.used / value.firstUsed) * 100 - 100).toFixed(2) + " %";
      return value;
    });
  }

  return (
    <Card className="card-stats">
      <BtcCardHeader
        title="Budget"
        updatedAt={updatedAt}
        error={budgetError}
        loading={loading}
      />
      <CardBody>
        <ReactTable
          className="prevent-too-small-columns"
          data={newestBudgets}
          hiddenColumns={["currency", "actualCostsV2", "limitV2"]}
          columns={[
            {
              Header: "Budget-Name",
              accessor: "budgetName",
              Cell: (item) => (
                <FormGroup className="react-table__form-group" check>
                  <Input
                    id={item.value}
                    value={item.value}
                    name="budget-radio-selection"
                    type="radio"
                    checked={selectedBudgetName === item.value}
                    onChange={() => onSelectBudgetName(item.value)}
                  />{" "}
                  <Label for={item.value} check>
                    <BtcAccountName
                      accountName={item.value}
                      hyperscaler={item.row.original.hyperscaler}
                    />
                  </Label>
                </FormGroup>
              ),
            },
            {
              Header: "Accounts",
              accessor: "accountNamesAsString",
              disableSortBy: true,
            },
            { accessor: "currency" }, // Invisible column
            { accessor: "actualCostsV2" }, // Invisible column
            { accessor: "limitV2" }, // Invisible column
            {
              Header: "used / limit",
              accessor: "used", // so sorting is by how much limit is used
              Cell: (item) => {
                return (
                  <BtcBudgetWithTooltip
                    actualCostsV2={item.row.original.actualCostsV2}
                    limitV2={item.row.original.limitV2}
                    actualCostsClassName={
                      item.row.original.budgetPercentage >= 1
                        ? "danger-text"
                        : item.row.original.budgetPercentage >= 0.8
                        ? "warning-text"
                        : ""
                    }
                  />
                );
              },
            },
            {
              Header: `Used in %`,
              accessor: "budgetPercentageAsString",
              Cell: (item) => {
                return (
                  <span
                    className={
                      item.row.original.budgetPercentage >= 1
                        ? "danger-text"
                        : item.row.original.budgetPercentage >= 0.8
                        ? "warning-text"
                        : ""
                    }
                  >
                    {roundTo2Decimals(
                      item.row.original.budgetPercentage * 100
                    ).toFixed(2) + "%"}
                  </span>
                );
              },
            },
          ]}
        />
      </CardBody>
    </Card>
  );
}

export default BudgetCard;
