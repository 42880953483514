import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BtcCardHeader from "../../../components/BtcCardHeader";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import Select from "react-select";
import { fetchCompanies, fetchRoles } from "../adminResource";
import FormError from "../../../components/FormError";
import BtcWarning from "../../../components/BtcWarning";
import { useAppContext } from "../../../utils/contextLib";

CompanyAssignmentsFormCard.propTypes = {
  userLoading: PropTypes.bool,
  companyAssignments: PropTypes.array,
  setValue: PropTypes.func,
  notifyRef: PropTypes.object,
  disabled: PropTypes.bool,
};

function CompanyAssignmentsFormCard({
  userLoading,
  companyAssignments,
  setValue,
  formErrors,
  notifyRef,
  disabled,
}) {
  const { fetchMultipleData } = useAppContext();
  const [optionsLoading, setOptionsLoading] = useState(true);

  const [rolesOptions, setRolesOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [allCompanyOptions, setAllCompanyOptions] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedRole, setSelectedRole] = useState();

  const [error, setError] = useState();

  function addCompanyTag(selectedCompany, selectedRole) {
    setCompanyAssignments([
      ...companyAssignments,
      { company: selectedCompany.value, role: selectedRole.value },
    ]);
    setSelectedCompany(null);
  }

  function removeCompanyTag(assignment) {
    setCompanyAssignments(companyAssignments.filter((a) => a !== assignment));
  }

  function setCompanyAssignments(companyAssignments) {
    setValue("companyAssignments", companyAssignments, {
      shouldValidate: true,
    });
  }

  useEffect(() => {
    const rolesPromise = fetchRoles().then((roles) => setRolesOptions(roles));
    const companiesPromise = fetchCompanies().then((company) =>
      setAllCompanyOptions(company)
    );

    fetchMultipleData(
      [rolesPromise, companiesPromise],
      setError,
      setOptionsLoading,
      notifyRef
    );
  }, [notifyRef, fetchMultipleData]);

  useEffect(() => {
    if (!companyAssignments) {
      return;
    }
    let alreadySelectedCompanies = companyAssignments.map(
      (assignment) => assignment.company
    );
    setCompanyOptions(
      allCompanyOptions.filter(
        (option) => !alreadySelectedCompanies.includes(option)
      )
    );
  }, [companyAssignments, allCompanyOptions]);

  return (
    <Card>
      <BtcCardHeader
        title={`Company Assignments`}
        loading={optionsLoading || userLoading}
        error={error}
      />
      <CardBody className="px-5 pb-4">
        <div className="btc-tag-edit">
          <div className="btc-tag-edit__elements d-flex flex-wrap btc-gap">
            {companyAssignments.map((assignment, key) => (
              <span
                className="badge badge-primary"
                key={"btc-tag-edit-" + assignment.company + key}
              >
                {assignment.company} ({assignment.role})
                <button
                  type="button"
                  className="btn badge-close"
                  aria-label="Remove"
                  disabled={disabled}
                  onClick={() => {
                    removeCompanyTag(assignment);
                  }}
                >
                  <i className="fas fa-times" />
                </button>
              </span>
            ))}
            <FormError error={formErrors.companyAssignments} />
          </div>
          <Row className="mt-4">
            <Col sm="5">
              <Label for="company" className="visually-hidden">
                Company
              </Label>
              <Select
                id="company"
                placeholder="Select Company..."
                value={selectedCompany}
                isDisabled={disabled}
                onChange={(selection) => setSelectedCompany(selection)}
                options={companyOptions.map((company) => {
                  return {
                    value: company,
                    label: company,
                  };
                })}
              />
            </Col>
            <Col sm="5">
              <Label for="role" className="visually-hidden">
                Role
              </Label>
              <Select
                id="role"
                placeholder="Select Role..."
                value={selectedRole}
                isDisabled={disabled}
                onChange={(selection) => setSelectedRole(selection)}
                options={rolesOptions.map((role) => {
                  return {
                    value: role,
                    label: role,
                  };
                })}
              />
            </Col>
            <Col sm="2">
              <Button
                className={"btn--no-margin pull-right btn-round"}
                onClick={() => addCompanyTag(selectedCompany, selectedRole)}
                disabled={!selectedCompany || !selectedRole || disabled}
              >
                <i className="fas fa-plus" />
              </Button>
            </Col>
          </Row>
        </div>
        {companyAssignments.map((a) => a.company).includes("SYSADMIN") && (
          <BtcWarning
            text="Assigning someone SYSADMIN company gives the user
            sys-admin rights! For example, such user can delete, create and update
            users!"
          />
        )}
      </CardBody>
    </Card>
  );
}

export default CompanyAssignmentsFormCard;
