import React from "react";
import CompanyCreateCard from "./CompanyCreateCard";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

CompanyCreatePage.propTypes = {
  notifyRef: PropTypes.object,
};

function CompanyCreatePage({ notifyRef }) {
  return (
    <section
      aria-labelledby="company-create-module-page-heading"
      className="content"
    >
      <h2 id="company-create-module-page-heading" className="visually-hidden">
        Company Create Page{" "}
      </h2>
      <Row>
        <Col
          md={{ offset: 1, size: 10 }}
          lg={{ offset: 2, size: 8 }}
          xl={{ offset: 3, size: 6 }}
        >
          <CompanyCreateCard notifyRef={notifyRef} />
        </Col>
      </Row>
    </section>
  );
}

export default CompanyCreatePage;
