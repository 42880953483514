export function groupByKeyAndBuildSum(array, keySupplier, valueSupplier) {
  return array.reduce(
    (entryMap, e) =>
      entryMap.set(
        keySupplier(e),
        (entryMap.get(keySupplier(e)) || 0) + valueSupplier(e)
      ),
    new Map()
  );
}

export function buildMapGroupedByKey(array, keySupplier) {
  return array.reduce((entryMap, e) => {
    let key = keySupplier(e);
    let list = entryMap.get(key) || [];
    list.push(e);
    entryMap.set(key, list);
    return entryMap;
  }, new Map());
}

export function groupByKeyAndThenReduce(array, keySupplier, reduceFunction) {
  return Array.from(buildMapGroupedByKey(array, keySupplier).values()).map(
    (entryWithSameKey) =>
      entryWithSameKey.reduce((value, newValue) =>
        reduceFunction(value, newValue)
      )
  );
}
