import { CustomError } from "./CustomError";

export class GraphQlError extends CustomError {}

export function parseErrors(response, resourceName) {
  if (response.errors == null) {
    throw response;
  }

  if (response.errors.length === 0) {
    return [];
  }

  return response.errors.map((error) => parseError(error, resourceName));
}

export function parseAndThrowErrors(errorResponse, resourceName) {
  let errors = parseErrors(errorResponse, resourceName);
  if (errors.length > 0) {
    throw errors[0];
  }
}

function parseError(errorResponse, resourceName) {
  console.error(errorResponse);
  switch (errorResponse.errorType) {
    case "Unauthorized":
      return new GraphQlError(
        `Sorry, you are not authorized to access ${resourceName}.`,
        errorResponse.errorType,
        "Please, try to login again or use a different account."
      );
    default:
      return new GraphQlError(
        `Sorry, an error happened when accessing ${resourceName}.`,
        errorResponse.errorType || "Unexpected Error"
      );
  }
}
