import React from "react";
import { CardBody } from "reactstrap";

BtcStretchedContentCardBody.propTypes = {};

function BtcStretchedContentCardBody({ children }) {
  return (
    <CardBody className="btc-stretch">
      <div className="btc-stretch__stretched">{children}</div>
    </CardBody>
  );
}

export default BtcStretchedContentCardBody;
