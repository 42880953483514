import { UncontrolledTooltip } from "reactstrap";
import { convertCurrencySymbol } from "../modules/costs/CostsUtil";
import { DateTime } from "luxon";
import React, { useState } from "react";
import _uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";

BtcExchangeRateInfoSymbolWithTooltip.propTypes = {
  costsV2: PropTypes.object,
};

export default function BtcExchangeRateInfoSymbolWithTooltip({ costsV2 }) {
  const [tooltipId] = useState(_uniqueId("btc-cost-with-tooltip-"));

  return (
    <>
      <i
        id={tooltipId}
        className="fas fa-info-circle"
        aria-label={`Costs in original unit was ${costsV2.originalCosts}${costsV2.orignalCurrency} with exchange rate of ${costsV2.exchangeRate} at ${costsV2.dateOfExchangeRate}`}
      />
      <UncontrolledTooltip delay={0} target={tooltipId}>
        <p style={{ padding: "0.5em", margin: 0, textAlign: "left" }}>
          Original cost: {costsV2.originalCosts}&thinsp;
          {convertCurrencySymbol(costsV2.originalCurrency)}
          <br />
          Exchange rate: {costsV2.exchangeRate}
          <br />
          Date of exchange rate:{" "}
          {DateTime.fromFormat(
            costsV2.dateOfExchangeRate,
            "yyyy-LL-dd"
          ).toFormat("dd/LL/yyyy")}
        </p>
      </UncontrolledTooltip>
    </>
  );
}
