import { NoAuthenticatedUserError } from "../errors/NoAuthenticatedUserError";
import { UnexpectedError } from "../errors/UnexpectedError";
import { notifyError } from "../utils/notification";
import { API, graphqlOperation } from "aws-amplify";
import { parseAndThrowErrors } from "../errors/GraphQlError";
import { distinct } from "../utils/arrayUtil";

/**
 * Returns at least one access permission for one company, if not it throws an error.
 */
export async function getAccessPermissions(notifyRef) {
  const userConfigItems = await getUserConfigItems();
  if (userConfigItems == null || userConfigItems.length === 0) {
    throw new NoAuthenticatedUserError("Couldn't find any user configuration.");
  }
  let responses = await Promise.allSettled(
    userConfigItems.map(async (item) => {
      return await getAccessPermission(item);
    })
  );

  let results = responses
    .filter((response) => response.status === "fulfilled")
    .map((response) => response.value);
  let errors = responses
    .filter((response) => response.status === "rejected")
    .map((response) => response.reason);

  errors.map((error) => console.error(error));
  if (results.length === 0) {
    if (errors.length > 1) {
      throw errors[0];
    } else {
      throw new UnexpectedError(
        "There should be company data for at least one company"
      );
    }
  }
  // At least one result is existing, so continue with that, but show notifications for other company-loading-errors
  errors.map((error) => notifyError(notifyRef, error));

  return results;
}

async function getAccessPermission(userConfigItem) {
  const company = userConfigItem.company;
  const role = userConfigItem.role;

  let [companyModules, companyAccListObj, extended] = await Promise.all([
    getCompanyModules(company, role),
    getCompanyAccountListObj(company),
    isExtended(company),
  ]);
  const companyAccountList = companyAccListObj.map((acc) => acc.accountId);

  return {
    company,
    role,
    companyModules,
    companyAccountList,
    companyAccListObj,
    extended,
  };
}

async function getUserConfigItems() {
  const getUserConfigQuery = `query {
          listUserConfig(input: {
            tenant: "getAll",
          }) {
            items{
              company
              role
            }
          }
        }
        `;

  try {
    const userConfigResponse = await API.graphql(
      graphqlOperation(getUserConfigQuery)
    );
    return userConfigResponse.data.listUserConfig.items;
  } catch (e) {
    parseAndThrowErrors(e, "user configuration");
  }
}

async function getCompanyModules(company, role) {
  const getCompanyModulesQuery = `query {
              listCompanyModules(input: {
                tenant: "${company}"
              }) {
                items{
                  module
                }
              }
            }
            `;

  let responseCM;
  try {
    responseCM = await API.graphql(graphqlOperation(getCompanyModulesQuery));
  } catch (e) {
    parseAndThrowErrors(e, `company module of ${company}`);
  }

  let itemsCM = responseCM.data.listCompanyModules.items;
  if (itemsCM == null || itemsCM.length === 0) {
    throw new UnexpectedError(
      `No company module was returned for ${company} and role ${role}`
    );
  }

  return itemsCM[itemsCM.length - 1].module;
}

async function getCompanyAccountListObj(company) {
  const getCompanyAccountListQuery = `query {
              listAccountsFromConfig(input: {
                tenant: "${company}",
              }) {
                items{
                  accountId
                  accountName
                  hyperscaler
                }
              }
            }
            `;

  let responseCA;
  try {
    responseCA = await API.graphql(
      graphqlOperation(getCompanyAccountListQuery)
    );
  } catch (e) {
    parseAndThrowErrors(e, `company account list of ${company}`);
  }

  const companyAccountListItems = responseCA.data.listAccountsFromConfig.items;
  if (companyAccountListItems == null) {
    throw new UnexpectedError(
      `Company account list for ${company} does not exist.`
    );
  }

  const companyAccListObj = [
    {
      accountId: "all",
      accountName: "All Accounts",
    },
  ];
  let hyperscalers = companyAccountListItems.map((i) => i.hyperscaler);
  if (distinct(hyperscalers).length > 1) {
    companyAccListObj.push({
      accountId: "aws",
      accountName: "All AWS Accounts",
      hyperscaler: "aws",
    });
    companyAccListObj.push({
      accountId: "azure",
      accountName: "All Azure Accounts",
      hyperscaler: "azure",
    });
  }

  for (let item of companyAccountListItems) {
    const accountId = item.accountId;
    const accountName = item.accountName;
    const hyperscaler = item.hyperscaler;
    // if (accountName.indexOf("idp") !== -1) {
    //   continue;
    // }
    if (companyAccListObj.map((acc) => acc.accountId).includes(accountId)) {
      continue;
    }
    companyAccListObj.push({ accountId, accountName, hyperscaler });
  }

  return companyAccListObj;
}

async function isExtended(company) {
  const getExtended = `query {
      listCompanyConfigParams(input: {tenant: "${company}"}) {
        items {
          extended
        }
      }
     }`;

  let response;
  try {
    response = await API.graphql(graphqlOperation(getExtended));
  } catch (e) {
    parseAndThrowErrors(e, `extended of ${company}`);
  }

  let items = response.data.listCompanyConfigParams.items;
  if (items == null || items.length === 0) {
    return false;
  }

  return !!items[0].extended;
}
