import React from "react";
import PropTypes from "prop-types";
import BtcHyperscalerLogo from "./BtcHyperscalerLogo";

BtcAccountName.propTypes = {
  hyperscaler: PropTypes.string,
  accountName: PropTypes.string,
};

function BtcAccountName({ hyperscaler, accountName }) {
  return (
    <span>
      <BtcHyperscalerLogo hyperscaler={hyperscaler}></BtcHyperscalerLogo>{" "}
      {accountName}
    </span>
  );
}

export default BtcAccountName;
