import React from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import logo from "../../assets/img/BTC-logo.png";

function ErrorPage({ error }) {
  let history = useHistory();

  async function handleLogout() {
    try {
      await Auth.signOut();
    } catch (e) {
      console.error(e);
    }
    history.push("/login");
  }

  return (
    <main className="btc-error-page" aria-label="error-page">
      <div className="btc-two-column-grid">
        <div>
          <div className="logo">
            <a
              href="http://www.btc-ag.com"
              target="blank"
              className="simple-text text-center"
            >
              <img src={logo} alt="BTC-AG" />
            </a>
          </div>
          <h1 className="error-page-logo-text text-center">
            Utility Cloud Platform
          </h1>
        </div>
        <div>
          <h2>{error.header ? error.header : "Unexpected Error"}</h2>
          <p>{error.message}</p>
          {error.authorizationText && (
            <>
              <p>{error.authorizationText}</p>
              <button
                className="btn btn-secondary"
                onClick={() => handleLogout()}
              >
                To Login
              </button>
            </>
          )}
        </div>
      </div>
    </main>
  );
}

export default ErrorPage;
