import React, { useState } from "react";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import { Line } from "react-chartjs-2";
import { BTCChart2, chartExample1 } from "../../variables/charts";
import BtcNoAspectRatioCard from "../../components/BtcNoAspectRatioCard";

JiraCostsCard.propTypes = {};

function JiraCostsCard(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  function doMail(email) {
    window.location.href = "mailto:" + email;
  }

  return (
    <BtcNoAspectRatioCard label="Jira Costs">
      <CardHeader>
        <Row>
          <Col xs="7">
            <div className="numbers pull-left">JIRA</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <h6 className="big-title">total costs</h6>
        <Line
          data={chartExample1.data}
          options={chartExample1.options}
          height={300}
          width={826}
        />
      </CardBody>
      <CardBody>
        <Line
          data={BTCChart2.data}
          options={BTCChart2.options}
          height={250}
          width={826}
        />
      </CardBody>
      <CardFooter>
        <hr />
        <Row>
          <Col xs="7">
            <Button className="btn btn-neutral p-0" onClick={toggle}>
              Contact <i className="fas fa-chevron-down" />
            </Button>{" "}
            <Collapse isOpen={isOpen}>
              <div className="text-left">
                <p>JIRA Service Coordinator:</p>
                <p>
                  <b>Max Mustermann</b>
                  <br />
                  +49 123 456789
                  <br />
                  max.mustermann@btc-ag.com
                </p>
                <Button
                  variant="primary"
                  onClick={() => doMail("max.mustermann@btc-ag.com")}
                >
                  Send Email
                </Button>{" "}
              </div>
            </Collapse>
          </Col>
        </Row>
      </CardFooter>
    </BtcNoAspectRatioCard>
  );
}

export default JiraCostsCard;
