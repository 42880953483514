import { API, graphqlOperation } from "aws-amplify";
import { parseAndThrowErrors } from "../../errors/GraphQlError";

export async function fetchAlerts(company) {
  const getAlerts = `query {
        listAlerts(input: {
              tenant: "${company}"
            }) {
              items{
                firstSeen
                geoLat
                geoLon
                severity
                eventType
                description
                normName
                normItems
                actor
                entityType
                action
                count
                accountId
                accountName
                hyperscaler
                updatedAt
                solved
              }
            }
          }
          `;

  try {
    const response = await API.graphql(graphqlOperation(getAlerts));
    return parseResponseData(response);
  } catch (e) {
    parseAndThrowErrors(e, `alerts of ${company}`);
  }
}

function parseResponseData(response) {
  const items = response.data.listAlerts.items;
  if (items != null) {
    return items
      .map((item) => ({
        ...item,
        solvedAsString: item.solved?.toString(),
        severityIndex: calcSeverityIndex(item.severity),
      }))
      .reverse();
  } else {
    return null;
  }
}

function calcSeverityIndex(severity) {
  switch (severity) {
    case "Low":
      return "01_LOW";
    case "Medium":
      return "02_MEDIUM";
    case "High":
      return "03_HIGH";
    default:
      return "00";
  }
}

export async function fetchAlertCount(company, monthStart) {
  const getAlertCount = `query {
        listAlertCount(input: {
              tenant: "${company}"
              month_begin: "${monthStart}"
            }) {
              items{
                accountId
                count
                severity
                hyperscaler
                date
                updatedAt
              }
            }
          }
          `;

  try {
    const response = await API.graphql(graphqlOperation(getAlertCount));
    return response.data.listAlertCount.items || [];
  } catch (e) {
    parseAndThrowErrors(e, `alerts of ${company}`);
  }
}
