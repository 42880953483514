import React, { useContext, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import { Col, Row } from "reactstrap";
import { UserRolesCard } from "./UserRolesCard";
import { IamOverviewCard } from "./IamOverviewCard";
import { IamOverviewPlotCard } from "./IamOverviewPlotCard";
import { IamSubscriptionOverviewCard } from "./IamSubscriptionOverviewCard";
import { UserContext } from "../../utils/userContext";

function Iam() {
  const { activePermission } = useContext(UserContext);
  const notifyRef = useRef(null);

  const customerLogoContainer = document.getElementById("customerLogoImg");
  const isCustomerLogoVisible = customerLogoContainer?.innerHTML !== "";

  return (
    <section
      aria-labelledby="iam-module-page-heading"
      className={`content ${
        isCustomerLogoVisible ? "content-with-customer-logo" : ""
      }`}
    >
      <h2 id="iam-module-page-heading" className="visually-hidden">
        IAM
      </h2>
      <NotificationAlert ref={notifyRef} />
      {activePermission.extended && (
        <Row>
          <Col lg="3" md="4" sm="6">
            <IamOverviewCard />
          </Col>
          <Col lg="3" md="4" sm="6">
            <IamOverviewPlotCard />
          </Col>
          <Col lg="3" md="4" sm="6">
            <IamSubscriptionOverviewCard />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <UserRolesCard notifyRef={notifyRef} />
        </Col>
      </Row>
    </section>
  );
}

export default Iam;
